import React from "react";
import styled from "styled-components";
import loaderAnimation from "../assets/loader/loader.json";
import LottieLoader from "react-lottie-loader";

export default function Loader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {},
  };
  return (
    <Container>
      <LoaderContainer>
        <LottieLoader
          animationData={loaderAnimation}
          loop={true}
          height={200}
          width={300}
        />
      </LoaderContainer>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 50px; */
  /* width: 100%; */
`;

const LoaderContainer = styled.div`
  width: 120px;
  padding-top: 37px;
  margin: 0 auto;
`;
