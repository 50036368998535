import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import Banner from "./Banner";
import BannerDetails from "./BannerDetails";
import BannerList from "./BannerList";
import AddBannerModal from "./modal/AddBannerModal";
import { webConfig } from "../../../axiosConfig";
import { useBearStore } from "../../context/Store";

const BannerScreen = () => {
  const userData = useBearStore((state) => state.userData);

  const [bannerModal, setBannerModal] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [pagination, setPagination] = useState({});

  const fetchBanners = () => {
    webConfig
      .get("banner/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setBannerData(app_data.data);
          setPagination(app_data.pagination_data);
        }
      });
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <Cover>
      <Banner bannerModal={bannerModal} setBannerModal={setBannerModal} />
      <BannerList
        bannerData={bannerData}
        fetchBanners={fetchBanners}
        pagination={pagination}
      />

      <AddBannerModal
        bannerModal={bannerModal}
        setBannerModal={setBannerModal}
        fetchBanners={fetchBanners}
      />
    </Cover>
  );
};

export default BannerScreen;
