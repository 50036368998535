import React, { useState } from "react";
import styled from "styled-components";
import { useBearStore, useDispatchStore } from "../../../context/Store";
import { paymentConfig } from "../../../../axiosConfig";
import ButtonLoader from "../../../helpers/ButtonLoader";

const AddShipmentModal = ({
  addShipmentModal,
  setShipmentModal,
  orderData,
}) => {
  const userData = useBearStore((state) => state.userData);
  const [wareHouseName, setWareHouseName] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoader, setLoader] = useState(false);

  const addPickupAddress = () => {
    const formData = new FormData();
    formData.append("ware_house_name", wareHouseName);
    formData.append("name", name);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("pin_code", pincode);
    formData.append("phone", phone);

    if (wareHouseName && name && address && city && state && pincode && phone) {
      paymentConfig
        .post("add-pickup-address/", formData, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            setShipmentModal(false);
          }
        });
    }
  };

  return (
    <Cover>
      <Container className={addShipmentModal && "active"}>
        <Overlay onClick={() => setShipmentModal(false)}></Overlay>
        <Modal>
          <Form>
            <InputDiv>
              <Label>Warhouse name : </Label>
              <input
                type="text"
                placeholder="Enter Warehouse Name"
                onChange={(e) => setWareHouseName(e.target.value)}
              />
            </InputDiv>
            <InputDiv>
              <Label>Name : </Label>
              <input
                type="text"
                placeholder="Enter Name"
                onChange={(e) => setName(e.target.value)}
              />
            </InputDiv>
            <InputDiv>
              <Label>Pickup Address: </Label>
              <input
                type="text"
                placeholder="Enter Pickup address"
                onChange={(e) => setAddress(e.target.value)}
              />
            </InputDiv>
            <InputDiv>
              <Label>Pickup City : </Label>
              <input
                type="text"
                placeholder="Enter Pickup city"
                onChange={(e) => setCity(e.target.value)}
              />
            </InputDiv>
            <InputDiv>
              <Label>Pickup State : </Label>
              <input
                type="text"
                placeholder="Enter Pickup state"
                onChange={(e) => setState(e.target.value)}
              />
            </InputDiv>
            <InputDiv>
              <Label>Pickup pincode : </Label>
              <input
                type="text"
                placeholder="Enter Pickup pincode"
                onChange={(e) => setPincode(e.target.value)}
              />
            </InputDiv>
            <InputDiv>
              <Label>Pickup phone : </Label>
              <input
                type="text"
                placeholder="Enter Pickup phone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </InputDiv>
          </Form>
          <ButtonContainer>
            <Button
              onClick={() => {
                addPickupAddress();
              }}
            >
              {isLoader ? <ButtonLoader /> : "Submit"}
            </Button>
          </ButtonContainer>
        </Modal>
      </Container>
    </Cover>
  );
};

export default AddShipmentModal;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  padding-bottom: 30px;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const Form = styled.form`
  padding-top: 20px;
`;
const InputDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  input {
    width: 50%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid grey;
  }
`;
const Label = styled.label`
  width: 30%;
  font-size: 15px;
`;
const ButtonContainer = styled.div`
  margin-top: 30px;
  width: 100%;
`;
const Button = styled.div`
  background: var(--button-color);
  width: 150px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
`;
