import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import BlogList from "./BlogList";
import BlogTopContainer from "./BlogTopContainer";
import ModalForm from "./modal/ModalForm";
import { blogConfig } from "../../../axiosConfig";
import {
  useBearStore,
  useBlogIdStore,
  useFeaturedIdStore,
} from "../../context/Store";

const BlogScreen = () => {
  const userData = useBearStore((state) => state.userData);
  const FeaturedId = useFeaturedIdStore((state) => state.FeaturedId);

  const [addBlogModal, setAddBlogModal] = useState(false);
  const [editBlogModal, setEditBlogModal] = useState(false);

  const [blogData, setBlogData] = useState([]);
  const [pagination, setPagination] = useState({});

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [author, setAuthor] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [keywords, setKeywords] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [slug, setSlug] = useState("");

  const [thumbnail, setThumbnail] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const fetchBlogData = () => {
    blogConfig
      .get("blog-list/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setBlogData(app_data.data);
          setPagination(app_data.pagination_data);
        } else {
          console.log(app_data.data.message);
        }
      });
  };

  const addBlog = () => {
    const formData = new FormData();

    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);
    formData.append("tags", keywords);
    formData.append("author", author);
    formData.append("slug", slug);
    formData.append("meta_description", metaDescription);
    formData.append("meta_title", metaTitle);
    formData.append("thumbnail", thumbnail);

    blogConfig
      .post("add-blogs/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setAddBlogModal(false);
          fetchBlogData();
        }
      });
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  return (
    <Cover>
      <BlogTopContainer
        setAddBlogModal={setAddBlogModal}
        addBlogModal={addBlogModal}
        setTitle={setTitle}
        setDescription={setDescription}
        setAuthor={setAuthor}
        setImage={setImage}
        setKeywords={setKeywords}
        setMetaDescription={setMetaDescription}
        setMetaTitle={setMetaTitle}
        setSlug={setSlug}
        setImageUrl={setImageUrl}
        setThumbnail={setThumbnail}
        setThumbnailUrl={setThumbnailUrl}
        addBlog={addBlog}
      />
      <BlogList
        setEditBlogModal={setEditBlogModal}
        fetchBlogData={fetchBlogData}
        setPagination={setPagination}
        blogData={blogData}
        pagination={pagination}
      />
      <ModalForm
        setModal={setEditBlogModal}
        modal={editBlogModal}
        type="edit_modal"
        title={title}
        description={description}
        author={author}
        image={image}
        imageUrl={imageUrl}
        keywords={keywords}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        slug={slug}
        thumbnail={thumbnail}
        thumbnailUrl={thumbnailUrl}
        setTitle={setTitle}
        setDescription={setDescription}
        setThumbnail={setThumbnail}
        setThumbnailUrl={setThumbnailUrl}
        setAuthor={setAuthor}
        setImage={setImage}
        setImageUrl={setImageUrl}
        setKeywords={setKeywords}
        setMetaDescription={setMetaDescription}
        setMetaTitle={setMetaTitle}
        setSlug={setSlug}
        fetchBlogData={fetchBlogData}
      />
    </Cover>
  );
};

export default BlogScreen;
