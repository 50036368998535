import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";
import { blogConfig } from "../../../../axiosConfig";
import { useBearStore, useBlogIdStore } from "../../../context/Store";

const ModalForm = ({
  setModal,
  modal,
  type,
  title,
  description,
  author,
  image,
  imageUrl,
  keywords,
  metaTitle,
  metaDescription,
  slug,
  thumbnail,
  thumbnailUrl,
  setTitle,
  setDescription,
  setAuthor,
  setImage,
  setKeywords,
  setMetaTitle,
  setSlug,
  setImageUrl,
  setMetaDescription,
  setThumbnail,
  setThumbnailUrl,
  fetchBlogData,
  addBlog,
}) => {
  const BlogId = useBlogIdStore((state) => state.BlogId);
  const userData = useBearStore((state) => state.userData);

  const [individualBlog, setIndividualBlog] = useState([]);

  console.log(thumbnail, "-=-=-=-imageUrl-=-=-=-");
  const handleDescription = (value) => {
    setDescription(value);
  };

  const handlePhoto1 = (event) => {
    setImage(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl(imageContent);
    };
  };

  const handleThumbnail = (event) => {
    setThumbnail(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setThumbnailUrl(imageContent);
    };
  };

  console.log(thumbnailUrl, "-=-=-=-thumbnailUrl-=-=-=-");
  const FetchSingleBlog = () => {
    BlogId &&
      blogConfig
        .get(`single-blog/${BlogId}`, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          let { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            setIndividualBlog(app_data.data);
            setTitle(app_data.data.title);
            setDescription(app_data.data.description);
            setImage(app_data.data.image);
            setThumbnail(app_data.data.thumbnail);
            setKeywords(app_data.data.tags);
            setAuthor(app_data.data.author);
            setSlug(app_data.data.slug);
            setMetaDescription(app_data.data.meta_description);
            setMetaTitle(app_data.data.meta_title);
          } else {
            console.log(app_data.data.message);
          }
        });
  };

  useEffect(() => {
    FetchSingleBlog();
  }, [BlogId]);

  const editBlog = () => {
    const formData = new FormData();

    if (individualBlog.title !== title) {
      formData.append("title", title);
    }
    if (individualBlog.description !== description) {
      formData.append("description", description);
    }

    if (individualBlog.image !== image) {
      formData.append("image", image);
    }

    if (individualBlog.image !== image) {
      formData.append("image", image);
    }

    if (individualBlog.tags !== keywords) {
      formData.append("tags", keywords);
    }

    if (individualBlog.author !== author) {
      formData.append("author", author);
    }

    if (individualBlog.slug !== slug) {
      formData.append("slug", slug);
    }

    if (individualBlog.meta_description !== metaDescription) {
      formData.append("meta_description", metaDescription);
    }

    if (individualBlog.meta_title !== metaTitle) {
      formData.append("meta_title", metaTitle);
    }

    if (individualBlog.thumbnail !== thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    blogConfig
      .post(`edit-blog/${BlogId}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          fetchBlogData();
          setModal(false);
        } else {
          console.log(app_data.data.message);
        }
      });
  };

  return (
    <Cover>
      <Container className={modal && "active"}>
        <Overlay onClick={() => setModal(false)}></Overlay>
        <Modal>
          <MainContainer>
            <Wrapper>
              {type == "edit_modal" ? (
                <MainTitle>Edit Blog</MainTitle>
              ) : (
                <MainTitle>Add Blog</MainTitle>
              )}
              <Form>
                <FieldDiv>
                  <LabelDiv>
                    <Label>Title </Label>
                  </LabelDiv>
                  <InputDiv>
                    <input
                      type="text"
                      placeholder="Enter title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv>
                  <LabelDiv>
                    <Label>Description </Label>
                  </LabelDiv>
                  {type == "edit_modal" ? (
                    <InputDiv>
                      <ReactQuill
                        value={description}
                        onChange={handleDescription}
                      />
                    </InputDiv>
                  ) : (
                    <InputDiv>
                      <ReactQuill onChange={handleDescription} />
                    </InputDiv>
                  )}
                </FieldDiv>
                <FieldDiv>
                  <LabelDiv>
                    <Label>Author name </Label>
                  </LabelDiv>
                  <InputDiv>
                    <input
                      type="text"
                      placeholder="Enter author name"
                      value={author}
                      onChange={(e) => setAuthor(e.target.value)}
                    />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv className="upload-image">
                  <LabelDiv>
                    <Label>Upload the blog Image(size-1175 x 540 px) </Label>
                  </LabelDiv>
                  {type == "edit_modal" ? (
                    <ImageDiv>
                      <img src={imageUrl ? imageUrl : image} alt="bl-image" />
                    </ImageDiv>
                  ) : (
                    ""
                  )}
                  <InputDiv>
                    <input type="file" onChange={handlePhoto1} />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv className="upload-image">
                  <LabelDiv>
                    <Label>Upload the Thumbnail Image(370 x 189 px) </Label>
                  </LabelDiv>
                  {type == "edit_modal" ? (
                    <ImageDiv>
                      <img
                        src={thumbnailUrl ? thumbnailUrl : image}
                        alt="bl-mage"
                      />
                    </ImageDiv>
                  ) : (
                    ""
                  )}
                  <InputDiv>
                    <input type="file" onChange={handleThumbnail} />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv>
                  <LabelDiv>
                    <Label>Keywords </Label>
                  </LabelDiv>
                  <InputDiv>
                    <input
                      type="text"
                      placeholder="Enter keyword"
                      value={keywords}
                      onChange={(e) => setKeywords(e.target.value)}
                    />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv>
                  <LabelDiv>
                    <Label>Meta Title </Label>
                  </LabelDiv>
                  <InputDiv>
                    <input
                      type="text"
                      placeholder="Enter meta title"
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv>
                  <LabelDiv>
                    <Label>Meta Description </Label>
                  </LabelDiv>
                  <InputDiv>
                    <input
                      type="text"
                      placeholder="Enter meta description"
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv>
                  <LabelDiv>
                    <Label>Slug </Label>
                  </LabelDiv>
                  <InputDiv>
                    <input
                      type="text"
                      placeholder="slug"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv className="button-container">
                  {type == "edit_modal" ? (
                    <Button
                      onClick={() => {
                        editBlog();
                      }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        addBlog();
                      }}
                    >
                      Submit
                    </Button>
                  )}
                  <Button className="cancel" onClick={() => setModal(false)}>
                    Cancel
                  </Button>
                </FieldDiv>
              </Form>
            </Wrapper>
          </MainContainer>
        </Modal>
      </Container>
    </Cover>
  );
};

export default ModalForm;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  height: 90vh;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  padding-bottom: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const MainContainer = styled.div``;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Form = styled.form``;
const MainTitle = styled.h2`
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const FieldDiv = styled.div`
  margin-top: 15px;
  &.upload-image {
    font-size: 10px;
  }
  &.button-container {
    display: flex;
    gap: 10px;
  }
`;
const LabelDiv = styled.div``;
const ImageDiv = styled.div`
  width: 20%;
  margin-top: 10px;
  img {
    width: 100%;
    display: block;
  }
`;
const InputDiv = styled.div`
  margin-top: 5px;
  input {
    width: 100%;
    padding: 5px;
  }
`;
const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
`;

const Button = styled.div`
  background-color: var(--button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
  cursor: pointer;
  &.cancel {
    background-color: #fff;
    color: red;
    border: 1px solid red;
  }
`;
