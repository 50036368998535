import React, { useState } from "react";
import styled from "styled-components";

import Search from "../../assets/images/product/Search.svg";
import { useNavigate } from "react-router-dom";

const UserDetails = ({ setSearchQuery }) => {
  return (
    <Wrapper>
      <Container>
        <TotalDiv>
          <RightContainer>
            <SearchInput
              type="text"
              placeholder="Serach Here"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <ImageContainer>
              <img src={Search} alt="Search" />
            </ImageContainer>
          </RightContainer>
        </TotalDiv>
      </Container>
    </Wrapper>
  );
};

export default UserDetails;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 20px;
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const TotalDiv = styled.div`
  display: flex;
  gap: 20px;
`;

const ImageContainer = styled.div`
  width: 20px;
  img {
    width: 100%;
    display: block;
    filter: invert(100%);
  }
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const SearchInput = styled.input`
  padding: 6px;
  border: 1px solid var(--button-color);
  color: #000;
  font-weight: 600;
  border-radius: 5px;
  width: 300px;
  @media all and (max-width: 768px) {
    width: 200px;
  }
  @media all and (max-width: 425px) {
    width: 150px;
  }
`;
