import React from "react";
import buttonLoaderAnimation from "../assets/loader/button_loader.json";
import LottieLoader from "react-lottie-loader";
import styled from "styled-components";

const ButtonLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: buttonLoaderAnimation,
    rendererSettings: {},
  };
  return (
    <Container>
      <LottieLoader
        animationData={buttonLoaderAnimation}
        loop={true}
        height={60}
        width={60}
      />
    </Container>
  );
};

export default ButtonLoader;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  margin: 0 auto;
`;
