import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "./reuse/Card";

const TopContainer = ({
  countData,
  data,
  title,
  title2,
  type,
  title3,
  userCount,
}) => {
  return (
    <MainContainer>
      <Container>
        <Top>
          <MainTitle>{title}</MainTitle>
          {type === "product_sale" ? (
            <Count>
              {countData?.length == 0 ? "0" : countData?.total_sale_by_quandity}
            </Count>
          ) : (
            <Count>
              {countData?.length == 0
                ? "0"
                : countData?.total_payment_recieved ||
                  countData?.total_sale_count}
            </Count>
          )}
        </Top>
      </Container>
      <Container>
        <Top>
          <MainTitle>{title2}</MainTitle>
          {type === "product_sale" ? (
            <Count>
              {countData?.length == 0 ? "0" : countData?.total_sale_by_amount}
            </Count>
          ) : (
            <Count>
              {countData?.length == 0
                ? "0"
                : countData?.total_order_receieved ||
                  countData?.total_order_count}
            </Count>
          )}
        </Top>
      </Container>
      {type == "student_count" ? (
        <Container>
          <Top>
            <MainTitle>Users Count</MainTitle>
            <Count>{userCount}</Count>
          </Top>
        </Container>
      ) : (
        ""
      )}
      {type === "product_sale" ? (
        <Container>
          <Top>
            <MainTitle>{title3}</MainTitle>
            {type === "product_sale" && (
              <Count>
                {countData?.length == 0 ? "0" : countData?.total_order}
              </Count>
            )}
          </Top>
        </Container>
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default TopContainer;

const MainContainer = styled.div`
  display: flex;
  @media all and (max-width: 980px) {
    flex-wrap: wrap;
  }
`;
const Container = styled.div`
  width: 30%;
  height: 200px;
  margin: 10px 10px;
  background: rgb(244, 172, 137);
  background: linear-gradient(
    356deg,
    rgba(244, 172, 137, 1) 28%,
    rgba(244, 153, 223, 1) 76%
  );
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: scroll;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const MainTitle = styled.div`
  color: #fff;
  font-weight: 700;
  text-align: center;
`;
const Count = styled.div`
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 55px;
`;
const Top = styled.div`
  width: 100%;
`;
const CardContainer = styled.div`
  width: 30%;
  height: 200px;
  margin: 10px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (max-width: 980px) {
    width: 100%;
    margin-top: 40px;
  }
  @media all and (max-width: 980px) {
    margin-top: 20px;
  }
`;
