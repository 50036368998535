import React from "react";
import styled from "styled-components";

const FeaturedCollectionFormModal = ({
  modal,
  setModal,
  type,
  title,
  url,
  video,
  videoUrl,
  setTitle,
  setUrl,
  setVideo,
  setVideoUrl,
  handleSubmit,
}) => {
  const handleVideo = (event) => {
    setVideo(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setVideoUrl(imageContent);
    };
  };
  console.log(title, "title");
  return (
    <Cover>
      <Container className={modal && "active"}>
        <Overlay onClick={() => setModal(false)}></Overlay>
        <Modal>
          <MainContainer>
            <Wrapper>
              {type == "edit_modal" ? (
                <MainTitle>Edit Blog</MainTitle>
              ) : (
                <MainTitle>Add Blog</MainTitle>
              )}

              <Form>
                <FieldDiv>
                  <LabeDiv>
                    <Label>Title</Label>
                  </LabeDiv>
                  <InputDiv>
                    <input
                      type="text"
                      placeholder="Enter the title"
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                    />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv>
                  <LabeDiv>
                    <Label>Url</Label>
                  </LabeDiv>
                  <InputDiv>
                    <input
                      type="text"
                      placeholder="Enter the url"
                      onChange={(e) => setUrl(e.target.value)}
                      value={url}
                    />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv>
                  <LabeDiv>
                    <Label>Video</Label>
                  </LabeDiv>
                  <InputDiv>
                    <input type="file" onChange={handleVideo} />
                  </InputDiv>
                </FieldDiv>

                <FieldDiv className="button-container">
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                  <Button className="cancel" onClick={(e) => setModal(false)}>
                    Cancel
                  </Button>
                </FieldDiv>
              </Form>
            </Wrapper>
          </MainContainer>
        </Modal>
      </Container>
    </Cover>
  );
};

export default FeaturedCollectionFormModal;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  padding-bottom: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const MainContainer = styled.div``;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const MainTitle = styled.h2`
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const Form = styled.form``;
const FieldDiv = styled.div`
  margin-top: 15px;
  &.button-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;
const LabeDiv = styled.div``;
const Label = styled.label``;
const InputDiv = styled.div`
  margin-top: 8px;
  input {
    padding: 5px;
    width: 100%;
  }
`;
const Button = styled.div`
  background-color: var(--button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
  cursor: pointer;
  &.cancel {
    background-color: #fff;
    color: red;
    border: 1px solid red;
  }
`;
