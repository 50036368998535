import React from "react";
import styled from "styled-components";

const ProductCount = ({ productCount }) => {
  return (
    <Wrapper>
      <MainContainer>
        <Container>
          <Top>
            <MainTitle>Total Product Count</MainTitle>
            <Count>{productCount.active_products_total_count}</Count>
          </Top>
        </Container>
      </MainContainer>
    </Wrapper>
  );
};

export default ProductCount;

const MainContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 30%;
  height: 200px;
  margin: 10px 10px;
  background: rgb(244, 172, 137);
  background: linear-gradient(
    356deg,
    rgba(244, 172, 137, 1) 28%,
    rgba(244, 153, 223, 1) 76%
  );
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: scroll;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const MainTitle = styled.div`
  color: #fff;
  font-weight: 700;
  text-align: center;
`;
const Count = styled.div`
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 55px;
`;
const Top = styled.div`
  width: 100%;
`;
