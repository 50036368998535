import React, { useState } from "react";
import styled from "styled-components";

import Close from "../../../assets/images/product/close.svg";
import EmptyImage from "../../../assets/images/product/image.png";

import { productConfig } from "../../../../axiosConfig";
import { useBearStore } from "../../../context/Store";

const AddProductCategoryModal = ({ addCategoryModal, setCategoryModal }) => {
  const userData = useBearStore((state) => state.userData);
  const [categoryName, setCategoryName] = useState("");
  const [orderid, setOrderid] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const ClearData = () => {
    setCategoryName("");
    setOrderid("");
    setImage("");
    setImageUrl("");
  };

  const handleImage = (event) => {
    setImage(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl(imageContent);
    };
  };

  const addproductcategory = () => {
    const formData = new FormData();
    formData.append("category_name", categoryName);
    formData.append("order_id", orderid);
    formData.append("image", image);
    if (categoryName && orderid) {
      productConfig
        .post(`add-product-categories/`, formData, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          let { StatusCode, app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            ClearData();
            setCategoryModal(false);
          }
        });
    }
  };
console.log(categoryName,"name");
  return (
    <Cover>
      <Container className={addCategoryModal && "active"}>
        <Overlay onClick={() => setCategoryModal(false)}></Overlay>
        <Modal>
          <Wrapper>
            <TopContainer>
              <CloseDiv
                onClick={() => {
                  ClearData();
                  setCategoryModal(false);
                }}
              >
                <img src={Close} alt="Close" />
              </CloseDiv>
            </TopContainer>
            <ContentContainer>
              <Form>
                <NameDiv>
                  <Label>Category Name : </Label>
                  <input
                    type="text"
                    placeholder="Enter the Category name"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </NameDiv>
                <OderIdDiv>
                  <Label> Order id : </Label>
                  <input
                    type="text"
                    placeholder="Enter the Order id name"
                    value={orderid}
                    onChange={(e) => setOrderid(e.target.value)}
                  />
                </OderIdDiv>

                <ImageContainer>
                  <ImageDiv>
                    <img src={imageUrl ? imageUrl : EmptyImage} alt="Empty" />
                  </ImageDiv>
                  <ImageInputDiv>
                    <Label className="intruction"> size : 210px x 210px</Label>
                    <input type="file" onChange={handleImage} />
                  </ImageInputDiv>
                </ImageContainer>
              </Form>
            </ContentContainer>
            <ButtonContainer>
              <Button
                onClick={() => {
                  addproductcategory();
                }}
              >
                Submit
              </Button>
            </ButtonContainer>
          </Wrapper>
        </Modal>
      </Container>
    </Cover>
  );
};

export default AddProductCategoryModal;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  padding-bottom: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
`;
const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;
const CloseDiv = styled.div`
  width: 3%;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
`;
const ContentContainer = styled.div`
  padding: 10px;
`;
const Form = styled.form``;
const NameDiv = styled.div`
  width: 100%;
  input {
    width: 80%;
    margin-top: 10px;
    padding: 5px;
  }
`;
const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
  &.intruction {
    color: red;
    margin-right: 10px;
  }
`;
const OderIdDiv = styled.div`
  width: 100%;
  margin-top: 30px;
  input {
    width: 80%;
    margin-top: 10px;
    padding: 5px;
    margin-left: 45px;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 30px;
  width: 100%;
`;
const Button = styled.div`
  background: var(--button-color);
  width: 30%;
  text-align: center;
  padding: 5px;
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
`;

const ImageDiv = styled.div`
  width: 20%;
  img {
    width: 100%;
    display: block;
  }
`;
const ImageInputDiv = styled.div``;
const ImageContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
`;
