import React from "react";
import "remixicon/fonts/remixicon.css";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./components/routing/router/MainRouter";

const App = () => {
  return (
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  );
};

export default App;
