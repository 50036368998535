import React from "react";

import Search from "../../assets/images/product/Search.svg";
import styled from "styled-components";

const Order = ({ setSearchQuery, order, filter, setFilter }) => {
  return (
    <Wrapper>
      <Container>
        <TopContainer>
          <Heading>Orders</Heading>
        </TopContainer>
        <TotalDiv>
          <SearchDiv>
            <ImageContainer>
              <img src={Search} alt="Search" />
            </ImageContainer>
            <input
              type="text"
              placeholder="Serach Here"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </SearchDiv>
          <FilterDiv>
            <IconDiv>
              <i class="ri-equalizer-line"></i>
            </IconDiv>
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value="none" selected disabled hidden>
                --------
              </option>
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="canceled">Canceled</option>
            </select>
          </FilterDiv>
        </TotalDiv>
      </Container>
    </Wrapper>
  );
};

export default Order;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
`;
const TopContainer = styled.div``;
const Heading = styled.h2`
  @media all and (max-width: 425px) {
    font-size: 18px;
  }
  @media all and (max-width: 375px) {
    display: none;
  }
`;
const TotalDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SearchDiv = styled.div`
  margin-right: 20px;
  position: relative;
  padding: 6px 6px 6px 30px;
  border: 1px solid var(--button-color);
  color: rgb(0, 0, 0);
  font-weight: 600;
  border-radius: 5px;
  input {
    height: 20px;
    border: none;
    outline: none;
  }
  @media all and (max-width: 640px) {
    input {
      width: 50%;
    }
  }
  @media all and (max-width: 425px) {
    width: 71px;
    input {
      width: 100%;
      padding: 3px;
    }
  }
  @media all and (max-width: 320px) {
    width: 55px;
  }
`;
const ImageContainer = styled.div`
  width: 8%;
  position: absolute;
  top: 27%;
  left: 4%;
  img {
    width: 100%;
    display: block;
    filter: invert(100%);
  }
  @media all and (max-width: 425px) {
    width: 14%;
    position: absolute;
    top: 30%;
    left: 6%;
  }
`;
const FilterDiv = styled.div`
  position: relative;
  select {
    padding: 5px 5px 5px 20px;
    border: none;
  }
`;
const IconDiv = styled.div`
  position: absolute;
  top: 20%;
`;
