import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import Order from "./Order";
import OrderDetails from "./OrderDetails";
import OrderList from "./OrderList";
import { paymentConfig } from "../../../axiosConfig";
import { useBearStore, useUnreadOrderCountStore } from "../../context/Store";
import { Outlet, useSearchParams } from "react-router-dom";

const OrderScreen = () => {
  const userData = useBearStore((state) => state.userData);
  const setUnreadCount = useUnreadOrderCountStore(
    (state) => state.setUnreadCount
  );
  const [order, setOrder] = useState([]);
  const [pagination, setPagination] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState();

  useEffect(() => {
    paymentConfig
      .get(`orders/`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
        params: {
          q: searchQuery,
          status: filter,
          page: currentPage,
        },
      })
      .then((response) => {
        const { app_data, data } = response.data;
        if (app_data.StatusCode === 6000) {
          setOrder(app_data.data);
          setPagination(app_data.pagination_data);
          setUnreadCount(app_data.unread_orders);
        }
      });
  }, [searchQuery, filter, currentPage]);

  return (
    <>
      <Cover>
        <Order
          setSearchQuery={setSearchQuery}
          order={order}
          setFilter={setFilter}
          filter={filter}
        />
        <OrderList
          order={order}
          setOrder={setOrder}
          pagination={pagination}
          setPagination={setPagination}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Cover>
      <Outlet />
    </>
  );
};

export default OrderScreen;
