import React from "react";
import OfferBannerNavList from "./OfferBannerNavList";
import styled from "styled-components";
import empty from "../../assets/images/product/image.png";
import DeleteModal from "../blogs/modal/DeleteModal";
import OfferBanerModal from "./modal/OfferBanerModal";
import { useBearStore, useOfferBannerIdStore } from "../../context/Store";
import { webConfig } from "../../../axiosConfig";

const OfferBannerListPage = ({
  editOfferBannerModal,
  setEditOfferBannerModal,
  offerBannerData,
  setDeleteOfferBanner,
  deleteOfferBaner,
  fetchOfferBannerData,
  setDeleteBanner,
  deleteBanner,
}) => {
  const setOfferBanneId = useOfferBannerIdStore(
    (state) => state.setOfferBannerId
  );

  const userData = useBearStore((state) => state.userData);
  const OfferBanneId = useOfferBannerIdStore((state) => state.OfferBannerId);

  const handleDelete = () => {
    OfferBanneId &&
      webConfig
        .post(
          `delete-offer-banner/${OfferBanneId}/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userData.accessToken}`,
            },
          }
        )
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            // setDeleteBanner(!deleteBanner);
            fetchOfferBannerData();
            setDeleteOfferBanner(false);
          }
        });
  };

  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <MainTitle>Offer Banner</MainTitle>
        </TitleContainer>
        <OfferBannerNavList />
        {offerBannerData?.map((item, index) => (
          <ItemContainer key={item.id}>
            <ItemList>{index + 1}</ItemList>
            <ItemList>
              <ImageDiv>
                <img src={item.image} alt="offer-banner" />
              </ImageDiv>
            </ItemList>
            <ItemList>
              <ImageDiv>
                <img src={item.mob_image} alt="offer-banner" />
              </ImageDiv>
            </ItemList>
            <ItemList onClick={() => setOfferBanneId(item.id)}>
              {/* <EditContainer onClick={() => setEditOfferBannerModal(true)}>
                <i className="ri-edit-box-fill"></i>
              </EditContainer> */}
              <DeleteContainer onClick={() => setDeleteOfferBanner(true)}>
                <i className="ri-delete-bin-fill"></i>
              </DeleteContainer>
            </ItemList>
          </ItemContainer>
        ))}
      </Container>
      <DeleteModal
        setDeleteModal={setDeleteOfferBanner}
        deleteModal={deleteOfferBaner}
        handleDeleteSubmit={handleDelete}
      />

      <OfferBanerModal
        setModal={setEditOfferBannerModal}
        modal={editOfferBannerModal}
        type="edit_modal"
      />
    </Wrapper>
  );
};

export default OfferBannerListPage;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Container = styled.div`
  max-width: 100%;
  overflow: scroll;
`;
const TitleContainer = styled.div``;
const MainTitle = styled.h2`
  margin: 30px 50px;
  @media all and (max-width: 980px) {
    font-size: 15px;
    margin: 30px 10px;
  }
`;
const ItemContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
  padding-top: 30px;
  height: 50px;
  @media all and (max-width: 980px) {
    min-width: 1180px;
  }
`;
const ItemList = styled.li`
  text-align: center;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
  font-size: 14px;
  margin-left: 30px;
  img {
    display: block;
    width: 100%;
  }
  &.status {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  &.slno {
    display: flex;
    justify-content: flex-end;
  }
`;
const EditContainer = styled.div`
  cursor: pointer;
`;
const DeleteContainer = styled.div`
  cursor: pointer;

  i {
    color: red;
  }
`;
const ImageDiv = styled.div`
  width: 50%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
`;
