import React from "react";
import styled from "styled-components";

import Close from "../../../assets/images/product/close.svg";

const AddVariantModal = ({
  variantModal,
  setVariantModal,
  setVariantSize,
  setVariantPrice,
  setVariantUnit,
  addVariantProduct,
  setVariantLeftCount,
  variantLeftCount,
}) => {
  return (
    <Cover>
      <Container className={variantModal && "active"}>
        <Overlay onClick={() => setVariantModal(false)}></Overlay>
        <Modal>
          <Wrapper>
            <TopContainer>
              <CloseDiv onClick={() => setVariantModal(false)}>
                <img src={Close} alt="Close" />
              </CloseDiv>
            </TopContainer>
            <ContentContainer>
              <Form>
                <SizeDiv>
                  <Label> Size: </Label>
                  <input
                    type="text"
                    placeholder="Enter the Size"
                    onChange={(e) => setVariantSize(e.target.value)}
                  />
                </SizeDiv>
                <PriceDiv>
                  <Label> Price : </Label>
                  <input
                    type="text"
                    placeholder="Enter the Price"
                    onChange={(e) => setVariantPrice(e.target.value)}
                  />
                </PriceDiv>
                <PriceDiv>
                  <Label> Left Count : </Label>
                  <input
                    type="text"
                    placeholder="Enter the Count"
                    onChange={(e) => setVariantLeftCount(e.target.value)}
                  />
                </PriceDiv>
                <UnitDiv>
                  <Label>Units : </Label>
                  <select
                    name="units"
                    id="units"
                    onChange={(e) => setVariantUnit(e.target.value)}
                  >
                    <option value="kg">Kg</option>
                    <option value="g">G</option>
                    <option value="l">L</option>
                    <option value="ml">Ml</option>
                  </select>
                </UnitDiv>
              </Form>
            </ContentContainer>
            <ButtonContainer>
              <Button
                onClick={() => {
                  addVariantProduct();
                }}
              >
                Submit
              </Button>
            </ButtonContainer>
          </Wrapper>
        </Modal>
      </Container>
    </Cover>
  );
};

export default AddVariantModal;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  padding-bottom: 30px;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
`;
const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;
const CloseDiv = styled.div`
  width: 3%;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
`;
const ContentContainer = styled.div`
  padding: 10px;
`;
const Form = styled.form``;
const SizeDiv = styled.div`
  width: 100%;
  input {
    width: 100%;
    margin-top: 10px;
    padding: 5px;
  }
`;
const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
`;

const PriceDiv = styled.div`
  margin-top: 20px;
  input {
    padding: 5px;
    width: 100%;
    margin-top: 10px;
  }
`;
const UnitDiv = styled.div`
  margin-top: 20px;
  select {
    padding: 5px;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 30px;
  width: 100%;
`;
const Button = styled.div`
  background: var(--button-color);
  width: 30%;
  text-align: center;
  padding: 8px;
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
`;
