import React from "react";
import styled from "styled-components";

const Stages = ({ status, setStatus }) => {
  return (
    <>
      <NavContainer>
        <Nav
          onClick={() => {
            setStatus("OnBoarding");
          }}
          className={status === "OnBoarding" ? "active" : ""}
        >
          Onboard
        </Nav>
        <Nav
          onClick={() => {
            setStatus("Procuring");
          }}
          className={status === "Procuring" ? "active" : ""}
        >
          Procuring
        </Nav>
        <Nav
          onClick={() => {
            setStatus("Process");
          }}
          className={status === "Process" ? "active" : ""}
        >
          Process
        </Nav>
        <Nav
          onClick={() => {
            setStatus("QualityCheck");
          }}
          className={status === "QualityCheck" ? "active" : ""}
        >
          Quality Check
        </Nav>
        <Nav
          onClick={() => {
            setStatus("Package");
          }}
          className={status === "Package" ? "active" : ""}
        >
          Package
        </Nav>
        {/* <Nav
          onClick={() => {
            setStatus("TraceX");
          }}
          className={status === "TraceX" ? "active" : ""}
        >
          TraceX Links
        </Nav> */}
      </NavContainer>
    </>
  );
};

export default Stages;

const NavContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  background-color: var(--button-color);
  cursor: pointer;
  padding: 30px;
  border-radius: 8px;
  min-width: 1080px;
  @media all and (max-width: 768px) {
    min-width: 980px;
  }
  @media all and (max-width: 375px) {
    min-width: 768px;
  }
`;
const Nav = styled.div`
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-weight: 600;
  border-radius: 5px;
  font-size: 12px;
  width: 18.3%;
  height: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    background: #ffaac9;
    color: #fff;
  }
`;
