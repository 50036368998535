import React from "react";
import styled from "styled-components";

const OrderViewTitle = () => {
  return (
    <div>
      <Cover>
        <ItemContainer>
          <Item type="photo">Product</Item>
          <Item type="name">Name</Item>
          <Item type="text">Size</Item>
          <Item type="text">Price</Item>
          <Item type="text">Quantity</Item>
          <Item type="text">Total Price</Item>
          <Item type="text">Confirmation Status</Item>
          <Item type="text">Package Status</Item>
          <Item type="text">Dispatch</Item>
          {/* <Item type="text">Shipment</Item> */}
        </ItemContainer>
      </Cover>
    </div>
  );
};

export default OrderViewTitle;

const Cover = styled.div`
  min-width: 1180px;
`;
const ItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px;
  text-align: center;
`;

const Item = styled.li`
  font-size: 14px;
  font-weight: 700;
  width: ${({ type }) =>
    type == "photo"
      ? "8%"
      : type == "text"
      ? "8%"
      : type == "name"
      ? "15%"
      : "15%"};
`;
