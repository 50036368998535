import { create } from "zustand";

export const useBearStore = create((set) => ({
  userData: localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : {
        userData: {
          username: "",
          isVerified: false,
          accessToken: "",
        },
      },
  updateUserData: (username, isVerified, accessToken) =>
    set((state) => {
      const userData = {
        username: username,
        isVerified: isVerified,
        accessToken: accessToken,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
      return {
        userData: userData,
      };
    }),
  removeAllBears: () => set({ bears: 0 }),
}));

export const useAppStore = create((set) => ({
  isSideBar: true,
  handleSidebar: () =>
    set((state) => {
      return {
        isSideBar: !state.isSideBar,
      };
    }),
}));

export const useBannerIdStore = create((set) => ({
  bannerId: "",
  setBannerId: (id) => set({ bannerId: id }),
}));

export const useUnreadOrderCountStore = create((set) => ({
  unreadCount: localStorage.getItem("unreadCount"),
  setUnreadCount: (count) => {
    localStorage.setItem("unreadCount", count);
    set({ unreadCount: count });
  },
}));

export const useOfferIdStore = create((set) => ({
  offerId: localStorage.getItem("offerId"),
  setOfferId: (id) => {
    localStorage.setItem("offerId", id);
    set({ offerId: id });
  },
}));

export const useCategoryIdStore = create((set) => ({
  categoryId: "",
  setCategoryId: (id) => set({ categoryId: id }),
}));

export const useBlogIdStore = create((set) => ({
  BlogId: "",
  setBlogId: (id) => set({ BlogId: id }),
}));

export const useFeaturedIdStore = create((set) => ({
  FeaturedId: "",
  setFeaturedId: (id) => set({ FeaturedId: id }),
}));

export const useOfferBannerIdStore = create((set) => ({
  OfferBannerId: "",
  setOfferBannerId: (id) => set({ OfferBannerId: id }),
}));
