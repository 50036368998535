import React from "react";
import styled from "styled-components";

const ProductCategoryNav = () => {
  return (
    <Cover>
      <ItemContainer>
        <Item type="name">Category Name</Item>
        <Item type="name">Category Image</Item>
        <Item type="name">Category Order Id</Item>
        <Item type="name">Actions</Item>
      </ItemContainer>
    </Cover>
  );
};

export default ProductCategoryNav;

const ItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;
const Cover = styled.div`
  @media all and (max-width: 980px) {
    min-width: 1180px;
  }
`;
const Item = styled.li`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
`;
