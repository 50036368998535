import React from "react";
import styled from "styled-components";
import Plus from "../../../assets/images/product/plus_icon.svg";

export const OfferTopConatiner = ({ buttonClickFunction }) => {
  return (
    <MainContainer>
      <Container>
        <Button onClick={() => buttonClickFunction(true)}>
          <ImageDiv>
            <img src={Plus} alt="Add" />
          </ImageDiv>
          <Span>Add Offer</Span>
        </Button>
      </Container>
    </MainContainer>
  );
};
const MainContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`;
const Container = styled.div``;
const Button = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 5px;
  background: var(--button-color);
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  width: 150px;
  font-size: 12px;

  cursor: pointer;
  &.product-category {
    width: 200px;
    /* font-size: 12px; */
  }
`;
const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  padding: 10px 0px;
  img {
    display: block;
    width: 20px;
  }
`;
const Span = styled.span``;
