import React, { useRef } from "react";
import styled from "styled-components";
import { formSchema } from "../../products/froms/FormSchema";
import { elements } from "chart.js";
import { productConfig } from "../../../../axiosConfig";
import { useBearStore } from "../../../context/Store";

const TraceX = ({ modal, setModal, products, get_products }) => {
  const userData = useBearStore((state) => state.userData);

  const inputRefs = useRef({});

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();

    formSchema.forEach((element) => {
      const inputValue = inputRefs.current[element.name]?.value;
      if (inputValue !== undefined) {
        formData.append(element.name, inputValue);
      }
    });

    productConfig
      .post("add-tracex-batch/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          formSchema.forEach((element) => {
            inputRefs.current[element.name].value = "";
          });
          setModal(false);
          get_products();
        }
      });
  };

  const handleInvalid = (event) => {
    const field = event.target;
    console.log(field, "field ===>");
    const fieldName = field.name.charAt(0).toUpperCase() + field.name.slice(1);
    const errorDisplayElement = field.nextElementSibling;

    errorDisplayElement.textContent = `${fieldName} is required.`;
    errorDisplayElement.style.display = "block";
  };

  // error message comes next child of input <Error> component
  const handleInput = (event) => {
    const errorDisplayElement = event.target.nextElementSibling;
    errorDisplayElement.style.display = "none";
  };

  return (
    <Cover>
      <Container className={modal && "active"}>
        <Overlay onClick={() => setModal(false)}></Overlay>
        <Modal>
          <Form onSubmit={(event) => handleSubmit(event)}>
            {formSchema &&
              formSchema.map((element, index) => (
                <div key={index}>
                  {element?.type === "text" ? (
                    <>
                      <Label
                        className={element?.is_required && "bold"}
                        htmlFor={element?.name}
                      >
                        {element?.label}
                        {element?.is_required && <span> *</span>}
                      </Label>

                      <Input
                        type="text"
                        id={element?.name}
                        placeholder={element?.placeholder}
                        name={element?.name}
                        required={element?.is_required}
                        onInvalid={handleInvalid}
                        onInput={handleInput}
                        ref={(input) =>
                          (inputRefs.current[element?.name] = input)
                        }
                      />
                      <Error></Error>
                    </>
                  ) : element?.type === "select" ? (
                    <>
                      <Label
                        className={element?.is_required && "bold"}
                        htmlFor={element?.name}
                      >
                        {element?.label}
                        {element?.is_required && <span> *</span>}
                      </Label>
                      <Select
                        id={element?.name}
                        name={element?.name}
                        required={element?.is_required}
                        onInvalid={handleInvalid}
                        onInput={handleInput}
                        ref={(input) =>
                          (inputRefs.current[element?.name] = input)
                        }
                      >
                        <option></option>
                        {products.map((product) => (
                          <>
                            <option value={product?.id}>{product?.name}</option>
                          </>
                        ))}
                      </Select>
                      <Error></Error>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            <SubmitButton type="submit" value="Submit" />
          </Form>
        </Modal>
      </Container>
    </Cover>
  );
};

export default TraceX;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  /* height: 50vh; */
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  padding: 30px;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const Form = styled.form``;
const Label = styled.label`
  margin-bottom: 5px;
  display: block;
  color: #333;
  font-size: 16px;
  &.bold {
    font-weight: bold;
  }
`;
const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border-color: var(--button-color);
    outline: none;
  }
`;
const Select = styled.select`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background-color: white;

  &:focus {
    border-color: var(--button-color);
    outline: none;
  }
`;
const Error = styled.p`
  color: red;
  font-size: 16px;
  margin-bottom: 15px;
`;
const SubmitButton = styled.input`
  padding: 10px 20px;
  background-color: var(--button-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  transition: background-color 0.3s ease;
  margin-top: 10px;

  &:hover {
    background-color: var(--button-color);
  }

  &:focus {
    outline: none;
  }
`;
