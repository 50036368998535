import React from "react";

// packages
import { Pie } from "react-chartjs-2";
import { Chart, Tooltip, Title, ArcElement } from "chart.js";
import styled from "styled-components";

function DougnutChartGraph(filtercountData, setFilterCountData) {
  Chart.register(Title, ArcElement, Tooltip);
  let no_data = "";
  if (
    filtercountData.filtercountData.total_order === 0 &&
    filtercountData.filtercountData.total_payments === 0 &&
    filtercountData.filtercountData.total_sale === 0
  ) {
    no_data = filtercountData.total_order + 1;
  }
  const options = {
    plugins: {
      title: {
        display: true,
        text: "Payment Status",
      },
    },
  };
  return (
    <ChartContainer>
      <Cover>
        <Pie
          options={options}
          data={{
            labels: ["no_data", "Total orders", "Total Payments", "Total Sale"],
            datasets: [
              {
                label: "Analysis",
                data: [
                  no_data,
                  filtercountData.filtercountData.total_order,
                  filtercountData.filtercountData.total_payments,
                  filtercountData.filtercountData.total_sale,
                ],
                backgroundColor: [
                  "#f80a0a",
                  "rgb(255, 144, 9)",
                  "rgb(7, 150, 216)",
                  "rgb(244, 153, 223)",
                ],
                hoverOffset: 4,
                borderWidth: 0,
                cutout: 90,
              },
            ],
          }}
        />
        <Percentage>
          <Percent></Percent>
        </Percentage>
      </Cover>
    </ChartContainer>
  );
}
export default DougnutChartGraph;

const ChartContainer = styled.div`
  position: relative;
  width: 330px;
  margin-top: 20px;
  @media all and (max-width: 400px) {
    width: 260px;
  }
`;
const Percentage = styled.div``;
const Percent = styled.p`
  transform: translate(15px, 23px);
  font-family: "gordita_medium";
  font-size: 18px;
  position: absolute;
  display: inline-block;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Cover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
