import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import styled from "styled-components";

import Plus from "../../assets/images/product/plus_icon.svg";
import OrderViewTitle from "./OrderViewTitle";
import { useBearStore, useDispatchStore } from "../../context/Store";
import { paymentConfig } from "../../../axiosConfig";
import { useParams } from "react-router-dom";
import AddShipmentModal from "./modal/AddShipmentModal";

const OrderView = () => {
  const userData = useBearStore((state) => state.userData);
  const [orderData, setOrderData] = useState([]);
  const [confirmation, setConfirmation] = useState("");
  const [dispatched, setDispatched] = useState("");
  const [packaged, setPackaged] = useState("");
  const [addShipmentModal, setShipmentModal] = useState(false);
  const [pickup, setPickup] = useState([]);
  const [pickupId, setPickupId] = useState();
  const { id } = useParams();

  const fetchSingleOrder = () => {
    paymentConfig
      .get(`orders/${id}/`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setOrderData(app_data.data.data);
        }
      });
  };

  const fetchPickupAddresses = () => {
    paymentConfig
      .get("get-pickup-address/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setPickup(app_data.data);
        }
      });
  };

  useEffect(() => {
    fetchSingleOrder();
    fetchPickupAddresses();
  }, [id, confirmation]);

  const updateStatus = () => {
    const formData = new FormData();
    formData.append("is_confirm", confirmation);
    formData.append("is_packed", packaged);
    formData.append("is_dispatched", dispatched);

    paymentConfig
      .post(`order-confirmation/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          window.location.reload();
        }
      });
  };

  const exportOrder = () => {
    paymentConfig
      .get(`print-order/${id}`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = url;
        link.download = `${orderData.profile?.name}-order-detail.pdf`; // Set the desired file name

        // Append the link to the document and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  };

  const createShipment = () => {
    const formData = new FormData();
    formData.append("order_id", orderData.order_id);
    formData.append("pickup_address_id", pickupId);
    if (orderData.order_id && pickupId) {
      // setLoader(true);
      paymentConfig
        .post("create-shipping/", formData, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          let { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            // setLoader(false);
            // window.location.reload();
          }
        });
    }
  };

  return (
    <Cover>
      <Wrapper>
        <Container>
          <TopContainer>
            <OrderIdContaner>
              <OrderId>{orderData.order_id}</OrderId>
              <Date>
                {orderData.created_month} {orderData.created_day},{" "}
                {orderData.created_year} at {orderData.created_time}
              </Date>
              {orderData?.razorpay_payment_id ? (
                <Date>
                  Razorpay Payment Id : {orderData?.razorpay_payment_id}
                </Date>
              ) : (
                <Date>Razorpay Payment Id : Payment not done</Date>
              )}
            </OrderIdContaner>
            <PrintButton onClick={() => exportOrder()}>Print</PrintButton>
            {/* <ShipingButton onClick={() => setShipmentModal(true)}>
              <ImageDiv>
                <img src={Plus} alt="Add" />
              </ImageDiv>
              <Span>Add Pickup Address</Span>
            </ShipingButton> */}
          </TopContainer>
          <OrderViewTitle />
          <OrderContainer>
            {orderData?.order_products?.map((item) => (
              <OrderItemContainer>
                <OrderItem type="photo">
                  <ProductImageDiv>
                    <img src={item.product_image} alt="Product" />
                  </ProductImageDiv>
                </OrderItem>
                <OrderItem type="name">{item.products}</OrderItem>
                <OrderItem type="text">
                  {item.size}
                  {item.size_unit}
                </OrderItem>
                <OrderItem type="text">{item.ordered_price}</OrderItem>
                <OrderItem type="text">{item.quantity}</OrderItem>
                <OrderItem type="text">{item.total_amount}</OrderItem>
                <OrderItem type="text" className="status-updation">
                  {item.is_confirm === true ? (
                    "Confirmed"
                  ) : (
                    <select
                      name="confirmation"
                      id="confirmation"
                      onChange={(e) => setConfirmation(e.target.value)}
                    >
                      <option>False</option>
                      <option>True</option>
                    </select>
                  )}
                </OrderItem>
                <OrderItem type="text" className="status-updation">
                  {item.is_packed === true ? (
                    "Packed"
                  ) : (
                    <select
                      name="packagation"
                      id="packagation"
                      onChange={(e) => setPackaged(e.target.value)}
                    >
                      <option>False</option>
                      <option>True</option>
                    </select>
                  )}
                </OrderItem>
                <OrderItem
                  type="text"
                  className={item.is_dispatched == true && "status-updation"}
                >
                  {item.is_dispatched == true ? (
                    "Dispatched"
                  ) : (
                    <>
                      <select
                        name="dispatched"
                        id="dispatched"
                        onChange={(e) => setDispatched(e.target.value)}
                      >
                        <option>False</option>
                        <option>True</option>

                        {/* {pickup.map((item) => (
                        <option value={item.id}>{item.ware_house_name}</option>
                      ))} */}
                      </select>
                    </>
                  )}

                  {/* <AddShipmentModal
                  addShipmentModal={addShipmentModal}
                  setShipmentModal={setShipmentModal}
                  orderData={orderData}
                /> */}
                </OrderItem>
                {/* <OrderItem>
                <DeleteContainer
                  className={orderData.is_dispatched ? "" : "inactive"}
                >
                  Cancel
                </DeleteContainer>
              </OrderItem> */}
              </OrderItemContainer>
            ))}
            <TotalDiv>
              <BillingContentDivContainer>
                <BillingContentDiv>
                  <BillingTitleDiv>
                    <BillingTitle>Items Subtotal:</BillingTitle>
                  </BillingTitleDiv>
                  <BillingValueDiv>
                    <BillingValue>₹ {orderData?.amount}</BillingValue>
                  </BillingValueDiv>
                </BillingContentDiv>
                <BillingContentDiv>
                  <BillingTitleDiv>
                    <BillingTitle>Shipping:</BillingTitle>
                  </BillingTitleDiv>
                  <BillingValueDiv>
                    <BillingValue>₹ {orderData?.shipping_charge}</BillingValue>
                  </BillingValueDiv>
                </BillingContentDiv>
              </BillingContentDivContainer>
              <BillingContentDiv>
                <BillingTitleDiv className="total">
                  <BillingTitle className="total">Paid:</BillingTitle>
                </BillingTitleDiv>
                <BillingValueDiv>
                  <BillingValue>
                    ₹ {orderData?.total_amount_to_be_paid}
                  </BillingValue>
                </BillingValueDiv>
              </BillingContentDiv>
            </TotalDiv>

            <ButtonContainer>
              <Button
                onClick={() => {
                  updateStatus();
                  // pickupId && createShipment();
                }}
              >
                Submit
              </Button>
            </ButtonContainer>
          </OrderContainer>
          <ProfileContainer>
            {orderData.shipping_address ? (
              <AddressContainer>
                <Title>Shipping Address</Title>
                <Name>{orderData.shipping_address?.name}</Name>
                <Sub>{orderData.shipping_address?.phone}</Sub>
                <Sub>{orderData.shipping_address?.house_name}</Sub>
                <Sub>{orderData.shipping_address?.street_name}</Sub>
                <Sub>{orderData.shipping_address?.pincode}</Sub>
                <Sub>{orderData.shipping_address?.district}</Sub>
                <Sub>{orderData.shipping_address?.state}</Sub>
                <Sub>INDIA</Sub>
              </AddressContainer>
            ) : (
              <AddressContainer>
                <Title>Shipping Address</Title>
                <Name>{orderData.profile?.name}</Name>
                <Sub>{orderData.profile?.phone}</Sub>
                <Sub>{orderData.profile?.house_name}</Sub>
                <Sub>{orderData.profile?.street_name}</Sub>
                <Sub>{orderData.profile?.pincode}</Sub>
                <Sub>{orderData.profile?.district}</Sub>
                <Sub>{orderData.profile?.state}</Sub>
                <Sub>INDIA</Sub>
              </AddressContainer>
            )}

            <UserContainer>
              <UserTitle>Profile</UserTitle>
              <User>{orderData.profile?.username}</User>
              <Name>{orderData.profile?.name}</Name>
              <Sub>{orderData.profile?.phone}</Sub>
            </UserContainer>
          </ProfileContainer>
        </Container>
      </Wrapper>
    </Cover>
  );
};

export default OrderView;

const Wrapper = styled.div`
  padding-top: 50px;
  width: 90%;
  margin: 0 auto;
`;
const Container = styled.div`
  max-width: 100%;
  overflow: scroll;
`;
const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 40px;
`;
const OrderIdContaner = styled.div`
  width: 75%;
`;
const OrderId = styled.h2`
  font-size: 34px;
`;
const Date = styled.h6`
  font-size: 10px;
  color: grey;
  margin-top: 10px;
`;
const OrderContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  min-width: 1180px;
  @media all and (max-width: 768px) {
    border-bottom: 1px solid #000;
  }
`;
const ProductImageDiv = styled.div`
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
`;
const ProfileContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AddressContainer = styled.div`
  margin-top: 20px;
`;
const Title = styled.h2`
  @media all and (max-width: 768px) {
    font-size: 12px;
  }
`;
const Name = styled.h4`
  margin-top: 10px;
  @media all and (max-width: 768px) {
    font-size: 10px;
  }
`;
const Sub = styled.h6``;
const UserContainer = styled.div``;
const User = styled.h5`
  margin-top: 15px;
`;
const OrderItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  &:first-child {
    margin-top: 0px;
  }
`;
const OrderItem = styled.div`
  width: ${({ type }) =>
    type == "photo"
      ? "8%"
      : type == "text"
      ? "8%"
      : type == "name"
      ? "15%"
      : ""};

  select {
    padding: 5px;
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 15px;
`;
const PrintButton = styled.div`
  background: var(--button-color);
  width: 10%;
  text-align: center;
  padding: 8px;
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
`;
const Button = styled.div`
  background: var(--button-color);
  width: 30%;
  text-align: center;
  padding: 8px;
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
`;
const ShipingButton = styled.div`
  background: var(--button-color);
  text-align: center;
  padding: 6px;
  margin: 0px auto;
  color: rgb(255, 255, 255);
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const UserTitle = styled.h2``;
const ImageDiv = styled.div`
  width: 15px;
  img {
    display: block;
    width: 100%;
  }
`;
const Span = styled.span``;
const DeleteContainer = styled.div`
  border: 1px solid red;
  color: red;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
  width: 84px;
  padding: 5px;
  cursor: pointer;
  &.inactive {
    cursor: not-allowed;
  }
`;
const TotalDiv = styled.div`
  margin-top: 40px;
  border-top: 1px dotted black;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
const BillingContentDiv = styled.div`
  display: flex;
  width: 100%;
  margin: 30px 30px;
`;
const BillingTitleDiv = styled.div`
  width: 30%;
  &.total {
    width: 12%;
  }
`;
const BillingTitle = styled.div`
  font-size: 12px;
  &.total {
    font-weight: 700;
  }
`;
const BillingValueDiv = styled.div`
  width: 30%;
`;
const BillingValue = styled.div``;
const BillingContentDivContainer = styled.div`
  width: 40%;
  border-bottom: 1px solid #000;
`;
