import React from "react";
import MainContainer from "../MainContainer";
import styled from "styled-components";
import TraceXDetails from "./TraceXDetails";

const TraceXLinkList = ({
  data,
  products,
  setFilterData,
  setTraceId,
  setDeleteTracexModal,
}) => {
  return (
    <div>
      <Container>
        <Select onChange={(e) => setFilterData(e.target.value)}>
          <option value="">--SELECT-PRODUCT------</option>
          {products.map((item, index) => (
            <option value={item.id}>{item.name}</option>
          ))}
        </Select>
        <TraceXDetails />
        {data.map((item, index) => (
          <ItemContainer key={index}>
            <Item type="slno">{index + 1}</Item>
            <Item type="name">{item.product}</Item>
            <Item type="name">{item.batch_no}</Item>
            <Item type="name" className="link">
              {item.link}
            </Item>
            <Item
              type="name"
              className="icon"
              onClick={() => {
                setTraceId(item.id);
                setDeleteTracexModal(true);
              }}
            >
              <i class="ri-delete-bin-fill"></i>
            </Item>
          </ItemContainer>
        ))}
      </Container>
    </div>
  );
};

export default TraceXLinkList;

const Container = styled.div`
  width: 100%;
`;
const Select = styled.select`
  padding: 3px;
  margin-bottom: 10px;
`;
const ItemContainer = styled.ul`
  display: flex;
  border-bottom: 1px solid #26272a;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  min-width: 1180px;
`;
const Item = styled.li`
  color: #000;
  cursor: pointer;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
  img {
    display: block;
    width: 50%;
  }
  &.icon {
    color: red;
  }
  &.link {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 12%;
    white-space: nowrap;
  }
`;
