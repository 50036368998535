import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import OfferBannerTopContainer from "./OfferBannerTopContainer";
import OfferBannerListPage from "./OfferBannerListPage";
import { webConfig } from "../../../axiosConfig";
import { useBearStore } from "../../context/Store";

const OfferBanerScreen = () => {
  const userData = useBearStore((state) => state.userData);
  const [addOfferBannerModal, setAddOfferBannerModal] = useState(false);
  const [editOfferBannerModal, setEditOfferBannerModal] = useState(false);
  const [deleteOfferBaner, setDeleteOfferBanner] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [offerBannerData, setOfferBannerData] = useState([]);
  const [deleteBanner, setDeleteBanner] = useState(false);

  const fetchOfferBannerData = () => {
    console.log("in  the function");
    webConfig
      .get("list-offer-banner/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setOfferBannerData(app_data.data);
        } else {
          setOfferBannerData([]);
          console.log(app_data.data.message);
        }
      });
  };

  useEffect(() => {
    fetchOfferBannerData();
  }, [deleteBanner]);

  return (
    <Cover>
      <OfferBannerTopContainer
        addOfferBannerModal={addOfferBannerModal}
        setAddOfferBannerModal={setAddOfferBannerModal}
        fetchOfferBannerData={fetchOfferBannerData}
        setLoading={setLoading}
        isLoading={isLoading}
        offerBannerData={offerBannerData}
      />
      <OfferBannerListPage
        editOfferBannerModal={editOfferBannerModal}
        setEditOfferBannerModal={setEditOfferBannerModal}
        offerBannerData={offerBannerData}
        setDeleteOfferBanner={setDeleteOfferBanner}
        deleteOfferBaner={deleteOfferBaner}
        fetchOfferBannerData={fetchOfferBannerData}
        setDeleteBanner={setDeleteBanner}
        deleteBanner={deleteBanner}
      />
    </Cover>
  );
};

export default OfferBanerScreen;
