import React from "react";
import styled from "styled-components";

import "../../assets/css/style.css";
import Plus from "../../assets/images/product/plus_icon.svg";
import Search from "../../assets/images/product/Search.svg";

const Products = ({
  addModal,
  setModal,
  addCategoryModal,
  setCategoryModal,
}) => {
  return (
    <Wrapper>
      <Container>
        <TopContainer>
          <Heading>Products</Heading>
        </TopContainer>
        <TotalDiv>
          <Button onClick={() => setModal(true)}>
            <ImageDiv>
              <img src={Plus} alt="Add" />
            </ImageDiv>
            <Span>Add Product</Span>
          </Button>
          <Button
            className="product-category"
            onClick={() => setCategoryModal(true)}
          >
            <ImageDiv>
              <img src={Plus} alt="Add" />
            </ImageDiv>
            <Span>Add Product Category</Span>
          </Button>
          {/* <RightContainer>
            <input type="text" placeholder="Serach Here" />
            <ImageContainer>
              <img src={Search} alt="Search" />
            </ImageContainer>
          </RightContainer> */}
        </TotalDiv>
      </Container>
    </Wrapper>
  );
};

export default Products;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 20px;
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const TopContainer = styled.div`
  width: 30%;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const Heading = styled.p`
  font-size: 18px;
`;
const TotalDiv = styled.div`
  display: flex;
  gap: 20px;
  @media all and (max-width: 768px) {
    display: flex;
    gap: 9px;
    width: 100%;
  }
  @media all and (max-width: 425px) {
    flex-wrap: wrap;
  }
`;
const BottomContainer = styled.div`
  width: 100%;
`;
const Button = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 5px;
  background: var(--button-color);
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  width: 150px;
  font-size: 12px;

  cursor: pointer;
  @media all and (max-width: 640px) {
    font-size: 10px;
    width: 120px;
  }
  @media all and (max-width: 425px) {
    width: 100%;
  }
  &.product-category {
    width: 200px;
    @media all and (max-width: 640px) {
      width: 120px;
    }
    @media all and (max-width: 425px) {
      width: 180px;
    }
    @media all and (max-width: 425px) {
      width: 100%;
    }
    /* font-size: 12px; */
  }
`;
const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  padding: 10px 0px;
  img {
    display: block;
    width: 20px;
  }
`;
const Span = styled.span``;
const ImageContainer = styled.div`
  width: 20px;
  img {
    width: 100%;
    display: block;
    filter: invert(100%);
  }
  @media all and (max-width: 425px) {
    position: absolute;
    right: 6px;
  }
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--button-color);
  border-radius: 5px;

  gap: 10px;
  input {
    padding: 6px;
    color: #000;
    font-weight: 600;
    border: none;
    width: 100%;
  }
  @media all and (max-width: 425px) {
    position: relative;
    width: 100%;
  }
`;
