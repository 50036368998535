import React from "react";
import styled from "styled-components";

const FeaturedCollectionNav = () => {
  return (
    <Cover>
      <ItemContainer>
        <Item type="name">Title</Item>
        <Item type="name">File</Item>
        <Item type="url">url</Item>
        <Item type="name">Actions</Item>
      </ItemContainer>
    </Cover>
  );
};

export default FeaturedCollectionNav;

const ItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;
const Cover = styled.div`
  @media all and (max-width: 980px) {
    min-width: 1180px;
  }
`;
const Item = styled.li`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  width: ${({ type }) =>
    type == "url" ? "20%" : type == "name" ? "12%" : "15%"};
`;
