import React from "react";
import styled from "styled-components";
import UserAnalitics from "./UserAnalitics";

const Analitics = () => {
  return (
    <Cover>
      <UserAnalitics />
    </Cover>
  );
};

export default Analitics;

const Cover = styled.div`
  width: 100%;
  height: 90vh;
  z-index: 0;
  overflow-y: scroll;
  overflow-x: scroll;
`;
