import React, { useEffect, useState } from "react";
import { OfferTopConatiner } from "../reuse/OfferTopConatiner";
import ProductCategoryOfferList from "./ProductCategoryOfferList";
import Cover from "../../home/reuse/Cover";
import AddProductOfferModal from "../product-offer/modal/AddProductOfferModal";
import EditProductOfferModal from "../product-offer/modal/EditProductOfferModal";
import { useBearStore, useOfferIdStore } from "../../../context/Store";
import { productConfig } from "../../../../axiosConfig";
import styled from "styled-components";

const ProductCategoryOfferScreen = () => {
  // store values
  const userData = useBearStore((state) => state.userData);
  const setOfferId = useOfferIdStore((state) => state.setOfferId);
  const Id = useOfferIdStore((state) => state.offerId);

  const [productCategoryOffer, setProductCategoryOffer] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [indivitualOffer, setIndivitualOffer] = useState([]);

  const [categoryOfferModal, setCategoryOfferModal] = useState(false);
  const [editCategoryOfferModal, setEditCategoryOfferModal] = useState(false);
  const [categoryId, setCategoryId] = useState();

  // Modal form states
  const [offerName, setOfferName] = useState();
  const [productCategory, setProductCategory] = useState();
  const [deductionPercentage, setDeductionPercentage] = useState();
  const [startDate, SetStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const path = window.location.pathname;

  const fetchProductCategoryOffer = () => {
    productConfig
      .get("product-category-offers/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setProductCategoryOffer(app_data.data);
        }
      });
  };

  const fetchProductCategory = () => {
    productConfig
      .get("get-product-categories/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data, data } = response.data;
        if (app_data.StatusCode === 6000) {
          setCategoryData(app_data.data);
        }
      });
  };

  const AddProductCategoryOffer = () => {
    const formData = new FormData();
    formData.append("offer_name", offerName);
    formData.append("product_category", productCategory);
    formData.append("deduction_percentage", deductionPercentage);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);

    productConfig
      .post("add-product-category-offer/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setCategoryOfferModal(false);
          fetchProductCategoryOffer();
        }
      });
  };

  const deleteProductCategoryOffer = () => {
    productConfig
      .post(
        `delete-product-offer/${Id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        }
      )
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          fetchProductCategoryOffer();
        }
      });
  };

  useEffect(() => {
    fetchProductCategoryOffer();
    fetchProductCategory();
  }, []);

  const fetchIndivitualProductCategory = () => {
    Id &&
      productConfig
        .get(`indivitual-offer/${Id}`, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            setIndivitualOffer(app_data.data);
            setOfferName(app_data.data.offer_name);
            setProductCategory(app_data.data.product_category);
            setDeductionPercentage(app_data.data.deduction_percentage);
            SetStartDate(app_data.data.start_date);
            setEndDate(app_data.data.end_date);
          }
        });
  };

  const EditProductCategoryOffer = () => {
    const formData = new FormData();

    if (indivitualOffer.offer_name !== offerName) {
      formData.append("offer_name", offerName);
    }

    if (indivitualOffer.product_category !== productCategory) {
      formData.append("product_category_id", productCategory);
    }

    if (indivitualOffer.deduction_percentage !== deductionPercentage) {
      formData.append("deduction_percentage", deductionPercentage);
    }

    if (indivitualOffer.start_date !== startDate) {
      formData.append("start_date", startDate);
    }

    if (indivitualOffer.end_date !== endDate) {
      formData.append("end_date", endDate);
    }

    productConfig
      .post(`edit-product-category-offer/${Id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setEditCategoryOfferModal(false);
          fetchProductCategoryOffer();
        }
      });
  };

  useEffect(() => {
    Id === Id && fetchIndivitualProductCategory();
  }, [editCategoryOfferModal]);

  return (
    <>
      <Cover>
        <MainTitle>Product Category Offer</MainTitle>
        {path === "/product-category-offer/" && (
          <OfferTopConatiner
            buttonClickFunction={setCategoryOfferModal}
            categoryOfferModal={categoryOfferModal}
          />
        )}
        <ProductCategoryOfferList
          editButtonClickFunction={setEditCategoryOfferModal}
          productCategoryOffer={productCategoryOffer}
          setId={setOfferId}
          deleteProductCategoryOffer={deleteProductCategoryOffer}
        />
        <AddProductOfferModal
          activeModal={categoryOfferModal}
          onclickFunction={setCategoryOfferModal}
          labelValue="Product Category"
          setOfferName={setOfferName}
          setObjectType={setProductCategory}
          setDeductionPercentage={setDeductionPercentage}
          SetStartDate={SetStartDate}
          setEndDate={setEndDate}
          data={categoryData}
          handleSubmit={AddProductCategoryOffer}
        />
        <EditProductOfferModal
          editActiveModal={editCategoryOfferModal}
          labelValue="Product Category"
          onclickFunction={setEditCategoryOfferModal}
          data={categoryData}
          offerName={offerName}
          objectType={productCategory}
          deductionPercentage={deductionPercentage}
          startDate={startDate}
          endDate={endDate}
          setOfferName={setOfferName}
          setObjectType={setProductCategory}
          setDeductionPercentage={setDeductionPercentage}
          SetStartDate={SetStartDate}
          setEndDate={setEndDate}
          handleSubmit={EditProductCategoryOffer}
        />
      </Cover>
    </>
  );
};

export default ProductCategoryOfferScreen;

const MainTitle = styled.h2`
  text-align: center;
  margin-top: 15px;
  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;
