import React from "react";
import styled from "styled-components";

const ProductDetails = () => {
  return (
    <Cover>
      <ItemContainer>
        <Item type="slno">Sl No</Item>
        <Item type="name">Name</Item>
        <Item type="name">Photo</Item>
        <Item type="name">Photo type</Item>
        <Item type="slno">Total Count</Item>
        {/* <Item type="slno"></Item> */}
      </ItemContainer>
    </Cover>
  );
};

export default ProductDetails;

const Cover = styled.div`
  min-width: 1180px;
`;
const ItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;
const Item = styled.li`
  font-size: 14px;
  font-weight: 700;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
`;
