import React from "react";
import styled from "styled-components";

const BannerDetails = () => {
  return (
    <Cover>
      <ItemContainer>
        <Item type="slno">Sl No</Item>
        <Item type="name">Banner Image</Item>
        <Item type="name">url</Item>
        <Item type="name">action</Item>
      </ItemContainer>
    </Cover>
  );
};

export default BannerDetails;

const ItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;
const Cover = styled.div`
  min-width: 1180px;
`;
const Item = styled.li`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
`;
