import React, { useEffect } from "react";
import styled from "styled-components";

const Users = () => {
  useEffect(() => {});
  return (
    <Cover>
      <ItemContainer>
        <Item type="slno">Sl&nbsp;No</Item>
        <Item type="name">Username</Item>
        <Item type="name">Name</Item>
        <Item type="name">Phone</Item>
        <Item type="name">Country</Item>
        <Item type="name">House&nbsp;Name</Item>
        <Item type="name">Street&nbsp;Name</Item>
        <Item type="name">Pincode</Item>
        <Item type="name">District</Item>
        <Item type="name">State</Item>
      </ItemContainer>
    </Cover>
  );
};

export default Users;

const Cover = styled.div`
  min-width: 1180px;
`;
const ItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #000;
`;
const Item = styled.li`
  font-size: 14px;
  font-weight: 700;
  font-size: 12px;
  width: ${({ type }) => (type == "slno" ? "8%" : type == "name" ? "30%" : "")};

  @media all and (max-width: 1280px) {
    font-size: 12px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }

  @media all and (max-width: 425px) {
    font-size: 12px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;
