import React, { useState } from "react";
import styled from "styled-components";

import Close from "../../../assets/images/product/close.svg";
import { webConfig } from "../../../../axiosConfig";
import { useBearStore } from "../../../context/Store";

const AddBannerModal = ({ bannerModal, setBannerModal, fetchBanners }) => {
  const userData = useBearStore((state) => state.userData);
  const [bannerImage, setBannerImage] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [mobileBannerImage, setMobileBannerImage] = useState("");
  const [mobileBannerImageUrl, setMobileBannerImageUrl] = useState("");

  const [url, setUrl] = useState("");

  const handleBanner = (event) => {
    setBannerImage(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setBannerImageUrl(imageContent);
    };
  };

  const handleMobileBanner = (event) => {
    setMobileBannerImage(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setMobileBannerImageUrl(imageContent);
    };
  };

  const addBanner = () => {
    const formData = new FormData();
    formData.append("image", bannerImage);
    formData.append("mob_image", mobileBannerImage);
    formData.append("url", url);
    webConfig
      .post("add-banner/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setBannerModal(false);
          fetchBanners();
        }
      });
  };

  return (
    <div>
      <Cover>
        <Container className={bannerModal && "active"}>
          <Overlay onClick={() => setBannerModal(false)}></Overlay>
          <Modal>
            <Wrapper>
              <TopContainer>
                <CloseDiv onClick={() => setBannerModal(false)}>
                  <img src={Close} alt="Close" />
                </CloseDiv>
              </TopContainer>
              <ContentContainer>
                <Form>
                  <BannerDiv>
                    <Label>Banner : </Label>
                    <input type="file" onChange={handleBanner} required />
                  </BannerDiv>
                  <WarningDiv>
                    <Warning>
                      The Banner is 1920 x 800 in both desktop and tab views.
                    </Warning>
                  </WarningDiv>
                  <BannerDiv>
                    <Label>Mobile Banner : </Label>
                    <input type="file" onChange={handleMobileBanner} required />
                    <WarningDiv>
                      <Warning>
                        The Banner's dimensions in mobile view are 430x470.
                      </Warning>
                    </WarningDiv>
                  </BannerDiv>
                  <UrlDiv>
                    <Label> Url : </Label>
                    <input
                      type="text"
                      placeholder="Enter the url of the banner"
                      onChange={(e) => setUrl(e.target.value)}
                      required
                    />
                  </UrlDiv>
                </Form>
              </ContentContainer>
              <ButtonContainer>
                <Button
                  onClick={() => {
                    addBanner();
                  }}
                >
                  Submit
                </Button>
              </ButtonContainer>
            </Wrapper>
          </Modal>
        </Container>
      </Cover>
    </div>
  );
};

export default AddBannerModal;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  padding-bottom: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
`;
const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;
const CloseDiv = styled.div`
  width: 3%;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
`;
const ContentContainer = styled.div`
  padding: 10px;
`;
const Form = styled.form``;
const BannerDiv = styled.div`
  width: 100%;
  input {
    width: 80%;
    margin-top: 10px;
    padding: 5px;
  }
`;
const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
`;
const UrlDiv = styled.div`
  width: 100%;
  margin-top: 30px;
  input {
    width: 80%;
    margin-top: 10px;
    padding: 5px;
    margin-left: 45px;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 30px;
  width: 100%;
`;
const Button = styled.div`
  background: var(--button-color);
  width: 30%;
  text-align: center;
  padding: 5px;
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
`;
const WarningDiv = styled.div``;
const Warning = styled.span`
  font-size: 10px;
  color: red;
  font-weight: 600;
`;
