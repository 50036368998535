import React from "react";
import styled from "styled-components";
import UserNavList from "./UserNavList";
import UserNavData from "./UserNavData";

const UserAnalitics = () => {
  return (
    <div>
      <Wrapper>
        <UserNavList />
        <UserNavData />
      </Wrapper>
    </div>
  );
};

export default UserAnalitics;

const MainTitle = styled.h2`
  margin-top: 10px;
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
