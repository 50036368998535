import React, { useEffect, useState } from "react";
import Empty from "../../assets/images/product/image.png";
import styled from "styled-components";
import FeaturedCollectionNav from "./FeaturedCollectionNav";
import FeaturedCollectionFormModal from "./modal/FeaturedCollectionFormModal";
import { webConfig } from "../../../axiosConfig";
import { useBearStore, useFeaturedIdStore } from "../../context/Store";
import DeleteModal from "../blogs/modal/DeleteModal";

const FeaturedCollectionList = ({
  setEditModal,
  editModal,
  title,
  url,
  video,
  videoUrl,
  deleteModal,
  setTitle,
  setUrl,
  setVideo,
  setVideoUrl,
  setDeleteModal,
  featuredCollectionData,
  editFeaturedCollectionVideo,
  fetchFeaturedCollections,
}) => {
  const userData = useBearStore((state) => state.userData);
  const setFeaturedId = useFeaturedIdStore((state) => state.setFeaturedId);
  const FeaturedId = useFeaturedIdStore((state) => state.FeaturedId);

  const deleteFeaturedCollection = () => {
    webConfig
      .post(
        `delete-featured-collection/${FeaturedId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        }
      )
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          fetchFeaturedCollections();
          setDeleteModal(false);
        }
      });
  };

  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <MainTitle>Featured Collection</MainTitle>
        </TitleContainer>
        <FeaturedCollectionNav />
        {featuredCollectionData?.map((item) => (
          <ItemContainer>
            <ItemList type="name">{item.title}</ItemList>
            <ItemList type="name">
              <ImageDiv>
                <video
                  id="featured-video"
                  width="120"
                  height="100"
                  muted
                  loop
                  autoPlay
                  playsInline
                >
                  <source src={item.video} type="video/mp4" />
                  <source src={item.video} type="video/ogg" />
                </video>
              </ImageDiv>
            </ItemList>
            <ItemList type="url">{item.url}</ItemList>
            <ItemList type="name" className="status">
              <EditContainer
                onClick={() => {
                  setFeaturedId(item.id);
                  setEditModal(true);
                }}
              >
                <i className="ri-edit-box-fill"></i>
              </EditContainer>
              <DeleteContainer
                onClick={() => {
                  setFeaturedId(item.id);
                  setDeleteModal(true);
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </DeleteContainer>
            </ItemList>
          </ItemContainer>
        ))}

        <FeaturedCollectionFormModal
          modal={editModal}
          setModal={setEditModal}
          type="edit_modal"
          title={title}
          url={url}
          video={video}
          videoUrl={videoUrl}
          setTitle={setTitle}
          setUrl={setUrl}
          setVideo={setVideo}
          setVideoUrl={setVideoUrl}
          handleSubmit={editFeaturedCollectionVideo}
        />

        <DeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          handleDeleteSubmit={deleteFeaturedCollection}
        />
      </Container>
    </Wrapper>
  );
};

export default FeaturedCollectionList;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Container = styled.div`
  max-width: 100%;
  overflow: scroll;
`;
const TitleContainer = styled.div``;
const MainTitle = styled.h2`
  margin: 30px 50px;
  @media all and (max-width: 980px) {
    font-size: 15px;
    margin: 30px 10px;
  }
`;
const ItemContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
  padding-top: 30px;
  height: 50px;
  @media all and (max-width: 980px) {
    min-width: 1180px;
  }
`;
const ItemList = styled.li`
  text-align: center;
  width: ${({ type }) =>
    type == "url" ? "20%" : type == "name" ? "12%" : "15%"};
  font-size: 14px;
  margin-left: 30px;
  img {
    display: block;
    width: 100%;
  }
  &.status {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  &.slno {
    display: flex;
    justify-content: flex-end;
  }
`;
const EditContainer = styled.div`
  cursor: pointer;
`;
const DeleteContainer = styled.div`
  cursor: pointer;

  i {
    color: red;
  }
`;
const ImageDiv = styled.div`
  width: 50%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
`;
