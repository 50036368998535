import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bar from "../assets/images/header/three.svg";
import dp from "../assets/images/header/face1.jpg";
import styled from "styled-components";
import MainLogo from "../assets/images/header/svojas_logo_final.png";
import { useAppStore, useBearStore } from "../context/Store";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";

const Header = ({ active, setActive }) => {
  const userData = useBearStore((state) => state.userData);
  const sidebar = useAppStore((state) => state.isSideBar);
  const handleSidebar = useAppStore((state) => state.handleSidebar);
  return (
    <>
      <Cover>
        <MainContainer>
          <Wrapper>
            <HeaderContainer>
              <LeftContainer>
                <LogoContainer to="/">
                  <Logo src={MainLogo} alt="svojas" />
                </LogoContainer>
                {/* <BarIconContainert onClick={() => handleSidebar()}>
                  <BarIcon src={bar} alt="bar-icon" />
                </BarIconContainert> */}
              </LeftContainer>
              <ProfileContainer>
                <ProfilePictureContainer>
                  <ProfilePic src={dp} alt="profile-pic" />
                </ProfilePictureContainer>
                <ProfileNameContainer>
                  <ProfileName>{userData.username}</ProfileName>
                  <DropArrowContainer>
                    <FontAwesomeIcon
                      icon="fa-solid fa-caret-down"
                      style={{ color: "#b8b8b8" }}
                    />
                  </DropArrowContainer>
                </ProfileNameContainer>
              </ProfileContainer>
            </HeaderContainer>
          </Wrapper>
        </MainContainer>
      </Cover>
      <Outlet />
    </>
  );
};

export default Header;
const Cover = styled.div`
  width: 100%;
`;
const MainContainer = styled.div`
  width: 100%;
`;
const Wrapper = styled.div``;
const LogoContainer = styled(Link)`
  width: 20%;
  @media all and (max-width: 980px) {
    width: 25%;
  }
  @media all and (max-width: 768px) {
    width: 30%;
  }
  @media all and (max-width: 640px) {
    width: 35%;
  }
  @media all and (max-width: 480px) {
    width: 50%;
  }
  @media all and (max-width: 360px) {
    width: 55%;
  }
`;
const Logo = styled.img`
  width: 100%;
  display: block;
`;
const LeftContainer = styled.div`
  width: 40%;
  height: 30px;
  display: flex;
  align-items: center;
`;
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;
  height: 50px;
`;
const BarIconContainert = styled.div`
  width: 4%;
  margin-left: 10px;
  cursor: pointer;
  @media all and (max-width: 480px) {
    width: 7%;
  }
  @media all and (max-width: 360px) {
    width: 10%;
  }
`;
const BarIcon = styled.img`
  display: block;
  width: 100%;
`;
const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  @media all and (max-width: 425px) {
    width: 34%;
  }
`;
const ProfilePictureContainer = styled.div`
  width: 20%;
`;
const ProfilePic = styled.img`
  width: 100%;
  display: block;
  border-radius: 50%;
`;
const GreenIconContainer = styled.div``;
const ProfileNameContainer = styled.div``;
const ProfileName = styled.h3`
  margin-left: 10px;
  @media all and (max-width: 320px) {
    font-size: 15px;
  }
`;
const DropArrowContainer = styled.div``;
