import React, { useState } from 'react'
import styled from 'styled-components'
import '../../assets/css/style.css'
import bg from '../../assets/images/banner.jpg'
import { useBearStore } from '../../context/Store'
import { accountsConfig } from '../../../axiosConfig'

const Login = () => {
    const userData = useBearStore((state) => state.userData)
    const updateUserData = useBearStore((state) => state.updateUserData)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const handleLogin = () => {
        accountsConfig.post('/admin-dashboard-login/',{
            username:username,
            password:password
        }).then(function(response){
            const { app_data, data } = response.data;
            if (app_data.StatusCode === 6000){
                updateUserData(username,true,app_data.data.access_token)
            }
            else{
                setErrorMessage(app_data.data.message)
            }
        })
    }



  return (
    <div>
        <MainContainer>
            <LeftContainer>
                <Content>
                    <MainTitle>Login to your Dashboard</MainTitle>
                    <CredentialsContainer>
                        <Form>
                            <Username type="text" onChange={(e) => setUsername(e.target.value)} placeholder="Enter your Username" />
                            <Password type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Enter your Password" />
                            {errorMessage && <ErrorMesage>{errorMessage}</ErrorMesage> }
                            <LoginButton onClick={()=>{handleLogin()}}>LogIn</LoginButton>
                        </Form>
                    </CredentialsContainer>
                </Content>
            </LeftContainer>
            <RightContainer></RightContainer>
        </MainContainer>
    </div>
  )
}

export default Login

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: 980px){
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;
const LeftContainer = styled.div`
    width: 40%;
    height: 100vh;
    background-color: #ebebed;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 980px){
        width: 100%;
        height: 50vh;
    }
    @media all and (max-width: 640px){
        height: 50vh;
    }
`;
const Content = styled.div`
    width: 50%;
    @media all and (max-width: 360px){
        font-size: 15px;
    }
`;
const MainTitle = styled.h2`
    color: #6e614e;

    @media all and (max-width: 1280px){
        font-size: 15px;
    }
`;
const CredentialsContainer = styled.div``;
const Form = styled.form`
    display: flex;
    flex-direction: column;
`;
const Username = styled.input`
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    border: none;
`;
const Password = styled.input`
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    border: none;

`;
const LoginButton = styled.div`
    width: 100%;
    margin-top: 20px;
    background: rgb(242,109,43);
    background: linear-gradient(90deg, rgba(242,109,43,1) 28%, rgba(165,85,147,1) 76%);
    padding: 10px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
`;
const RightContainer = styled.div`
    width: 60%;
    height: 100vh;
    background: url(${bg}) no-repeat;
    background-size: cover;

    @media all and (max-width: 980px){
        width: 90%;

    }
    @media all and (max-width: 981px){
        width: 100%;

    }
    @media all and (max-width: 640px){
        height: 50vh;
    }
`;

const ErrorMesage = styled.p`
    color: red;
    font-size: 10px;
    font-weight: 700;
    margin-top: 10px;
`;
