import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const OfferModules = ({ isMenu, setMenu }) => {
  return (
    <SubMainContainer>
      <MenuListContainer>
        <SubMenu
          onClick={() => setMenu("ProductOffer")}
          className={isMenu === "ProductOffer" ? "active" : ""}
          to="product-offer/"
        >
          <IconDiv>
            <i class="ri-red-packet-fill"></i>
          </IconDiv>
          <IconTitle>Product Offer</IconTitle>
        </SubMenu>
        <SubMenu
          onClick={() => setMenu("CategoryOffer")}
          className={isMenu === "CategoryOffer" ? "active" : ""}
          to="product-category-offer/"
        >
          <IconDiv>
            <i class="ri-layout-grid-fill"></i>
          </IconDiv>
          <IconTitle>Category Offer</IconTitle>
        </SubMenu>
        <SubMenu
          onClick={() => setMenu("CartOffer")}
          className={isMenu === "CartOffer" ? "active" : ""}
          to="cart-offer/"
        >
          <IconDiv>
            <i class="ri-luggage-cart-line"></i>
          </IconDiv>
          <IconTitle>Cart Offer</IconTitle>
        </SubMenu>
      </MenuListContainer>
    </SubMainContainer>
  );
};

export default OfferModules;

const SubMainContainer = styled.div``;
const MenuListContainer = styled.ul`
  display: flex;
  flex-direction: column;
`;
const SubMenu = styled(Link)`
  margin-top: 20px;
  color: #000;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  &.active {
    color: blue;
    font-weight: 700;
  }
  @media all and (max-width: 768px) {
    font-size: 15px;
  }
  @media all and (max-width: 375px) {
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 10px;
  }
`;
const IconDiv = styled.div``;
const IconTitle = styled.h6`
  font-size: 12px;
  @media all and (max-width: 425px) {
    display: none;
  }
`;
