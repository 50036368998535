import React from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import NoData from "../users/NoData";
import { Link } from "react-router-dom";
import Loader from "../../helpers/Loader";
import OrderDetails from "./OrderDetails";

const OrderList = ({
  order,
  pagination,
  setPagination,
  searchParams,
  setSearchParams,
  setCurrentPage,
  currentPage,
}) => {
  const currentParams = Object.fromEntries([...searchParams]);
  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage + 1);
    setSearchParams({
      ...currentParams,
      page: selectedPage + 1,
    });
    console.log(selectedPage, "selectedPage");
  };

  return (
    <Wrapper>
      <Conatiner>
        <OrderDetails />
        {order?.length > 0 ? (
          order.map((item, index) => (
            <ItemContainer
              key={item.id}
              to={`${item.id}`}
              className={!item.is_read ? "active" : ""}
            >
              <ItemList type="item">
                {pagination?.first_item <= pagination?.last_item && (
                  <ItemList
                    type="item"
                    className={!item.is_read ? "active" : ""}
                  >
                    {pagination?.first_item + index}
                  </ItemList>
                )}
              </ItemList>
              <ItemList type="item" className={!item.is_read ? "active" : ""}>
                {item.order_id}
              </ItemList>
              <ItemList type="item" className={!item.is_read ? "active" : ""}>
                {item.created_at}
              </ItemList>
              <ItemList type="item" className={!item.is_read ? "active" : ""}>
                {item.profile}
              </ItemList>
              <ItemList type="item" className={!item.is_read ? "active" : ""}>
                {item.total_amount_to_be_paid}
              </ItemList>
              <ItemList type="item" className={!item.is_read ? "active" : ""}>
                {item.is_confirm ? "True" : "False"}
              </ItemList>
              <ItemList type="item" className={!item.is_read ? "active" : ""}>
                {item.razor_pay_confirmation}
              </ItemList>
              <ItemList type="item" className={!item.is_read ? "active" : ""}>
                {item.order_status === "completed"
                  ? "Payment Received"
                  : item.order_status === "pending"
                  ? "Payment Pending"
                  : item.order_status === "failed"
                  ? "Payment Failed"
                  : "Unknown Status"}
                {!item.is_read && (
                  <IconDiv>
                    <i class="ri-checkbox-blank-circle-fill"></i>
                  </IconDiv>
                )}
              </ItemList>
            </ItemContainer>
          ))
        ) : (
          <Loader />
        )}
        <PaginationContainer>
          <PaginationText>
            Showing Orders {pagination?.first_item} - {pagination?.last_item} of{" "}
            {pagination?.total_items}
          </PaginationText>
          <ReactPaginate
            previousLabel={"< Prev"}
            nextLabel={"Next >"}
            pageCount={pagination?.total_pages}
            onPageChange={handlePageClick}
            forcePage={currentPage - 1}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </PaginationContainer>
      </Conatiner>
    </Wrapper>
  );
};

export default OrderList;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Conatiner = styled.div`
  max-width: 100%;
  overflow: scroll;
`;
const ItemContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  height: 50px;
  &.active {
    font-weight: 700;
  }
  @media all and (max-width: 1080px) {
    min-width: 1180px;
  }
`;
const ItemList = styled.li`
  width: ${({ type }) =>
    type == "check" ? "5%" : type == "item" ? "12%" : "15%"};
  font-size: 12px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  @media all and (max-width: 1080px) {
    width: ${({ type }) =>
      type == "check" ? "5%" : type == "item" ? "19%" : "15%"};
  }
  &.active {
    font-weight: 800;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0 15px;
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
const IconDiv = styled.div`
  color: #90ee90;
`;
