import React from "react";
import styled from "styled-components";
import Button from "../../../general/Button";
import EmptyImage from "../../../assets/images/product/image.png";

const ProcuringTable = ({
  status,
  setName,
  setDate,
  setDescription,
  name,
  date,
  description,
  procuringImage,
  quantity,
  setQuantity,
  setProcuringImage,
  setProcuringImageUrl,
  procuringImageUrl,
  addProcuring,
  procuring,
  editProcure,
  type,
}) => {
  const handleImage = (event) => {
    setProcuringImage(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setProcuringImageUrl(imageContent);
    };
  };

  return (
    <MainContainer>
      <Wrapper>
        <Form>
          <Title>{status}</Title>
          <FormContainer>
            <TextDiv>
              <Label>Grain Name : </Label>
            </TextDiv>
            <TextInputDiv>
              <input
                type="text"
                placeholder="Enter grain name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </TextInputDiv>
          </FormContainer>
          <FormContainer>
            <TextDiv>
              <Label>Date : </Label>
            </TextDiv>
            <TextInputDiv>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </TextInputDiv>
          </FormContainer>
          <FormContainer>
            <TextDiv>
              <Label>Quantity : </Label>
            </TextDiv>
            <TextInputDiv>
              <input
                type="text"
                placeholder="Quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </TextInputDiv>
          </FormContainer>
          <FormContainer>
            <TextDiv>
              <Label>Description : </Label>
            </TextDiv>
            <TextInputDiv>
              <textarea
                id="w3review"
                name="w3review"
                rows="4"
                cols="50"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </TextInputDiv>
          </FormContainer>
          <FormContainer>
            <TextDiv>
              <Label>Procuring Image : </Label>
            </TextDiv>
            <TextInputDiv className="image">
              {type == "edit_procuring" ? (
                <PhotoDiv>
                  <img
                    src={procuringImageUrl ? procuringImageUrl : procuring}
                    alt="Image"
                  />
                </PhotoDiv>
              ) : (
                <PhotoDiv>
                  <img
                    src={procuringImageUrl ? procuringImageUrl : EmptyImage}
                    alt="Image"
                  />
                </PhotoDiv>
              )}
              <ImageInput>
                <input
                  type="file"
                  className="image-container"
                  onChange={handleImage}
                />
              </ImageInput>
            </TextInputDiv>
          </FormContainer>
        </Form>
        {type == "edit_procuring" ? (
          <ButtonConatiner>
            <Button handleSubmit={editProcure} />
          </ButtonConatiner>
        ) : (
          <ButtonConatiner>
            <Button handleSubmit={addProcuring} />
          </ButtonConatiner>
        )}
      </Wrapper>
    </MainContainer>
  );
};

export default ProcuringTable;

const MainContainer = styled.div`
  width: 85%;
  margin: 0 auto;
  margin-top: 50px;
  @media all and (max-width: 768px) {
    min-width: 980px;
  }
  @media all and (max-width: 375px) {
    min-width: 768px;
  }
`;
const Wrapper = styled.div``;
const Form = styled.form``;
const Title = styled.h1`
  margin: 10px;
`;
const FormContainer = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
`;
const TextDiv = styled.div`
  width: 20%;
`;
const Label = styled.label``;
const TextInputDiv = styled.div`
  width: 70%;
  textarea {
    margin-left: 10px;
  }
  input {
    height: 30px;
    padding: 3px;
    margin-left: 10px;
    width: 50%;
    border-radius: 3px;
    border: 1px solid grey;
  }
  input.image-container {
    border: None;
  }
  &.image {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;
const ButtonConatiner = styled.div`
  width: 30%;
  margin: 0 auto;
  margin-top: 30px;
`;
const PhotoDiv = styled.div`
  width: 200px;
  margin-right: 20px;
  img {
    width: 100%;
    display: block;
  }
`;
const ImageInput = styled.div``;
const ErrorMessage = styled.span`
  color: red;
  font-size: 10px;
  font-weight: 600;
`;
