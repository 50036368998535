import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import ProductCategoryList from "./ProductCategoryList";
import { productConfig } from "../../../axiosConfig";
import { useBearStore, useCategoryIdStore } from "../../context/Store";
import { CategoryEditModal } from "./modal/CategoryEditModal";

const ProductCategoryScreen = () => {
  const userData = useBearStore((state) => state.userData);
  const categoryId = useCategoryIdStore((state) => state.categoryId);

  const [isCategory, setCategory] = useState([]);
  const [productCategoryData, setProductCategoryData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [categoryImageUrl, setCategoryImageUrl] = useState("");

  const fetchProductCategory = () => {
    productConfig
      .get("get-product-categories/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data, data } = response.data;
        if (app_data.StatusCode === 6000) {
          setCategory(app_data.data);
        }
      });
  };

  const fetchSingleProductCategory = () => {
    categoryId &&
      productConfig
        .get(`get-product-category/${categoryId}/`, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            setProductCategoryData(app_data.data);
            setCategoryName(app_data.data.category_name);
            setOrderId(app_data.data.order_id);
            setCategoryImage(app_data.data.image);
          }
        });
  };

  const editProductCategory = () => {
    const formData = new FormData();

    if (productCategoryData.category_name !== categoryName) {
      formData.append("category_name", categoryName);
    }

    if (productCategoryData.order_id !== orderId) {
      formData.append("order_id", orderId);
    }

    if (productCategoryData.image !== categoryImage) {
      formData.append("image", categoryImage);
    }

    productConfig
      .post(`edit-product-categories/${categoryId}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setCategoryName("");
          setOrderId("");
          setCategoryImage("");
          setCategoryImageUrl("");
          fetchProductCategory();
          setEditModal(false);
        }
      });
  };

  useEffect(() => {
    fetchProductCategory();
  }, []);

  return (
    <Cover>
      <ProductCategoryList
        isCategory={isCategory}
        setEditModal={setEditModal}
        fetchSingleProductCategory={fetchSingleProductCategory}
        categoryId={categoryId}
      />
      <CategoryEditModal
        setEditModal={setEditModal}
        editModal={editModal}
        categoryName={categoryName}
        setCategoryName={setCategoryName}
        orderId={orderId}
        setOrderId={setOrderId}
        categoryImage={categoryImage}
        setCategoryImage={setCategoryImage}
        categoryImageUrl={categoryImageUrl}
        setCategoryImageUrl={setCategoryImageUrl}
        editProductCategory={editProductCategory}
      />
    </Cover>
  );
};

export default ProductCategoryScreen;
