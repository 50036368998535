import React from "react";
import Cover from "../home/reuse/Cover";
import UserNavList from "../analytics/UserNavList";
import ProductNavData from "./ProductNavData";
import styled from "styled-components";
import Card from "../home/reuse/Card";

const ProductAnalytics = () => {
  return (
    <Cover>
      <Wrapper>
        <CardContainer>
          <Card />
        </CardContainer>
        <UserNavList product="Product" />
        <ProductNavData />
      </Wrapper>
    </Cover>
  );
};

export default ProductAnalytics;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const CardContainer = styled.div`
  margin-bottom: 20px;
`;
