import React, { useState } from "react";
import empty from "../../assets/images/product/image.png";
import styled from "styled-components";
import BlogNav from "./BlogNav";
import { useBearStore, useBlogIdStore } from "../../context/Store";
import DeleteModal from "./modal/DeleteModal";
import { blogConfig } from "../../../axiosConfig";

const BlogList = ({
  setEditBlogModal,
  fetchBlogData,
  setPagination,
  blogData,
  pagination,
}) => {
  const setBlogId = useBlogIdStore((state) => state.setBlogId);
  const BlogId = useBlogIdStore((state) => state.BlogId);
  const userData = useBearStore((state) => state.userData);
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteBanner = () => {
    BlogId &&
      blogConfig
        .post(
          `delete-blog/${BlogId}/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userData.accessToken}`,
            },
          }
        )
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            fetchBlogData();
            setDeleteModal(false);
          }
        });
  };

  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <MainTitle>Blogs</MainTitle>
        </TitleContainer>
        <BlogNav />
        {blogData?.map((item, index) => (
          <ItemContainer key={item.id}>
            <ItemList type="slno">
              {pagination?.first_item <= pagination?.last_item && (
                <ItemList type="slno">
                  {pagination?.first_item + index}
                </ItemList>
              )}
            </ItemList>
            <ItemList type="name">{item.title}</ItemList>
            <ItemList type="name">
              <ImageDiv>
                <img src={item.image} alt="blog-image" />
              </ImageDiv>
            </ItemList>
            <ItemList
              type="name"
              className="status"
              onClick={() => {
                setBlogId(item.id);
              }}
            >
              <EditContainer
                onClick={() => {
                  setEditBlogModal(true);
                }}
              >
                <i className="ri-edit-box-fill"></i>
              </EditContainer>
              <DeleteContainer
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </DeleteContainer>
            </ItemList>
          </ItemContainer>
        ))}
      </Container>
      <DeleteModal
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        handleDeleteSubmit={deleteBanner}
      />
    </Wrapper>
  );
};

export default BlogList;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Container = styled.div`
  max-width: 100%;
  overflow: scroll;
`;
const TitleContainer = styled.div``;
const MainTitle = styled.h2`
  margin: 30px 50px;
  @media all and (max-width: 980px) {
    font-size: 15px;
    margin: 30px 10px;
  }
`;
const ItemContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
  padding-top: 30px;
  height: 50px;
  @media all and (max-width: 980px) {
    min-width: 1180px;
  }
`;
const ItemList = styled.li`
  text-align: center;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
  font-size: 14px;
  margin-left: 30px;
  img {
    display: block;
    width: 100%;
  }
  &.status {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  &.slno {
    display: flex;
    justify-content: flex-end;
  }
`;
const EditContainer = styled.div`
  cursor: pointer;
`;
const DeleteContainer = styled.div`
  cursor: pointer;

  i {
    color: red;
  }
`;
const ImageDiv = styled.div`
  width: 50%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
`;
