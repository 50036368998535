import React, { useEffect } from "react";
import styled from "styled-components";
import { Route, Routes } from "react-router";
import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Dashboard from "./home/Dashboard";
import UsersScreen from "./users/UsersScreen";
import ProductScreen from "./products/ProductScreen";
import ProductView from "./products/ProductView";
import OrderView from "./order/OrderView";
import OrderScreen from "./order/OrderScreen";
import BannerScreen from "./banner/BannerScreen";
import UserView from "./users/UserView";
import EditTracebility from "./products/EditTracebility";
import Analitics from "./analytics/Analitics";
import ProductOfferScreen from "./offer/product-offer/ProductOfferScreen";
import ProductCategoryOfferScreen from "./offer/product-category-offer/ProductCategoryOfferScreen";
import CartOfferScreen from "./offer/cart-offer/CartOfferScreen";
import AddTracebility from "./products/AddTracebility";
import ProductAnalytics from "./product_analytics/ProductAnalytics";
import ProductAnalyticsData from "./product_analytics/ProductAnalyticsData";
import { useBearStore, useUnreadOrderCountStore } from "../context/Store";
import { paymentConfig } from "../../axiosConfig";
import ProductCategoryScreen from "./product_category/ProductCategoryScreen";
import BlogScreen from "./blogs/BlogScreen";
import FeaturedCollectionScreen from "./featured_collection/FeaturedCollectionScreen";
import OfferBanerScreen from "./offer_banner/OfferBanerScreen";
import TraceXScreen from "./traceX/TraceXScreen";

const MainContainer = () => {
  const unreadCount = useUnreadOrderCountStore((state) => state.unreadCount);
  const userData = useBearStore((state) => state.userData);
  const setUnreadCount = useUnreadOrderCountStore(
    (state) => state.setUnreadCount
  );

  useEffect(() => {
    paymentConfig
      .get(`orders/`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data, data } = response.data;
        if (app_data.StatusCode === 6000) {
          setUnreadCount(app_data.unread_orders);
        }
      });
  }, []);

  return (
    <>
      <Header />
      <Cover>
        <Sidebar unreadCount={unreadCount} />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="users">
            <Route path="" element={<UsersScreen />} />
            <Route path=":id" element={<UserView />} />
          </Route>
          <Route path="orders">
            <Route path="" element={<OrderScreen />} />
            <Route path=":id" element={<OrderView />} />
          </Route>
          <Route path="products">
            <Route path="" element={<ProductScreen />} />
            <Route path=":id?" element={<ProductView />} />
            <Route path=":id/tracebility" element={<EditTracebility />} />
            <Route path=":id/add-tracebility" element={<AddTracebility />} />
          </Route>
          <Route path="products-category">
            <Route path="" element={<ProductCategoryScreen />} />
          </Route>
          <Route path="tracex">
            <Route path="" element={<TraceXScreen />} />
          </Route>
          <Route path="/banner" element={<BannerScreen />} />
          <Route path="anlytics">
            <Route path="" element={<Analitics />} />
          </Route>
          <Route path="blogs">
            <Route path="" element={<BlogScreen />} />
          </Route>
          <Route path="product-anlytics">
            <Route path="" element={<ProductAnalytics />} />
            <Route path=":id?" element={<ProductAnalyticsData />} />
          </Route>
          <Route path="product-offer">
            <Route path="" element={<ProductOfferScreen />} />
          </Route>
          <Route path="product-category-offer">
            <Route path="" element={<ProductCategoryOfferScreen />} />
          </Route>
          <Route path="cart-offer">
            <Route path="" element={<CartOfferScreen />} />
          </Route>
          <Route path="featured-collection">
            <Route path="" element={<FeaturedCollectionScreen />} />
          </Route>
          <Route path="offer-banner">
            <Route path="" element={<OfferBanerScreen />} />
          </Route>
        </Routes>
      </Cover>
    </>
  );
};

export default MainContainer;
const Cover = styled.div`
  display: flex;
`;
