import axios from "axios";

// //Local Server
// export const accountsConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/v1/accounts/",
// });

// export const paymentConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/v1/payments/",
// });

// export const productConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/v1/products/",
// });

// export const webConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/v1/web/",
// });

// export const tracebilityConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/v1/tracebility/",
// });

// export const blogConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/v1/blogs/",
// });

// Demo Server
// export const accountsConfig = axios.create({
//   baseURL: "https://svojas-dev.accoladesweb.com/api/v1/accounts/",
// });

// export const paymentConfig = axios.create({
//   baseURL: "https://svojas-dev.accoladesweb.com/api/v1/payments/",
// });

// export const productConfig = axios.create({
//   baseURL: "https://svojas-dev.accoladesweb.com/api/v1/products/",
// });

// export const webConfig = axios.create({
//   baseURL: "https://svojas-dev.accoladesweb.com/api/v1/web/",
// });

// export const tracebilityConfig = axios.create({
//   baseURL: "https://svojas-dev.accoladesweb.com/api/v1/tracebility/",
// });

// export const blogConfig = axios.create({
//   baseURL: "https://svojas-dev.accoladesweb.com/api/v1/blogs/",
// });

// Live Server
export const accountsConfig = axios.create({
  baseURL: "https://www.svojasfarms.com/api/v1/accounts/",
});

export const paymentConfig = axios.create({
  baseURL: "https://www.svojasfarms.com/api/v1/payments/",
});

export const productConfig = axios.create({
  baseURL: "https://www.svojasfarms.com/api/v1/products/",
});

export const webConfig = axios.create({
  baseURL: "https://www.svojasfarms.com/api/v1/web/",
});

export const tracebilityConfig = axios.create({
  baseURL: "https://www.svojasfarms.com/api/v1/tracebility/",
});

export const blogConfig = axios.create({
  baseURL: "https://www.svojasfarms.com/api/v1/blogs/",
});
