import React, { useState } from "react";
import Users from "./Users";
import styled from "styled-components";
import Cover from "../home/reuse/Cover";
import { Link, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import NoData from "./NoData";
import "../../assets/css/style.css";
import Loader from "../../helpers/Loader";

const UsersList = ({
  users,
  setUsers,
  pagination,
  setPagination,
  searchParams,
  setSearchParams,
  setCurrentPage,
  currentPage,
}) => {
  const currentParams = Object.fromEntries([...searchParams]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage + 1);
    setSearchParams({
      ...currentParams,
      page: selectedPage + 1,
    });
  };

  return (
    <Wrapper>
      <Container>
        <Users />
        {users?.length > 0 ? (
          users.map((user, index) => (
            <ItemContainer key={user.id} to={`${user.id}`}>
              <Item type="slno">
                {pagination?.first_item <= pagination?.last_item && (
                  <Item type="slno"> {pagination?.first_item + index}</Item>
                )}
              </Item>
              <Item type="name">{user.username}</Item>
              <Item type="name">{user.name}</Item>
              <Item type="name">{user.phone}</Item>
              <Item type="name">{user.country}</Item>
              <Item type="name">{user.house_name}</Item>
              <Item type="name">{user.street_name}</Item>
              <Item type="name">{user.pincode}</Item>
              <Item type="name">{user.district}</Item>
              <Item type="name">{user.state}</Item>
            </ItemContainer>
          ))
        ) : (
          <Loader />
        )}
        <PaginationContainer>
          <PaginationText>
            Showing Users {pagination?.first_item} - {pagination?.last_item} of{" "}
            {pagination?.total_items}
          </PaginationText>
          <ReactPaginate
            previousLabel={"< Prev"}
            nextLabel={"Next >"}
            pageCount={pagination?.total_pages}
            onPageChange={handlePageClick}
            forcePage={currentPage - 1}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </PaginationContainer>
      </Container>
    </Wrapper>
  );
};

export default UsersList;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Container = styled.div`
  max-width: 100%;
  overflow: scroll;
`;
const ItemContainer = styled(Link)`
  display: flex;
  border-bottom: 1px solid #26272a;
  justify-content: space-between;
  padding: 20px;
  font-size: 12px;
  min-width: 1180px;
`;
const Item = styled.li`
  color: #000;
  font-weight: 500;
  font-size: 12px;
  width: ${({ type }) => (type == "slno" ? "8%" : type == "name" ? "30%" : "")};

  @media all and (max-width: 1280px) {
    font-size: 12px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }
  @media all and (max-width: 425px) {
    font-size: 12px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0 15px;
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  margin-left: 20px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
