import React from "react";
import styled from "styled-components";

const ProductCategoryOfferTable = () => {
  return (
    <Cover>
      <ItemContainer>
        <Item type="name">Offer name</Item>
        <Item type="name">Product Category</Item>
        <Item type="name">Offer Type</Item>
        <Item type="name">Percentage</Item>
        <Item type="name">Start date</Item>
        <Item type="name">End date</Item>
        <Item type="name">Action</Item>
      </ItemContainer>
    </Cover>
  );
};

export default ProductCategoryOfferTable;

const Cover = styled.div`
  /* min-width: 1180px; */
`;
const ItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid black;
  @media all and (max-width: 1080px) {
    min-width: 1180px;
  }
`;
const Item = styled.li`
  font-size: 14px;
  font-weight: 700;
  font-size: 12px;
  width: ${({ type }) => (type == "slno" ? "8%" : type == "name" ? "12%" : "")};

  @media all and (max-width: 1280px) {
    font-size: 12px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }

  @media all and (max-width: 425px) {
    font-size: 12px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;
