import React, { useEffect, useState } from "react";
import Products from "./Products";
import ProductDetails from "./ProductDetails";
import Cover from "../home/reuse/Cover";
import ProductList from "./ProductList";
import AddProductModal from "./modal/AddProductModal";
import AddProductCategoryModal from "./modal/AddProductCategoryModal";
import { productConfig } from "../../../axiosConfig";
import { useBearStore, usefetchProductsStore } from "../../context/Store";
import ProductCount from "./ProductCount";
import { useSearchParams } from "react-router-dom";

const ProductScreen = () => {
  const userData = useBearStore((state) => state.userData);
  const [addModal, setModal] = useState(false);
  const [addCategoryModal, setCategoryModal] = useState(false);
  const [variantModal, setVariantModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCategoryActive, setCategoryActive] = useState(false);
  const [isCategory, setCategory] = useState([]);
  const [productCount, setProductCount] = useState([]);
  const [product, setProduct] = useState([]);
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    productConfig
      .get("get-product-categories/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data, data } = response.data;
        if (app_data.StatusCode === 6000) {
          setCategory(app_data.data);
        }
      });
    fetchProductCount();
    fetchProductData();
  }, [addModal]);

  const fetchProductCount = () => {
    productConfig
      .get("count-products/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setProductCount(app_data.data);
        }
      });
  };

  const fetchProductData = () => {
    productConfig
      .get("/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setProduct(app_data.data);
          setPagination(app_data.pagination_data);
        }
      });
  };

  return (
    <Cover>
      <Products
        addModal={addModal}
        setModal={setModal}
        addCategoryModal={addCategoryModal}
        setCategoryModal={setCategoryModal}
      />
      <ProductCount productCount={productCount} />
      <ProductList
        product={product}
        pagination={pagination}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <AddProductModal
        isCategory={isCategory}
        setCategory={setCategory}
        addModal={addModal}
        setModal={setModal}
        isCategoryActive={isCategoryActive}
        setCategoryActive={setCategoryActive}
        fetchProductData={fetchProductData}
      />
      <AddProductCategoryModal
        addCategoryModal={addCategoryModal}
        setCategoryModal={setCategoryModal}
      />
    </Cover>
  );
};

export default ProductScreen;
