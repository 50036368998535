import "react-quill/dist/quill.snow.css";
import "./product.css";
import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import styled from "styled-components";
import ReactQuill from "react-quill";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2";

import product from "../../assets/images/product/foxtail_milet_dosa-2.jpg";
import deleteIcon from "../../assets/images/product/delete.svg";
import Plus from "../../assets/images/product/plus_icon.svg";
import AddVariantModal from "./modal/AddVariantModal";
import { productConfig, tracebilityConfig } from "../../../axiosConfig";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useBearStore } from "../../context/Store";
import DeleteModal from "./modal/DeleteModal";
import EditImage from "../../assets/images/product/pencil-fill.svg";

const ProductView = () => {
  const userData = useBearStore((state) => state.userData);
  const navigate = useNavigate();
  const [variantModal, setVariantModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const [title, setTitle] = useState("");
  const [active, setActive] = useState(false);
  const [description, setDescription] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [leftProductCount, setLeftProductCount] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [size, setSize] = useState("");
  const [isCategory, setCategory] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isSelectedBox, setSelectedBox] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [productTypeSelectedValue, setProductTypeSelectedValue] = useState("");
  const [price, setPrice] = useState("");
  const [photo1, setPhoto1] = useState("");
  const [photo2, setPhoto2] = useState("");
  const [photo3, setPhoto3] = useState("");
  const [photo4, setPhoto4] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [imageUrl3, setImageUrl3] = useState("");
  const [imageUrl4, setImageUrl4] = useState("");

  const [variants, setVariants] = useState([]);
  const [variantSize, setVariantSize] = useState("");
  const [variantPrice, setVariantPrice] = useState("");
  const [variantUnit, setVariantUnit] = useState("");
  const [variantLeftCount, setVariantLeftCount] = useState("");

  const [tagTitle, setTagTitle] = useState("");
  const [tagDescription, setTagDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [productionDate, setProductionDate] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [ingredients2, setIngredients2] = useState("");
  const [ingredients3, setIngredients3] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  const { id } = useParams();

  const showSuccessAlert = () => {
    // const navigate = useNavigate();

    Swal.fire({
      title: "Success!",
      text: "Changes Updated.",
      icon: "success",
      confirmButtonColor: "rgb(9, 175, 244)",
      confirmButtonText: "Done",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/products/");
      }
    });
  };

  useEffect(() => {
    productConfig
      .get(`product/${id}`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setProductData(app_data.data);
          setTitle(app_data.data.name);
          setDescription(app_data.data.description);
          setSpecifications(app_data.data.specifications);
          setLeftProductCount(app_data.data.left_product_count);
          setSize(app_data.data.size);
          setPrice(app_data.data.price);
          setPhoto1(app_data.data.photo_1);
          setPhoto2(app_data.data.photo_2);
          setPhoto3(app_data.data.photo_3);
          setPhoto4(app_data.data.photo_4);
          setActive(app_data.data.is_active);
          setSelected(app_data.data.product_category);
          setSelectedValue(app_data.data.size_unit);
          setProductTypeSelectedValue(app_data.data.product_type);
          setTagTitle(app_data.data.tag_title);
          setTagDescription(app_data.data.tag_description);
          setKeywords(app_data.data.keywords);
          setProductionDate(app_data.data.production_date);
          setIngredients(app_data.data.ingredient);
          setIngredients2(app_data.data.ingredient2);
          setIngredients3(app_data.data.ingredient3);
          setWidth(app_data.data.width);
          setHeight(app_data.data.height);
          setTotalCount(app_data.data.total_count);
        }
      });
    fetchProductCategory();
    fetchVariantProducts();
  }, [id]);

  const fetchProductCategory = () => {
    productConfig
      .get("get-product-categories/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data, data } = response.data;
        if (app_data.StatusCode === 6000) {
          setCategory(app_data.data);
        }
      });
  };

  const handleCheckBox = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, value]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => id !== value)
      );
    }
  };
  console.log(description, "=========description============");

  const editProduct = () => {
    const formData = new FormData();

    if (productData.name !== title) {
      formData.append("name", title);
    }

    if (productData.size !== size) {
      formData.append("size", size);
    }

    if (productData.description !== description) {
      formData.append("description", description);
    }

    if (productData.specifications !== specifications) {
      formData.append("specifications", specifications);
    }

    if (productData.size_unit !== selectedValue) {
      formData.append("size_unit", selectedValue);
    }

    if (productData.photo_1 !== photo1) {
      formData.append("photo_1", photo1);
    }

    if (productData.photo_2 !== photo2) {
      formData.append("photo_2", photo2);
    }

    if (productData.photo_3 !== photo3) {
      formData.append("photo_3", photo3);
    }

    if (productData.photo_4 !== photo4) {
      formData.append("photo_4", photo4);
    }

    if (productData.product_type !== productTypeSelectedValue) {
      formData.append("product_type", productTypeSelectedValue);
    }

    if (productData.total_count !== totalCount) {
      formData.append("total_count", totalCount);
    }

    if (productData.left_product_count !== leftProductCount) {
      formData.append("left_product_count", leftProductCount);
    }

    if (productData.price !== price) {
      formData.append("price", price);
    }

    if (productData.tag_title !== tagTitle) {
      formData.append("tag_title", tagTitle);
    }
    if (productData.tag_description !== tagDescription) {
      formData.append("tag_description", tagDescription);
    }
    if (productData.keywords !== keywords) {
      formData.append("keywords", keywords);
    }
    if (productData.production_date !== productionDate) {
      formData.append("production_date", productionDate);
    }
    if (productData.ingredient !== ingredients) {
      formData.append("ingredient", ingredients);
    }
    if (productData.ingredient2 !== ingredients2) {
      formData.append("ingredient2", ingredients2);
    }
    if (productData.ingredient3 !== ingredients3) {
      formData.append("ingredient3", ingredients3);
    }
    if (productData.width !== width) {
      formData.append("width", width);
    }
    if (productData.height !== height) {
      formData.append("height", height);
    }

    for (let i = 0; i < selectedIds.length; i++) {
      formData.append("product_category", selectedIds[i]);
    }
    productConfig
      .post(`edit-products/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          showSuccessAlert();
        }
      });
  };

  const deleteProduct = () => {
    productConfig
      .post(
        `delete-product/${id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        }
      )
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          navigate("/products");
        }
      });
  };

  const deleteVariant = (id) => {
    productConfig
      .post(
        `delete-variant-product/${id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        }
      )
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          window.location.reload();
        }
      });
  };

  const handleDescription = (value) => {
    setDescription(value);
  };

  const handleSpecification = (value) => {
    setSpecifications(value);
  };

  const handlePhoto1 = (event) => {
    setPhoto1(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl(imageContent);
    };
  };

  const handlePhoto2 = (event) => {
    setPhoto2(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl2(imageContent);
    };
  };

  const handlePhoto3 = (event) => {
    setPhoto3(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl3(imageContent);
    };
  };

  const handlePhoto4 = (event) => {
    setPhoto4(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl4(imageContent);
    };
  };

  const fetchVariantProducts = () => {
    productConfig
      .get(`get-variant-product/${id}`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setVariants(app_data.data);
        }
      });
  };

  const addVariantProduct = () => {
    const formData = new FormData();

    formData.append("size", variantSize);
    formData.append("price", variantPrice);
    formData.append("unit", variantUnit);
    formData.append("left_product", variantLeftCount);

    productConfig
      .post(`add-variant-product/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          window.location.reload();
        }
      });
  };

  return (
    <Cover>
      <Wrapper>
        <MainContainer>
          <TopContainer>
            <TopRightContainer>
              <ProductTitle>{productData.name}</ProductTitle>
              <TagContainer className={active ? "" : "inactive"}>
                <Tag>{productData.is_active ? "active" : "inactive"}</Tag>
              </TagContainer>
            </TopRightContainer>
            <TopLeftContainer>
              {productData?.tracebility ? (
                <TracebilityContainer to={`tracebility/`}>
                  Tracebility Management
                </TracebilityContainer>
              ) : (
                <TracebilityContainer to={`add-tracebility/`}>
                  Add Tracebility
                </TracebilityContainer>
              )}

              <DeleteContainer onClick={() => setDeleteModal(true)}>
                Delete
              </DeleteContainer>
            </TopLeftContainer>
          </TopContainer>
          <ProductDetailsContainer>
            <Form>
              <TitlteDiv>
                <Label>Title</Label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </TitlteDiv>
              <DescriptionDiv>
                <Label>Descriptions</Label>
                <ReactQuill
                  className="rich-text-area"
                  value={description}
                  onChange={handleDescription}
                />
              </DescriptionDiv>
              <SpecificationDiv>
                <Label>Specification</Label>
                <ReactQuill
                  className="rich-text-area"
                  value={specifications}
                  onChange={handleSpecification}
                />
              </SpecificationDiv>
              <LeftProductConatiner>
                <Label>Left Product</Label>
                <input
                  type="text"
                  value={leftProductCount}
                  onChange={(e) => setLeftProductCount(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Total Count </Label>
                <input
                  type="text"
                  value={totalCount}
                  onChange={(e) => setTotalCount(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Tag Title </Label>
                <input
                  type="text"
                  value={tagTitle}
                  onChange={(e) => setTagTitle(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Tag Description </Label>
                <input
                  type="text"
                  value={tagDescription}
                  onChange={(e) => setTagDescription(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Keyword </Label>
                <input
                  type="text"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Production Date </Label>
                <input
                  type="date"
                  value={productionDate}
                  onChange={(e) => setProductionDate(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Ingredients </Label>
                <input
                  type="text"
                  value={ingredients}
                  onChange={(e) => setIngredients(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Ingredients2 </Label>
                <input
                  type="text"
                  value={ingredients2}
                  onChange={(e) => setIngredients2(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Ingredients3 </Label>
                <input
                  type="text"
                  value={ingredients3}
                  onChange={(e) => setIngredients3(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Width </Label>
                <input
                  type="text"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                />
              </LeftProductConatiner>
              <LeftProductConatiner>
                <Label>Height </Label>
                <input
                  type="text"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </LeftProductConatiner>
              <PriceConatiner>
                <Label>Size</Label>
                <input
                  type="text"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                />
              </PriceConatiner>
              <PriceConatiner>
                <Label>Units</Label>
                <select
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.target.value)}
                >
                  <option value="g">g</option>
                  <option value="kg">kg</option>
                  <option value="l">l</option>
                  <option value="ml">ml</option>
                </select>
              </PriceConatiner>
              <PriceConatiner>
                <Label>Product Type</Label>
                <select
                  value={productTypeSelectedValue}
                  onChange={(e) => setProductTypeSelectedValue(e.target.value)}
                >
                  <option value="digestive">Digestive</option>
                  <option value="immunity">Immunity</option>
                  <option value="diabetic">Diabetic</option>
                  <option value="med_kit">Med Kit</option>
                  <option value="ready_to_cook">Ready To Cook</option>
                    <option value="spices">Spices</option>
                    <option value="ready_to_drink">Ready To Drink</option>
                    <option value="ready_to_eat">Ready To Eat</option>
                    <option value="natural_sweetener">Natural Sweetener</option>
                </select>
              </PriceConatiner>
              <PriceConatiner>
                <Label>Price</Label>
                <input
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </PriceConatiner>
            </Form>
          </ProductDetailsContainer>
          <MediaContainer>
            <MediaTitle>Media</MediaTitle>
            <Gallery>
              <ImageListContainer>
                <FileDiv>
                  <PhotoDiv>
                    <img src={imageUrl ? imageUrl : photo1} alt="Image" />
                  </PhotoDiv>
                  <ImageInput>
                    <input type="file" onChange={handlePhoto1} />
                  </ImageInput>
                </FileDiv>
                <FileDiv>
                  <PhotoDiv>
                    <img src={imageUrl2 ? imageUrl2 : photo2} alt="Image" />
                  </PhotoDiv>
                  <ImageInput>
                    <input type="file" onChange={handlePhoto2} />
                  </ImageInput>
                </FileDiv>
                <FileDiv>
                  <PhotoDiv>
                    <img src={imageUrl3 ? imageUrl3 : photo3} alt="Image" />
                  </PhotoDiv>
                  <ImageInput>
                    <input type="file" onChange={handlePhoto3} />
                  </ImageInput>
                </FileDiv>
                <FileDiv>
                  <PhotoDiv>
                    <img src={imageUrl4 ? imageUrl4 : photo4} alt="Image" />
                  </PhotoDiv>
                  <ImageInput>
                    <input type="file" onChange={handlePhoto4} />
                  </ImageInput>
                </FileDiv>
              </ImageListContainer>
            </Gallery>
          </MediaContainer>
          <ProductCategoryContainer>
            <ProductCtegoryTitle>Product Category : </ProductCtegoryTitle>
            {!isSelectedBox ? (
              <ProductCategoryDiv>
                {productData.product_category?.map((item) => (
                  <>
                    <CategorySelectConatiner>
                      <Label>{item.category_name} : </Label>
                      <input
                        type="checkbox"
                        checked={item.id}
                        value={item.id}
                        id={item.id}
                      />
                    </CategorySelectConatiner>
                  </>
                ))}
              </ProductCategoryDiv>
            ) : (
              <CategoryContainer>
                <CategoryDiv>
                  {isCategory?.map((is_category_item) => (
                    <Category>
                      <Label>{is_category_item.category_name}</Label>
                      <input
                        type="checkbox"
                        name="ids"
                        value={is_category_item.id}
                        checked={selectedIds.includes(
                          is_category_item.id.toString()
                        )}
                        onChange={handleCheckBox}
                      />
                    </Category>
                  ))}
                </CategoryDiv>
              </CategoryContainer>
            )}

            <EditContainer onClick={() => setSelectedBox(!isSelectedBox)}>
              <img src={EditImage} alt="edit" />
            </EditContainer>
          </ProductCategoryContainer>
          <SubmitContainer>
            <CancelButton to="/products">Cancel</CancelButton>
            <SubmitButton
              onClick={() => {
                editProduct();
              }}
            >
              Submit
            </SubmitButton>
          </SubmitContainer>
          <VariantContainer>
            <VariantTitileDiv>
              <Varianttitle>Variants</Varianttitle>
              <AddButtonContainer>
                <Button onClick={() => setVariantModal(true)}>
                  <ImageDiv>
                    <img src={Plus} alt="Add" />
                  </ImageDiv>
                  <Span>Add</Span>
                </Button>
              </AddButtonContainer>
            </VariantTitileDiv>
            <VariantListContainer>
              <Variants>
                <VariantList>
                  <VariantDetails>
                    <VariantSize>Size</VariantSize>
                    <VariantUnit>Unit</VariantUnit>
                    <VariantUnit>Price</VariantUnit>
                  </VariantDetails>
                  <DeleteButtonContainer></DeleteButtonContainer>
                </VariantList>
                {variants?.map((variant) => (
                  <VariantList>
                    <VariantDetails>
                      <VariantSize>{variant.size}</VariantSize>
                      <VariantUnit>{variant.unit}</VariantUnit>
                      <VariantUnit>{variant.price}rs</VariantUnit>
                    </VariantDetails>
                    <DeleteButtonContainer
                      onClick={() => {
                        deleteVariant(variant.id);
                      }}
                    >
                      <img src={deleteIcon} alt="delete" />
                    </DeleteButtonContainer>
                  </VariantList>
                ))}
              </Variants>
            </VariantListContainer>
          </VariantContainer>
        </MainContainer>
      </Wrapper>
      <AddVariantModal
        variantModal={variantModal}
        setVariantModal={setVariantModal}
        setVariantSize={setVariantSize}
        setVariantPrice={setVariantPrice}
        setVariantUnit={setVariantUnit}
        addVariantProduct={addVariantProduct}
        setVariantLeftCount={setVariantLeftCount}
        variantLeftCount={variantLeftCount}
      />
      <DeleteModal
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        deleteProduct={deleteProduct}
      />
    </Cover>
  );
};

export default ProductView;

const MainContainer = styled.div``;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
// const DeleteContainer = styled.div`
//   width: 10%;
//   cursor: pointer;
//   img {
//     width: 100%;
//     display: block;
//   }
// `;
const TopContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 425px) {
    flex-wrap: wrap;
  }
`;
const ProductTitle = styled.h3`
  font-size: 28px;
  @media all and (max-width: 980px) {
    font-size: 18px;
  }
`;
const Tag = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: green;
`;
const TagContainer = styled.div`
  margin-top: 10px;
  background: lightgreen;
  height: 20px;
  border-radius: 10%;
  width: 40%;
  text-align: center;
  &.inactive {
    background: #dc4046;
    color: red;
  }
`;
const ProductDetailsContainer = styled.div`
  padding-top: 20px;
`;
const Form = styled.form``;
const Label = styled.label`
  margin-top: 10px;
`;
const TitlteDiv = styled.div`
  width: 100%;
  input {
    width: 90%;
    padding: 5px;
    display: block;
    margin-top: 8px;
    border: 1px solid #ccc;
  }
`;
const DescriptionDiv = styled.div`
  margin-top: 3%;
  height: 385px;
  @media all and (max-width: 425px) {
    margin-top: 5%;
    height: 425px;
  }
`;
const LeftProductConatiner = styled.div`
  margin-top: 3%;
  input {
    width: 90%;
    padding: 5px;
    display: block;
    margin-top: 8px;
    border: 1px solid #ccc;
  }
`;
const PriceConatiner = styled.div`
  margin-top: 4%;
  input {
    width: 90%;
    padding: 5px;
    display: block;
    margin-top: 8px;
    border: 1px solid #ccc;
  }
  select {
    padding: 3px;
    margin-left: 10px;
  }
  @media all and (max-width: 425px) {
    margin-top: 5%;
  }
`;
const MediaContainer = styled.div`
  margin-top: 5%;
  @media all and (max-width: 425px) {
    margin-top: 13%;
  }
`;
const MediaTitle = styled.h3`
  margin-bottom: 20px;
`;
const Gallery = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 60px;
  padding: 10px;
`;
const ImageListContainer = styled.div`
  width: 20%;
  @media all and (max-width: 768px) {
    width: 50%;
  }
  @media all and (max-width: 425px) {
    width: 100%;
  }
`;
const ImageList = styled.div`
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
`;
// const ButtonContainer = styled.div`
//   height: 240px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   input {
//     width: 70%;
//   }
// `;

const VariantContainer = styled.div`
  margin-top: 30px;
`;
const VariantTitileDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Varianttitle = styled.h3`
  font-size: 34px;
`;
const AddButtonContainer = styled.div``;
const Button = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 5px;
  background: var(--button-color);
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  width: 84px;
  cursor: pointer;
`;
const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  padding: 10px 0px;
  img {
    display: block;
    width: 20px;
  }
`;
const Span = styled.span``;
// const ImageContainer = styled.div`
//   width: 20px;
//   img {
//     width: 100%;
//     display: block;
//     filter: invert(100%);
//   }
// `;
const VariantListContainer = styled.div`
  margin-top: 30px;
`;
const DeleteButtonContainer = styled.div`
  width: 2%;
  cursor: pointer;
  img {
    width: 100%;
    display: block;
  }
`;
const Variants = styled.ul`
  max-width: 100%;
  overflow: scroll;
`;
const VariantList = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  min-width: 1180px;
`;
const VariantSize = styled.h3`
  text-align: center;
`;
const VariantUnit = styled.h3`
  text-align: center;
`;
const SubmitContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;
const SubmitButton = styled.div`
  padding: 10px;
  background: var(--button-color);
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  width: 150px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    border: 1px solid var(--button-color);
    color: var(--button-color);
  }
`;
const CancelButton = styled(Link)`
  padding: 10px;
  background: var(--button-color);
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  width: 150px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    border: 1px solid var(--button-color);
    color: var(--button-color);
  }
`;
const SpecificationDiv = styled.div`
  height: 385px;
  @media all and (max-width: 425px) {
    height: 425px;
  }
`;

const FileDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

const PhotoDiv = styled.div`
  width: 700px;
  margin-right: 20px;
  img {
    width: 100%;
    display: block;
  }
`;

const ImageInput = styled.div`
  align-items: center;
  color: #fff;
  width: 60%;
`;

const ProductCategoryContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ProductCategoryDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const ProductCtegoryTitle = styled.h5``;
const CategorySelectConatiner = styled.div`
  margin-left: 30px;
  input {
    margin-top: 5px;
  }
`;
const TopRightContainer = styled.div``;
const TopLeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  gap: 10px;
  @media all and (max-width: 1280px) {
    width: 34%;
  }
  @media all and (max-width: 980px) {
    width: 40%;
  }
  @media all and (max-width: 980px) {
    width: 50%;
  }
  @media all and (max-width: 425px) {
    width: 100%;
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;
const DeleteContainer = styled.div`
  border: 1px solid red;
  color: red;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
  width: 84px;
  padding: 5px;
  cursor: pointer;
  @media all and (max-width: 1280px) {
    font-size: 12px;
  }
  @media all and (max-width: 425px) {
    width: 100%;
  }
`;
const TracebilityContainer = styled(Link)`
  border: 1px solid green;
  color: green;
  text-align: center;
  font-weight: 700;
  border-radius: 5px;
  width: 200px;
  padding: 5px;
  cursor: pointer;
  @media all and (max-width: 1280px) {
    font-size: 12px;
  }
  @media all and (max-width: 425px) {
    width: 100%;
  }
`;
const EditContainer = styled.div`
  width: 3%;
  margin-left: 30px;
  cursor: pointer;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 425px) {
    width: 6%;
  }
`;

const CategoryContainer = styled.div`
  padding: 10px;
`;
const CategoryDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const Category = styled.div`
  input {
    margin-left: 5px;
  }
`;
const VariantDetails = styled.div`
  width: 52%;
  display: flex;
  justify-content: space-between;
  padding: 8px;
`;
