import React from "react";
import styled from "styled-components";

const Button = ({ handleSubmit }) => {
  return (
    <>
      <GeneralButton onClick={() => handleSubmit()}> Submit</GeneralButton>
    </>
  );
};

export default Button;

const GeneralButton = styled.div`
  padding: 17px 40px;
  border-radius: 9px;
  border: 0;
  background-color: var(--button-color);
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.5s ease;
  color: #fff;
  font-weight: 700;
  margin: 0 auto;
  /* display: block; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    letter-spacing: 3px;
    background-color: var(--button-color);
    color: hsl(0, 0%, 100%);
    box-shadow: -webkit-box-shadow: 7px 13px 27px 1px rgba(210,162,249,0.72);
-moz-box-shadow: 7px 13px 27px 1px rgba(210,162,249,0.72);
box-shadow: 7px 13px 27px 1px rgba(210,162,249,0.72)
  }
  &:active {
    letter-spacing: 3px;
    background-color: hsl(261deg 80% 48%);
    color: hsl(0, 0%, 100%);-webkit-box-shadow: 7px 13px 27px 1px rgba(210,162,249,0.72);
-moz-box-shadow: 7px 13px 27px 1px rgba(210,162,249,0.72);
box-shadow: 7px 13px 27px 1px rgba(210,162,249,0.72)rgb(93 24 220) 0px 0px 0px 0px;
    transform: translateY(10px);
    transition: 100ms;
  }
`;
