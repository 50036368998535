import React, { useState } from "react";
import Plus from "../../assets/images/product/plus_icon.svg";
import styled from "styled-components";
import FeaturedCollectionFormModal from "./modal/FeaturedCollectionFormModal";
import { webConfig } from "../../../axiosConfig";

const FeaturedCollectionTop = ({
  setTitle,
  setUrl,
  setVideo,
  setVideoUrl,
  setAddModal,
  addModal,
  addFeatureCollectionVideo,
}) => {
  return (
    <MainContainer>
      <ButtonContainer
        onClick={() => {
          setAddModal(true);
        }}
      >
        <Button>
          <ImageDiv>
            <img src={Plus} alt="Add" />
          </ImageDiv>
          <ButtonTitle>Featured Collection</ButtonTitle>
        </Button>
      </ButtonContainer>
      <FeaturedCollectionFormModal
        setModal={setAddModal}
        modal={addModal}
        setTitle={setTitle}
        setUrl={setUrl}
        setVideo={setVideo}
        setVideoUrl={setVideoUrl}
        handleSubmit={addFeatureCollectionVideo}
      />
    </MainContainer>
  );
};

export default FeaturedCollectionTop;

const MainContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  width: 25%;
  background-color: var(--button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  margin-right: 20px;
  cursor: pointer;
  @media all and (max-width: 1024px) {
    width: 30%;
  }
  @media all and (max-width: 768px) {
    width: 30%;
  }
  @media all and (max-width: 768px) {
    width: 35%;
  }
  @media all and (max-width: 768px) {
    width: 45%;
  }
  @media all and (max-width: 480px) {
    width: 50%;
  }
  @media all and (max-width: 480px) {
    width: 70%;
  }
  @media all and (max-width: 320px) {
    width: 80%;
  }
`;
const Button = styled.div`
  width: 70%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media all and (max-width: 320px) {
    width: 80%;
  }
`;
const ImageDiv = styled.div`
  width: 15%;
  img {
    width: 100%;
    display: block;
  }
`;
const ButtonTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #fff;
`;
