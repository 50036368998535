import React, { useEffect, useState } from "react";
import styled from "styled-components";
import empty from "../../../assets/images/product/image.png";
import { webConfig } from "../../../../axiosConfig";
import { useBearStore, useOfferBannerIdStore } from "../../../context/Store";
import ButtonLoader from "../../../helpers/ButtonLoader";

const OfferBanerModal = ({
  setModal,
  modal,
  type,
  image,
  setImage,
  imageUrl,
  setImageUrl,
  mobImage,
  setMobImage,
  mobImageUrl,
  setMobImageUrl,
  actionFunction,
  isLoading,
  setLoading,
}) => {
  const userData = useBearStore((state) => state.userData);
  const OfferBanneId = useOfferBannerIdStore((state) => state.OfferBannerId);

  const handleImage = (event) => {
    setImage(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl(imageContent);
    };
  };

  const handleMobImage = (event) => {
    setMobImage(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setMobImageUrl(imageContent);
    };
  };

  const fetchSingleOfferBanner = () => {
    OfferBanneId &&
      webConfig
        .get(`single-offer-banner/${OfferBanneId}/`, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            console.log("heeeeee");
            // setImage(app_data.data.image);
            // setMobImage(app_data.data.mob_image);
          }
        });
  };

  useEffect(() => {
    fetchSingleOfferBanner();
  }, [OfferBanneId]);

  return (
    <Cover>
      <Container className={modal && "active"}>
        <Overlay onClick={() => setModal(false)}></Overlay>
        <Modal>
          <MainContainer>
            <Wrapper>
              <MainTitle>Add Offer Banner</MainTitle>
              <Form onSubmit={(e) => actionFunction(e)}>
                <FieldDiv className="upload-image">
                  <LabelDiv>
                    <Label>
                      Upload the blog desktop Image(size-1920 x 650 px){" "}
                    </Label>
                  </LabelDiv>
                  {type === "edit_modal" ? (
                    <ImageDiv>
                      <img src={image} alt="banner-image" />
                    </ImageDiv>
                  ) : (
                    <ImageDiv>
                      <img
                        src={imageUrl ? imageUrl : empty}
                        alt="banner-image"
                      />
                    </ImageDiv>
                  )}

                  <InputDiv>
                    <input type="file" onChange={handleImage} />
                  </InputDiv>
                </FieldDiv>
                <FieldDiv className="upload-image">
                  <LabelDiv>
                    <Label>Upload the Thumbnail Image(1080 x 1080 px) </Label>
                  </LabelDiv>

                  <ImageDiv>
                    <img
                      src={mobImageUrl ? mobImageUrl : empty}
                      alt="bl-mage"
                    />
                  </ImageDiv>

                  <InputDiv>
                    <input type="file" onChange={handleMobImage} />
                  </InputDiv>
                </FieldDiv>

                <FieldDiv className="button-container">
                  {isLoading ? (
                    <Button
                      style={{
                        height: isLoading && "18px",
                        width: isLoading && "15%",
                      }}
                    >
                      <ButtonLoader />
                    </Button>
                  ) : (
                    <SubmitButton value="Submit" type="submit" />
                  )}

                  <Button className="cancel" onClick={() => setModal(false)}>
                    Cancel
                  </Button>
                </FieldDiv>
              </Form>
            </Wrapper>
          </MainContainer>
        </Modal>
      </Container>
    </Cover>
  );
};

export default OfferBanerModal;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  padding-bottom: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const MainContainer = styled.div``;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Form = styled.form``;
const MainTitle = styled.h2`
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const FieldDiv = styled.div`
  margin-top: 15px;
  &.upload-image {
    font-size: 10px;
  }
  &.button-container {
    display: flex;
    gap: 10px;
  }
`;
const LabelDiv = styled.div``;
const ImageDiv = styled.div`
  width: 20%;
  margin-top: 10px;
  img {
    width: 100%;
    display: block;
  }
`;
const InputDiv = styled.div`
  margin-top: 5px;
  input {
    width: 100%;
    padding: 5px;
  }
`;
const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
`;

const Button = styled.div`
  background-color: var(--button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 15%;

  color: #fff;
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
  cursor: pointer;
  &.cancel {
    background-color: #fff;
    color: red;
    border: 1px solid red;
  }
`;

const SubmitButton = styled.input`
  background-color: var(--button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 15%;

  color: #fff;
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
  cursor: pointer;
  &.cancel {
    background-color: #fff;
    color: red;
    border: 1px solid red;
  }
`;
