import React, { useState } from "react";
import styled from "styled-components";
import "../product.css";

import Close from "../../../assets/images/product/close.svg";
import EmptyImage from "../../../assets/images/product/image.png";
import ReactQuill from "react-quill";
import { productConfig } from "../../../../axiosConfig";
import { useBearStore } from "../../../context/Store";

const AddProductModal = ({
  addModal,
  setModal,
  isCategory,
  fetchProductData,
  setCategory,
  setCategoryActive,
  isCategoryActive,
}) => {
  const userData = useBearStore((state) => state.userData);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [photo1, setPhoto1] = useState("");
  const [imageUrl1, setImageUrl1] = useState("");
  const [photo2, setPhoto2] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [photo3, setPhoto3] = useState("");
  const [imageUrl3, setImageUrl3] = useState("");
  const [photo4, setPhoto4] = useState("");
  const [imageUrl4, setImageUrl4] = useState("");
  const [description, setDescription] = useState("");
  const [specification, setSpecification] = useState("");
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [unit, setUnit] = useState("");
  const [productCount, setProductCount] = useState("");
  const [tagTitle, setTagTitle] = useState("");
  const [tagDescription, setTagDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [productionDate, setProductionDate] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [ingredients2, setIngredients2] = useState("");
  const [ingredients3, setIngredients3] = useState("");
  const [productType, setProductType] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [variantSize, setVariantSize] = useState("");
  const [variantUnit, setVariantUnit] = useState("");
  const [VariantPrice, setVariantPrice] = useState("");
  const [variantLeftCount, setVariantLeftCount] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [leftProductCount, setLeftProductCount] = useState("");

  const handleDescription = (value) => {
    setDescription(value);
  };

  const handleSpecification = (value) => {
    setSpecification(value);
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    if (checked) {
      // Add the ID to the array if checkbox is checked
      setSelectedIds((prevItems) => [...prevItems, id]);
    } else {
      // Remove the ID from the array if checkbox is unchecked
      setSelectedIds((prevItems) => prevItems.filter((item) => item !== id));
    }
  };

  const handlePhoto1 = (event) => {
    setPhoto1(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl1(imageContent);
    };
  };

  const handlePhoto2 = (event) => {
    setPhoto2(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl2(imageContent);
    };
  };

  const handlePhoto3 = (event) => {
    setPhoto3(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl3(imageContent);
    };
  };

  const handlePhoto4 = (event) => {
    setPhoto4(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setImageUrl4(imageContent);
    };
  };

  const addProduct = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("size", size);
    formData.append("total_count", productCount);
    formData.append("price", productPrice);
    formData.append("photo_1", photo1);
    formData.append("photo_2", photo2);
    formData.append("photo_3", photo3);
    formData.append("photo_4", photo4);
    formData.append("size_unit", unit);
    formData.append("description", description);
    formData.append("tag_title", tagTitle);
    formData.append("tag_description", tagDescription);
    formData.append("product_date", productionDate);
    formData.append("keywords", keywords);
    formData.append("ingredient", ingredients);
    formData.append("ingredient2", ingredients2);
    formData.append("ingredient3", ingredients3);
    formData.append("specifications", specification);
    formData.append("product_type", productType);
    formData.append("variant_size", variantSize);
    formData.append("variant_price", VariantPrice);
    formData.append("variant_unit", variantUnit);
    formData.append("variant_left_product_count", variantLeftCount);
    formData.append("width", width);
    formData.append("height", height);
    formData.append("weight", weight);
    formData.append("left_product_count", leftProductCount);

    for (let i = 0; i < selectedIds.length; i++) {
      formData.append("product_category", selectedIds[i]);
    }
    console.log(selectedIds, "----in--------");

    productConfig
      .post("add-products/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setModal(false);
          fetchProductData();
        }
      });
  };
  return (
    <Cover>
      <Container className={addModal ? "active" : ""}>
        <Overlay onClick={() => setModal(false)}></Overlay>
        <Modal>
          <Wrapper>
            <TopContainer>
              <CloseDiv onClick={() => setModal(false)}>
                <img src={Close} alt="Close" />
              </CloseDiv>
            </TopContainer>
            <ContentContainer>
              <From>
                <NameDiv>
                  <Label>Product Name : </Label>
                  <input
                    type="text"
                    placeholder="Enter the product name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </NameDiv>
                <DescriptionDiv>
                  <Label>Description : </Label>
                  <ReactQuill
                    className="rich-text-area-modal"
                    onChange={handleDescription}
                  />
                </DescriptionDiv>
                <SpecificationDiv>
                  <Label>Specifications : </Label>
                  <ReactQuill
                    className="rich-text-area-specification"
                    onChange={handleSpecification}
                  />
                </SpecificationDiv>
                <LeftProductDiv>
                  <Label>Production date : </Label>
                  <input
                    type="date"
                    onChange={(e) => setProductionDate(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Width in mm : </Label>
                  <input
                    type="text"
                    placeholder="Enter the width used"
                    onChange={(e) => setWidth(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Height in cm : </Label>
                  <input
                    type="text"
                    placeholder="Enter the height used"
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>weight in pound : </Label>
                  <input
                    type="text"
                    placeholder="Enter the height used"
                    onChange={(e) => setWeight(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Ingredients : </Label>
                  <input
                    type="text"
                    placeholder="Enter the ingredient used"
                    onChange={(e) => setIngredients(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Ingredients : </Label>
                  <input
                    type="text"
                    placeholder="Enter the ingredient used"
                    onChange={(e) => setIngredients2(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Ingredients : </Label>
                  <input
                    type="text"
                    placeholder="Enter the ingredient used"
                    onChange={(e) => setIngredients3(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Product Count : </Label>
                  <input
                    type="text"
                    placeholder="Enter the Count of the product the left"
                    onChange={(e) => setProductCount(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Left Product Count : </Label>
                  <input
                    type="text"
                    placeholder="Enter the Count of the product the left"
                    onChange={(e) => setLeftProductCount(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Meta Tag Title : </Label>
                  <input
                    type="text"
                    placeholder="Enter the tag title of the product"
                    onChange={(e) => setTagTitle(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Meta Tag Description : </Label>
                  <input
                    type="text"
                    placeholder="Enter the tag description of the product"
                    onChange={(e) => setTagDescription(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Meta Tag Keywords : </Label>
                  <input
                    type="text"
                    placeholder="Enter the tag keywords of the product"
                    onChange={(e) => setKeywords(e.target.value)}
                  />
                </LeftProductDiv>
                <LeftProductDiv>
                  <Label>Product Price : </Label>
                  <input
                    type="text"
                    placeholder="Enter the Product price"
                    onChange={(e) => setProductPrice(e.target.value)}
                  />
                </LeftProductDiv>
                <ProductTypeDiv>
                  <Label>Product Type:</Label>
                  <select
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                  >
                    <option value="digestive">Digestive</option>
                    <option value="immunity">Immunity</option>
                    <option value="diabetic">Diabetic</option>
                    <option value="med_kit">Med Kit</option>
                    <option value="ready_to_cook">Ready To Cook</option>
                    <option value="spices">Spices</option>
                    <option value="ready_to_drink">Ready To Drink</option>
                    <option value="ready_to_eat">Ready To Eat</option>
                    <option value="natural_sweetener">Natural Sweetener</option>
                  </select>
                </ProductTypeDiv>
                <SizeContainer>
                  <SizeDiv>
                    <Label>Size : </Label>
                    <input
                      type="text"
                      placeholder="Enter the size"
                      onChange={(e) => setSize(e.target.value)}
                    />
                  </SizeDiv>
                  <UnitDiv>
                    <Label>Unit : </Label>
                    <select
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                    >
                      <option value="kg">Kg</option>
                      <option value="g">G</option>
                      <option value="l">L</option>
                      <option value="ml">Ml</option>
                    </select>
                  </UnitDiv>
                </SizeContainer>
                <ImageContainer>
                  <Label>Upload the Image(635 x 750)</Label>
                  <FileDiv>
                    <PhotoDiv>
                      <img
                        src={imageUrl1 ? imageUrl1 : EmptyImage}
                        alt="Image"
                      />
                    </PhotoDiv>
                    <ImageInput>
                      <input type="file" onChange={handlePhoto1} />
                    </ImageInput>
                  </FileDiv>
                  <FileDiv>
                    <PhotoDiv>
                      <img
                        src={imageUrl2 ? imageUrl2 : EmptyImage}
                        alt="Imge"
                      />
                    </PhotoDiv>
                    <ImageInput>
                      <input type="file" onChange={handlePhoto2} />
                    </ImageInput>
                  </FileDiv>
                  <FileDiv>
                    <PhotoDiv>
                      <img
                        src={imageUrl3 ? imageUrl3 : EmptyImage}
                        alt="Image"
                      />
                    </PhotoDiv>
                    <ImageInput>
                      <input type="file" onChange={handlePhoto3} />
                    </ImageInput>
                  </FileDiv>
                  <FileDiv>
                    <PhotoDiv>
                      <img
                        src={imageUrl4 ? imageUrl4 : EmptyImage}
                        alt="Image"
                      />
                    </PhotoDiv>
                    <ImageInput>
                      <input type="file" onChange={handlePhoto4} />
                    </ImageInput>
                  </FileDiv>
                </ImageContainer>
                <ProductCategoryContainer>
                  <ProductCtegoryTitle>Product Category : </ProductCtegoryTitle>
                  <ProductCategoryDiv>
                    {isCategory?.map((is_category_item) => (
                      <CategorySelectConatiner>
                        <Label>{is_category_item.category_name} : </Label>
                        <input
                          type="checkbox"
                          value={is_category_item.id}
                          id={is_category_item.id}
                          onChange={handleCheckboxChange}
                          checked={selectedIds.includes(
                            is_category_item.id.toString()
                          )}
                        />
                      </CategorySelectConatiner>
                    ))}
                  </ProductCategoryDiv>
                </ProductCategoryContainer>

                <VarianQuandityDiv>
                  <Label>Variant Weight : </Label>
                  <input
                    type="text"
                    placeholder="Enter the weight"
                    onChange={(e) => setVariantSize(e.target.value)}
                  />
                </VarianQuandityDiv>
                <VarianQuandityDiv>
                  <Label>Variant Price : </Label>
                  <input
                    type="text"
                    placeholder="Enter the Price"
                    onChange={(e) => setVariantPrice(e.target.value)}
                  />
                </VarianQuandityDiv>
                <VarianQuandityDiv>
                  <Label>Left Variant Count : </Label>
                  <input
                    type="text"
                    placeholder="Enter the Count"
                    onChange={(e) => setVariantLeftCount(e.target.value)}
                  />
                </VarianQuandityDiv>
                <VariantUnitDiv>
                  <Label>Variant Unit : </Label>
                  <select
                    value={variantUnit}
                    onChange={(e) => setVariantUnit(e.target.value)}
                  >
                    <option value="kg">Kg</option>
                    <option value="g">G</option>
                    <option value="l">L</option>
                    <option value="ml">Ml</option>
                  </select>
                </VariantUnitDiv>
              </From>
              <ButtonContainer>
                <Button
                  onClick={() => {
                    addProduct();
                  }}
                >
                  Submit
                </Button>
              </ButtonContainer>
            </ContentContainer>
          </Wrapper>
        </Modal>
      </Container>
    </Cover>
  );
};

export default AddProductModal;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  height: 90vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
`;
const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;
const CloseDiv = styled.div`
  width: 3%;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
`;
const ContentContainer = styled.div`
  padding: 10px;
`;
const From = styled.form`
  width: 100%;
`;
const NameDiv = styled.div`
  width: 100%;
  input {
    width: 100%;
    margin-top: 10px;
    padding: 5px;
  }
`;
const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
`;
const DescriptionDiv = styled.div`
  margin-top: 20px;
  height: 380px;
`;
const SpecificationDiv = styled.div`
  margin-top: 20px;
  height: 380px;
`;
const SizeContainer = styled.div`
  margin-bottom: 50px;
`;
const SizeDiv = styled.div`
  input {
    padding: 5px;
  }
`;
const UnitDiv = styled.div`
  margin-top: 30px;
`;
const LeftProductDiv = styled.div`
  margin-top: 20px;
  input {
    padding: 5px;
    width: 100%;
    margin-top: 10px;
  }
`;
const ProductTypeDiv = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  select {
    margin-left: 10px;
  }
`;
const ImageContainer = styled.div`
  width: 50%;
  margin: 20px 0 30px 0;
`;
const FileDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;
const PhotoDiv = styled.div`
  width: 700px;
  margin-right: 20px;
  img {
    width: 100%;
    display: block;
  }
`;
const ImageInput = styled.div`
  align-items: center;
  color: #fff;
  width: 60%;
`;
const VarianQuandityDiv = styled.div`
  margin-top: 20px;
  input {
    padding: 5px;
    width: 100%;
    margin-top: 10px;
  }
`;
const VariantUnitDiv = styled.div`
  margin-top: 20px;
  select {
    padding: 5px;
  }
`;

const ProductCategoryDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const Drop = styled.div`
  display: flex;
  align-items: center;
`;
const SelectInput = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 10px;
  input {
    padding: 5px;
    width: 100%;
  }
`;
const Arrow = styled.div`
  margin-left: 10px;
  width: 1.5%;
  position: absolute;
  left: 95%;
  cursor: pointer;
`;
const ArrowImage = styled.img`
  width: 100%;
  display: block;
  transition: all 0.2s ease-in-out;
  &.active {
    transform: rotate(90deg);
    transition: all 0.2s ease-in-out;
  }
`;
const DropDown = styled.div`
  width: 79.5%;
  margin-left: 18.3%;
  border: 1px solid rgb(133, 133, 133);
  border-radius: 3px;
  display: none;
  overflow-y: scroll;
  transition: all 1s ease-in-out;
  cursor: pointer;
  &.active {
    display: block;
    transition: all 1s ease-in-out;
  }
`;
const CategorySelectConatiner = styled.div`
  margin-left: 30px;
  input {
    margin-top: 5px;
  }
`;
const ProductCategoryContainer = styled.div`
  margin-top: 20px;
`;
const ProductCtegoryTitle = styled.h5``;
const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;
const Button = styled.div`
  background: var(--button-color);
  width: 30%;
  text-align: center;
  padding: 8px;
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
`;
