import React from "react";
import ProductCategoryOfferTable from "./ProductCategoryOfferTable";
import styled from "styled-components";

const ProductCategoryOfferList = ({
  editButtonClickFunction,
  productCategoryOffer,
  setId,
  deleteProductCategoryOffer,
}) => {
  return (
    <Cover>
      <Wrapper>
        <OfferContaimner>
          <ProductCategoryOfferTable />
          {productCategoryOffer.map((item) => (
            <ItemContainer>
              <Item type="name">{item.offer_name}</Item>
              <Item type="name">{item.product_category}</Item>
              <Item type="name">{item.offer_type}</Item>
              <Item type="name">{item.deduction_percentage}</Item>
              <Item type="name">{item.start_date}</Item>
              <Item type="name">{item.end_date}</Item>
              <Item type="name">
                <ActionConatiner>
                  <EditDiv
                    onClick={() => {
                      editButtonClickFunction(true);
                      setId(item.id);
                    }}
                  >
                    <i class="ri-edit-2-fill"></i>
                  </EditDiv>
                  <DeleteDiv
                    onClick={() => {
                      deleteProductCategoryOffer();
                      setId(item.id);
                    }}
                  >
                    <i class="ri-delete-bin-line"></i>
                  </DeleteDiv>
                </ActionConatiner>
              </Item>
            </ItemContainer>
          ))}
        </OfferContaimner>
      </Wrapper>
    </Cover>
  );
};

export default ProductCategoryOfferList;

const Cover = styled.div`
  margin-top: 20px;
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const ItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid black;
  max-width: 100%;
  overflow: scroll;
  @media all and (max-width: 1080px) {
    min-width: 1180px;
  }
`;
const EditDiv = styled.div`
  font-size: 24px;
  cursor: pointer;
`;
const DeleteDiv = styled.div`
  font-size: 24px;
  cursor: pointer;
`;
const ActionConatiner = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const Item = styled.li`
  font-size: 14px;
  font-size: 12px;
  width: ${({ type }) => (type == "slno" ? "8%" : type == "name" ? "12%" : "")};

  @media all and (max-width: 1280px) {
    font-size: 12px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }

  @media all and (max-width: 425px) {
    font-size: 12px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;
const OfferContaimner = styled.div``;
