import React, { useState } from "react";
import MainContainer from "../screens/MainContainer";
import styled from "styled-components";
import { Link } from "react-router-dom";

const AnalyticsModules = ({ isMenu, setMenu }) => {
  return (
    <SubMainContainer>
      <MenuListContainer>
        <SubMenu
          onClick={() => setMenu("SalesUser")}
          className={isMenu === "SalesUser" ? "active" : ""}
          to="/anlytics"
        >
          <IconDiv>
            <i class="ri-bubble-chart-line"></i>
          </IconDiv>
          <IconTitle>User</IconTitle>
        </SubMenu>
      </MenuListContainer>
      <MenuListContainer>
        <SubMenu
          onClick={() => setMenu("SalesProduct")}
          className={isMenu === "SalesProduct" ? "active" : ""}
          to="/product-anlytics"
        >
          <IconDiv>
            <i class="ri-bubble-chart-line"></i>
          </IconDiv>
          <IconTitle>Product</IconTitle>
        </SubMenu>
      </MenuListContainer>
    </SubMainContainer>
  );
};

export default AnalyticsModules;

const SubMainContainer = styled.div``;
const MenuListContainer = styled.ul`
  display: flex;
  flex-direction: column;
`;
const SubMenu = styled(Link)`
  margin-top: 20px;
  color: #000;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  &.active {
    color: blue;
    font-weight: 700;
  }
  @media all and (max-width: 768px) {
    font-size: 15px;
  }
  @media all and (max-width: 375px) {
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 10px;
  }
`;
const IconDiv = styled.div``;
const IconTitle = styled.h6`
  font-size: 12px;
  @media all and (max-width: 980px) {
    font-size: 9px;
  }
  @media all and (max-width: 425px) {
    display: none;
  }
`;
