import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OnBoardTables from "./tables/OnBoardTables";
import styled from "styled-components";
import ProcuringTable from "./tables/ProcuringTable";
import ProcessingTables from "./tables/ProcessingTables";
import QualityCheck from "./tables/QualityCheck";
import PackageTables from "./tables/PackageTables";
import { productConfig, tracebilityConfig } from "../../../axiosConfig";
import { useBearStore } from "../../context/Store";
import Stages from "./reuse/Stages";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2";
import moment from "moment";
import TraceX from "../traceX/modal/TraceX";

const EditTracebility = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const userData = useBearStore((state) => state.userData);

  const [status, setStatus] = useState("OnBoarding");
  const [traceabilityData, setTraceabilityData] = useState([]);

  //Common states
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");

  //Onboard states
  const [geo, setGeo] = useState("");
  const [onBoardImage, setOnboardImage] = useState("");
  const [onboardImageUrl, setOnboardImageUrl] = useState("");

  //Procuring states
  const [quantity, setQuantity] = useState("");
  const [procuring, setProcuringImage] = useState("");
  const [procuringImageUrl, setProcuringImageUrl] = useState("");

  //Processing states
  const [processingImage, setProcessingImage] = useState("");
  const [processingImageUrl, setProcessingImageUrl] = useState("");
  const [output, setOutput] = useState("");

  //QualityCheck states
  const [qualityCheckImage, setQualityCheckImage] = useState("");
  const [qualityCheckImageUrl, setQualityCheckImageUrl] = useState("");
  const [report, setReport] = useState("");

  //Package states
  const [expDate, setExpDate] = useState("");
  const [packingImage, setPackingImage] = useState("");
  const [packingImageUrl, setPackingImageUrl] = useState("");
  const [reportUrl, setReportUrl] = useState("");

  const showSuccessAlert = () => {
    Swal.fire({
      title: "Success!",
      text: "Changes Updated.",
      icon: "success",
      confirmButtonColor: "rgb(9, 175, 244)",
      confirmButtonText: "Done",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/products/");
      }
    });
  };

  const fetchTraceabilityData = () => {
    tracebilityConfig
      .get(`tracebility/${id}/`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
        params: {
          stage: status,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setTraceabilityData(app_data.data);

          setName(app_data.data.name);
          setDate(app_data.data.start_date);
          app_data.stage == "Process"
            ? setDescription(app_data.data.ck_editor_description)
            : setDescription(app_data.data.description);
          setGeo(app_data.data.geo_region);
          setOnboardImage(app_data.data.image1);
          setQuantity(app_data.data.quantity);
          setProcuringImage(app_data.data.image1);
          setProcessingImage(app_data.data.image1);
          setOutput(app_data.data.output_product);
          setQualityCheckImage(app_data.data.image1);
          setReport(app_data.data.file1);
          setExpDate(app_data.data.expiry_date);
          setPackingImage(app_data.data.image1);
        }
      });
  };

  const editOnboard = () => {
    const formData = new FormData();

    if (traceabilityData.name !== name) {
      formData.append("name", name);
    }

    if (traceabilityData.start_date !== date) {
      formData.append("start_date", date);
    }

    if (traceabilityData.description !== description) {
      formData.append("description", description);
    }

    if (traceabilityData.geo_region !== geo) {
      formData.append("geography", geo);
    }

    if (traceabilityData.image1 !== onBoardImage) {
      formData.append("image1", onBoardImage);
    }

    tracebilityConfig
      .post(`edit-onboard-tracebility/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          showSuccessAlert();
        }
      });
  };

  const editProcure = () => {
    const formData = new FormData();

    if (traceabilityData.name !== name) {
      formData.append("name", name);
    }

    if (traceabilityData.start_date !== date) {
      formData.append("start_date", date);
    }

    if (traceabilityData.description !== description) {
      formData.append("description", description);
    }

    if (traceabilityData.quantity !== quantity) {
      formData.append("quantity", quantity);
    }

    if (traceabilityData.image1 !== procuring) {
      formData.append("image1", procuring);
    }

    tracebilityConfig
      .post(`edit-procuring-tracebility/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          showSuccessAlert();
        }
      });
  };

  const editProcess = () => {
    const formData = new FormData();

    if (traceabilityData.name !== name) {
      formData.append("name", name);
    }

    if (traceabilityData.start_date !== date) {
      formData.append("start_date", date);
    }

    if (traceabilityData.ck_editor_description !== description) {
      formData.append("description", description);
    }

    if (traceabilityData.quantity !== quantity) {
      formData.append("quantity", quantity);
    }

    if (traceabilityData.output_product !== output) {
      formData.append("output_product", output);
    }

    if (traceabilityData.image1 !== processingImage) {
      formData.append("image1", processingImage);
    }

    tracebilityConfig
      .post(`edit-processing-tracebility/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          showSuccessAlert();
        }
      });
  };

  const editQualityCheck = () => {
    const formData = new FormData();

    if (traceabilityData.name !== name) {
      formData.append("name", name);
    }

    if (traceabilityData.start_date !== date) {
      formData.append("start_date", date);
    }

    if (traceabilityData.description !== description) {
      formData.append("description", description);
    }

    if (traceabilityData.file1 !== report) {
      formData.append("file1", report);
    }

    if (traceabilityData.image1 !== qualityCheckImage) {
      formData.append("image1", qualityCheckImage);
    }

    tracebilityConfig
      .post(`edit-quality-check-tracebility/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          showSuccessAlert();
        }
      });
  };

  const editPackage = () => {
    const formData = new FormData();

    if (traceabilityData.name !== name) {
      formData.append("name", name);
    }

    if (traceabilityData.start_date !== date) {
      formData.append("start_date", date);
    }

    if (traceabilityData.description !== description) {
      formData.append("description", description);
    }

    if (traceabilityData.expiry_date !== expDate) {
      formData.append("exp_date", expDate);
    }

    if (traceabilityData.image1 !== packingImage) {
      formData.append("image1", packingImage);
    }

    tracebilityConfig
      .post(`edit-package-tracebility/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          showSuccessAlert();
        }
      });
  };

  useEffect(() => {
    fetchTraceabilityData();
  }, [status]);

  return (
    <MainContainer>
      <Stages status={status} setStatus={setStatus} />
      {status == "OnBoarding" ? (
        <OnBoardTables
          name={name}
          date={date}
          description={description}
          geo={geo}
          onBoardImage={onBoardImage}
          setName={setName}
          setDate={setDate}
          setDescription={setDescription}
          setGeo={setGeo}
          setOnboardImage={setOnboardImage}
          type="edit_onboard"
          editOnboard={editOnboard}
          setOnboardImageUrl={setOnboardImageUrl}
          onboardImageUrl={onboardImageUrl}
        />
      ) : status == "Procuring" ? (
        <ProcuringTable
          name={name}
          date={date}
          description={description}
          quantity={quantity}
          procuring={procuring}
          setName={setName}
          setDate={setDate}
          setDescription={setDescription}
          setQuantity={setQuantity}
          setProcuringImage={setProcuringImage}
          setProcuringImageUrl={setProcuringImageUrl}
          procuringImageUrl={procuringImageUrl}
          editProcure={editProcure}
          type="edit_procuring"
        />
      ) : status == "Process" ? (
        <ProcessingTables
          name={name}
          date={date}
          description={description}
          processingImage={processingImage}
          output={output}
          setName={setName}
          setDate={setDate}
          setDescription={setDescription}
          setProcessingImage={setProcessingImage}
          setOutput={setOutput}
          setProcessingImageUrl={setProcessingImageUrl}
          processingImageUrl={processingImageUrl}
          editProcess={editProcess}
          type="edit_process"
        />
      ) : status == "QualityCheck" ? (
        <QualityCheck
          name={name}
          date={date}
          description={description}
          qualityCheckImage={qualityCheckImage}
          report={report}
          setName={setName}
          setDate={setDate}
          setDescription={setDescription}
          setQualityCheckImage={setQualityCheckImage}
          setReport={setReport}
          setQualityCheckImageUrl={setQualityCheckImageUrl}
          setReportUrl={setReportUrl}
          qualityCheckImageUrl={qualityCheckImageUrl}
          editQualityCheck={editQualityCheck}
          type="edit_quality_check"
        />
      ) : status == "Package" ? (
        <PackageTables
          name={name}
          date={date}
          description={description}
          expDate={expDate}
          packingImage={packingImage}
          packingImageUrl={packingImageUrl}
          setName={setName}
          setDate={setDate}
          setDescription={setDescription}
          setExpDate={setExpDate}
          setPackingImage={setPackingImage}
          setPackingImageUrl={setPackingImageUrl}
          editPackage={editPackage}
          type="package"
        />
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default EditTracebility;

const MainContainer = styled.div`
  max-width: 100%;
  height: 90vh;
  overflow: scroll;
  overflow-y: scroll;
`;
const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;
const Button = styled.div`
  background: var(--button-color);
  width: 20%;
  text-align: center;
  padding: 8px;
  margin-top: 20px;
  color: #fff;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
`;
