import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HighGraph from "../../../assets/images/dashboard/Graph-01.png";
import LowGraph from "../../../assets/images/dashboard/Graph-02.png";
import { paymentConfig } from "../../../../axiosConfig";
import { useBearStore } from "../../../context/Store";

const Card = () => {
  const userData = useBearStore((state) => state.userData);
  const [data, setData] = useState([]);

  const fetchMonthSale = () => {
    paymentConfig
      .get("top-least-sale-month/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setData(app_data);
        }
      });
  };

  useEffect(() => {
    fetchMonthSale();
  }, []);

  return (
    <MainContainer>
      <Container className="green">
        <TopContainer>
          <Title>Top sale month</Title>
        </TopContainer>
        <BottomContainer>
          <GraphContainer>
            <img src={HighGraph} alt="high-graph" />
          </GraphContainer>
          <ContentDiv>
            <TopSaleMonth>
              {data.top_sale_month}, {data.top_sale_year}
            </TopSaleMonth>
          </ContentDiv>
        </BottomContainer>
      </Container>
      <Container>
        <TopContainer>
          <Title>Least sale month</Title>
        </TopContainer>
        <BottomContainer>
          <GraphContainer>
            <img src={LowGraph} alt="high-graph" />
          </GraphContainer>
          <ContentDiv>
            <TopSaleMonth>
              {data.least_sale_month}, {data.least_sale_year}
            </TopSaleMonth>
          </ContentDiv>
        </BottomContainer>
      </Container>
    </MainContainer>
  );
};

export default Card;

const TopContainer = styled.div`
  margin-top: 10px;
`;
const Title = styled.h3`
  font-size: 12px;
  text-align: center;
`;
const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 980px) {
    flex-wrap: wrap;
  }
`;
const Container = styled.div`
  width: 45%;
  border-radius: 10px;
  overflow-x: scroll;
  margin-top: 10px;
  border: 1px solid #f96767;
  padding: 3px;
  @media all and (max-width: 980px) {
    width: 100%;
  }
  &.green {
    border: 1px solid #a6e27f;
  }
`;

const GraphContainer = styled.div`
  width: 20%;
  img {
    width: 100%;
    display: block;
  }
`;
const ContentDiv = styled.div``;
const TopSaleMonth = styled.h4``;
const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  gap: 10px;
`;
