import React from "react";
import styled from "styled-components";

const Cover = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Cover;
const Container = styled.div`
  width: 100%;
  height: 90vh;
  overflow-x: auto;
  overflow-y: auto;
`;
