import React from 'react'
import { useBearStore } from '../../context/Store'
import { Navigate, Outlet } from 'react-router-dom'

const AuthRoute = () => {
    const userData = useBearStore((state) => state.userData)
  return (
    userData.isVerified ? <Outlet /> : <Navigate to="/auth/login" />
  )
}

export default AuthRoute