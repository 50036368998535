import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import TopContainer from "../home/TopContainer";
import { paymentConfig, productConfig } from "../../../axiosConfig";
import { useParams } from "react-router-dom";
import { useBearStore } from "../../context/Store";
import styled from "styled-components";
import DP from "../../assets/images/annalitics/user.png";

const ProductAnalyticsData = () => {
  const userData = useBearStore((state) => state.userData);
  const [saleCount, setSaleCount] = useState({});
  const [saleQuandity, setSaleQuandity] = useState();
  const [productImage, setProductImage] = useState();
  const [orderUser, setOrderUser] = useState([]);

  const { id } = useParams();

  const Data = [
    {
      name: "Shiyas",
      dp: DP,
    },
    {
      name: "Shyam",
      dp: DP,
    },
    {
      name: "Wazeer",
      dp: DP,
    },
    {
      name: "Shaneeb",
      dp: DP,
    },
    {
      name: "Ajmal",
      dp: DP,
    },
    {
      name: "Ahammad",
      dp: DP,
    },
    {
      name: "Moosa",
      dp: DP,
    },
    {
      name: "Abilash",
      dp: DP,
    },
    {
      name: "Nasim",
      dp: DP,
    },
    {
      name: "Rajeev",
      dp: DP,
    },
    {
      name: "Shibin",
      dp: DP,
    },
    {
      name: "Arjun",
      dp: DP,
    },
    {
      name: "Prajin",
      dp: DP,
    },
    {
      name: "Rajesh",
      dp: DP,
    },
    {
      name: "Anoop",
      dp: DP,
    },
    {
      name: "Emanual",
      dp: DP,
    },
    {
      name: "Moosa",
      dp: DP,
    },
    {
      name: "Abilash",
      dp: DP,
    },
    {
      name: "Nasim",
      dp: DP,
    },
    {
      name: "Rajeev",
      dp: DP,
    },
    {
      name: "Shibin",
      dp: DP,
    },
    {
      name: "Arjun",
      dp: DP,
    },
    {
      name: "Prajin",
      dp: DP,
    },
    {
      name: "Rajesh",
      dp: DP,
    },
    {
      name: "Anoop",
      dp: DP,
    },
    {
      name: "Emanual",
      dp: DP,
    },
  ];

  const fetchIndivitualProductSale = () => {
    productConfig
      .get(`indivitual-sale/${id}/`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setSaleCount(app_data);
          //   setSaleQuandity(app_data.data);
        }
      });
  };

  const fetchIndivitualProduct = () => {
    productConfig
      .get(`product/${id}/`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setProductImage(app_data.data.photo_1);
        }
      });
  };

  const fetchOrderUser = () => {
    paymentConfig
      .get(`product-order-user/${id}`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setOrderUser(app_data.data.profile);
        }
      });
  };

  useEffect(() => {
    fetchIndivitualProductSale();
    fetchIndivitualProduct();
    fetchOrderUser();
  }, []);

  return (
    <Cover>
      <TopContainer
        countData={saleCount}
        type="product_sale"
        title="Total sale by quandity"
        title2="Total sale by amount"
        title3="Total Orders"
      />
      <MainContainer>
        <LeftContainer>
          <img src={productImage} alt="product-image" />
        </LeftContainer>
        <RightContainer>
          <UsersContainer>
            <UserContainerTitle>Users</UserContainerTitle>
            <UsersDiv>
              {orderUser?.map((item) => (
                <UsersList>
                  <UserProfile>
                    <img src={DP} alt="profile" />
                  </UserProfile>
                  <UserName>{item}</UserName>
                </UsersList>
              ))}
            </UsersDiv>
          </UsersContainer>
        </RightContainer>
      </MainContainer>
    </Cover>
  );
};

export default ProductAnalyticsData;

const MainContainer = styled.div`
  width: 90%;
  margin: 30px auto;
  display: flex;
  gap: 10px;
  @media all and (max-width: 980px) {
    flex-wrap: wrap;
  }
`;
const LeftContainer = styled.div`
  width: 54%;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const RightContainer = styled.div`
  width: 45%;
  height: 550px;
  overflow: scroll;
  @media all and (max-width: 1280px) {
    height: 480px;
  }
  @media all and (max-width: 1080px) {
    height: 408px;
  }
  @media all and (max-width: 980px) {
    width: 100%;
    margin-top: 30px;
  }
`;
const UsersContainer = styled.div``;
const UserContainerTitle = styled.h2`
  text-align: center;
`;
const UsersList = styled.div`
  width: 24.3%;
  margin-top: 23px;
`;
const UserProfile = styled.div`
  width: 50%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
`;
const UserName = styled.span`
  margin-top: 5px;
  display: block;
  font-size: 12px;
  text-align: center;
`;
const UsersDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
`;
