import React, { useState } from "react";
import styled from "styled-components";
import "../assets/css/style.css";
import { NavLink } from "react-router-dom";
import {
  useAppStore,
  useBearStore,
  useUnreadOrderCountStore,
} from "../context/Store";
import AnalyticsModules from "./AnalyticsModules";
import OfferModules from "./OfferModules";

const Sidebar = ({ active, setActive, unreadCount }) => {
  const [isMenu, setMenu] = useState("");
  const [dropDown, setDropDown] = useState(false);
  const [offerDropDown, setOfferDropDown] = useState(false);
  const sidebar = useAppStore((state) => state.isSideBar);

  const logoutFunction = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <MainContainer sidebar={sidebar}>
      <SubContainer>
        <ListContainer>
          <MenuList
            onClick={() => {
              setMenu("Dashboard");
              setDropDown(false);
            }}
            className={isMenu === "Dashboard" ? "active" : ""}
            to="/"
          >
            <LogoDiv>
              <i class="ri-dashboard-line"></i>{" "}
            </LogoDiv>
            <LogoTitle>Dashboard</LogoTitle>
          </MenuList>
          <MenuList
            onClick={() => {
              setMenu("Users");
              setDropDown(false);
            }}
            className={isMenu === "Users" ? "active" : ""}
            to="/users"
          >
            <LogoDiv>
              <i class="ri-user-fill"></i>
            </LogoDiv>
            <LogoTitle>Users</LogoTitle>
          </MenuList>
          <MenuList
            onClick={() => {
              setMenu("Orders");
              setDropDown(false);
            }}
            className={isMenu === "Orders" ? "active" : ""}
            to="/orders"
          >
            <LogoDiv>
              <i class="ri-article-fill"></i>
            </LogoDiv>
            <LogoTitle>Orders</LogoTitle>
            <UnreadCircleDiv>
              <span>{unreadCount}</span>
            </UnreadCircleDiv>
          </MenuList>
          <MenuList
            onClick={() => {
              setMenu("Products");
              setDropDown(false);
            }}
            className={isMenu === "Products" ? "active" : ""}
            to="/products"
          >
            <LogoDiv>
              <i class="ri-shopping-cart-fill"></i>
            </LogoDiv>
            <LogoTitle>Product</LogoTitle>
          </MenuList>
          <MenuList
            onClick={() => {
              setMenu("ProductCategory");
              setDropDown(false);
            }}
            className={isMenu === "ProductCategory" ? "active" : ""}
            to="/products-category"
          >
            <LogoDiv>
              <i class="ri-shopping-cart-line"></i>
            </LogoDiv>
            <LogoTitle>Product Category</LogoTitle>
          </MenuList>
          <MenuList
            onClick={() => {
              setMenu("TraceX");
              setDropDown(false);
            }}
            className={isMenu === "TraceX" ? "active" : ""}
            to="/tracex"
          >
            <LogoDiv>
              <i class="ri-map-pin-2-line"></i>
            </LogoDiv>
            <LogoTitle>TraceX Links</LogoTitle>
          </MenuList>
          <MenuList
            onClick={() => {
              setMenu("Banners");
              setDropDown(false);
            }}
            className={isMenu === "Banners" ? "active" : ""}
            to="/banner"
          >
            <LogoDiv>
              <i class="ri-gallery-line"></i>
            </LogoDiv>
            <LogoTitle>Banner</LogoTitle>
          </MenuList>
          <MenuList
            onClick={() => {
              setMenu("FeaturedCollections");
              setDropDown(false);
            }}
            className={isMenu === "FeaturedCollections" ? "active" : ""}
            to="/featured-collection"
          >
            <LogoDiv>
              <i class="ri-video-line"></i>
            </LogoDiv>
            <LogoTitle>Featured Collection</LogoTitle>
          </MenuList>
          <MenuList
            onClick={() => {
              setMenu("Blogs");
              setDropDown(false);
            }}
            className={isMenu === "Blogs" ? "active" : ""}
            to="/blogs"
          >
            <LogoDiv>
              <i class="ri-pen-nib-fill"></i>
            </LogoDiv>
            <LogoTitle>Blogs</LogoTitle>
          </MenuList>
          <MenuList
            onClick={() => {
              setMenu("OfferBanner");
              setDropDown(false);
            }}
            className={isMenu === "OfferBanner" ? "active" : ""}
            to="/offer-banner"
          >
            <LogoDiv>
              <i class="ri-flag-2-fill"></i>
            </LogoDiv>
            <LogoTitle>Offer Banner</LogoTitle>
          </MenuList>
          <OfferList
            onClick={() => {
              setOfferDropDown(!offerDropDown);
              setDropDown(false);
            }}
            className={offerDropDown ? "active" : ""}
          >
            <LogoDiv>
              <i class="ri-gift-fill"></i>
            </LogoDiv>
            <LogoTitle>Offers</LogoTitle>
            <OfferArrow type={offerDropDown}>
              <i class="ri-arrow-drop-right-line rotate"></i>
            </OfferArrow>
          </OfferList>
          {offerDropDown && (
            <SubContainer>
              <OfferModules isMenu={isMenu} setMenu={setMenu} />
            </SubContainer>
          )}
          <AnnaylitcList
            onClick={() => {
              setDropDown(!dropDown);
              setMenu("");
            }}
            className={dropDown ? "active" : ""}
          >
            <LogoDiv>
              <i class="ri-folder-chart-fill"></i>
            </LogoDiv>
            <LogoTitle>Analytics</LogoTitle>
            <Arrow type={dropDown}>
              <i class="ri-arrow-drop-right-line rotate"></i>
            </Arrow>
          </AnnaylitcList>
          {dropDown && (
            <SubContainer>
              <AnalyticsModules isMenu={isMenu} setMenu={setMenu} />
            </SubContainer>
          )}
        </ListContainer>
      </SubContainer>
      <LogoutContainer onClick={logoutFunction}>
        <LogoDiv>
          <i class="ri-logout-box-r-fill"></i>
        </LogoDiv>
        <LogoutTitle>Logout</LogoutTitle>
      </LogoutContainer>
    </MainContainer>
  );
};

export default Sidebar;

const MainContainer = styled.div`
  width: 250px;
  width: ${({ sidebar }) => (sidebar ? "250px" : "0px")};
  height: 90vh;
  background-color: #f9f9f9;
  transition: 0.75s all ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.active {
    left: 0px;
    transition: 0.75s all ease;
  }
  @media all and (max-width: 1080px) {
    width: 200px;
  }
  @media all and (max-width: 768px) {
    width: 160px;
  }
  @media all and (max-width: 640px) {
    width: 150px;
  }
  @media all and (max-width: 425px) {
    width: 76px;
  }
  @media all and (max-width: 375px) {
    width: 70px;
  }
`;
const OfferArrow = styled.div`
  transform: ${({ type }) => type && "rotate(90deg)"};
`;
const Arrow = styled.div`
  transform: ${({ type }) => type && "rotate(90deg)"};
`;
const SubContainer = styled.div`
  width: 100%;
  margin-left: 10px;
  transition: all 1s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  min-width: 1180px;
`;
const MenuList = styled(NavLink)`
  margin-top: 20px;
  height: 30px;
  width: 100%;
  color: #000;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  &.active {
    color: #d2a2f9;
    font-weight: 700;
  }
  i {
    &.ri-arrow-drop-right-line {
    }
  }
  @media all and (max-width: 768px) {
    font-size: 15px;
  }
  @media all and (max-width: 375px) {
    font-size: 15px;
  }
`;
const OfferList = styled.div`
  margin-top: 20px;
  height: 30px;
  width: 100%;
  color: #000;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  &.active {
    color: #d2a2f9;
    font-weight: 700;
  }
`;
const AnnaylitcList = styled.div`
  margin-top: 20px;
  height: 30px;
  width: 100%;
  color: #000;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  &.active {
    color: #d2a2f9;
    font-weight: 700;
  }
  i {
    &.ri-arrow-drop-right-line {
    }
  }
  @media all and (max-width: 768px) {
    font-size: 15px;
  }
  @media all and (max-width: 375px) {
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 10px;
  }
`;
const LogoutContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 10px;
`;
const LogoutTitle = styled.h3`
  font-size: 15px;

  @media all and (max-width: 425px) {
    display: none;
  }
`;
const UnreadCircleDiv = styled.div`
  width: 30px;
  height: 30px;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
`;
const LogoDiv = styled.div``;
const LogoTitle = styled.h3`
  font-size: 15px;
  @media all and (max-width: 425px) {
    display: none;
  }
`;
