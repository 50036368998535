import React from 'react'
import styled from 'styled-components';
import Plus from "../../assets/images/product/plus_icon.svg";


const Banner = ({bannerModal, setBannerModal}) => {
  return (
    <div>
        <Wrapper>
        <Container>
          <TopContainer>
            <Heading>Banner</Heading>
          </TopContainer>
          <TotalDiv>
            <Button onClick={() => setBannerModal(true)}>
              <ImageDiv>
                <img src={Plus} alt="Add" />
              </ImageDiv>
              <Span>Add Banner</Span>
            </Button>
          </TotalDiv>
        </Container>
      </Wrapper>
    </div>
  )
}

export default Banner;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 20px;
`;
const TopContainer = styled.div`
  width: 30%;
`;
const Heading = styled.p`
  font-size: 18px;
`;
const TotalDiv = styled.div`
  display: flex;
  gap: 20px;

`;
const Button = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 5px;
  background: var(--button-color);
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  width: 150px;
  font-size: 12px;

  cursor: pointer;
  &.product-category{
    width: 200px;
    /* font-size: 12px; */
  }
`;
const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  padding: 10px 0px;
  img {
    display: block;
    width: 20px;
  }
`;
const Span = styled.span``;