import React from "react";
import Plus from "../../assets/images/product/plus_icon.svg";
import styled from "styled-components";
import ModalForm from "./modal/ModalForm";

const BlogTopContainer = ({
  setAddBlogModal,
  addBlogModal,
  setTitle,
  setDescription,
  setAuthor,
  setImage,
  setKeywords,
  setMetaTitle,
  setSlug,
  setImageUrl,
  setMetaDescription,
  addBlog,
  setThumbnail,
  setThumbnailUrl,
}) => {
  return (
    <MainContainer>
      <ButtonContainer onClick={() => setAddBlogModal(true)}>
        <Button>
          <ImageDiv>
            <img src={Plus} alt="Add" />
          </ImageDiv>
          <ButtonTitle>Add Blog</ButtonTitle>
        </Button>
      </ButtonContainer>
      <ModalForm
        setModal={setAddBlogModal}
        modal={addBlogModal}
        setTitle={setTitle}
        setDescription={setDescription}
        setAuthor={setAuthor}
        setImage={setImage}
        setKeywords={setKeywords}
        setMetaDescription={setMetaDescription}
        setMetaTitle={setMetaTitle}
        setSlug={setSlug}
        setImageUrl={setImageUrl}
        setThumbnail={setThumbnail}
        setThumbnailUrl={setThumbnailUrl}
        addBlog={addBlog}
      />
    </MainContainer>
  );
};

export default BlogTopContainer;

const MainContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  width: 15%;
  background-color: var(--button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  margin-right: 20px;
  cursor: pointer;
  @media all and (max-width: 1080px) {
    width: 20%;
  }
  @media all and (max-width: 768px) {
    width: 30%;
  }
  @media all and (max-width: 768px) {
    width: 35%;
  }
  @media all and (max-width: 480px) {
    width: 50%;
  }
  @media all and (max-width: 480px) {
    width: 70%;
  }
`;
const Button = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
`;
const ImageDiv = styled.div`
  width: 15%;
  img {
    width: 100%;
    display: block;
  }
`;
const ButtonTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #fff;
`;
