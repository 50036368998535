import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import styled from "styled-components";

import Tick from "../../assets/images/orders/tick.svg";
import Product from "../../assets/images/product/foxtail_milet_dosa-2.jpg";
import {
  Link,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useBearStore } from "../../context/Store";
import { accountsConfig } from "../../../axiosConfig";
import TopContainer from "../home/TopContainer";

const UserView = () => {
  const userData = useBearStore((state) => state.userData);
  const [data, setData] = useState([]);
  const [orderId, setOrderId] = useState();
  const { id } = useParams();

  useEffect(() => {
    accountsConfig
      .get(`user/${id}/`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setData(app_data.data.data);
        }
      });
  }, [id]);

  return (
    <>
      <Cover>
        <Wrapper>
          <ContentConatiner>
            <TopNameContainer>
              <OrderIdContaner>
                <OrderId>{data.name}</OrderId>
                <Date>
                  {data.state},{data.district}, {data.country}
                </Date>
              </OrderIdContaner>
            </TopNameContainer>
            <TilesContainer>
              <TopContainer
                countData={data}
                title="Total Sales Count"
                title2="Total Order Recived"
              />
            </TilesContainer>
            <OrderContainer>
              <OrderIdTitle>Orders</OrderIdTitle>
              {data.order?.map((order_data) => (
                <OrderBottomContainer to={`/orders/${order_data.id}/`}>
                  <ProductOrderId>
                    <OrderTitle>{order_data.order_id}</OrderTitle>
                  </ProductOrderId>
                  <ProductContainer>
                    <ProductImageDiv>
                      <img src={Product} alt="Product" />
                    </ProductImageDiv>
                    <ProductNameContainer>
                      <ProductName>{order_data.products}</ProductName>
                      <ProductSize>{order_data.size}</ProductSize>
                    </ProductNameContainer>
                  </ProductContainer>
                  <PriceContainer>
                    <PriceQuandity>
                      {order_data.total_amount} x {order_data.quantity}
                    </PriceQuandity>
                    <Price>
                      {order_data.total_amount * order_data.quantity}
                    </Price>
                  </PriceContainer>
                </OrderBottomContainer>
              ))}
            </OrderContainer>
          </ContentConatiner>
        </Wrapper>
      </Cover>
    </>
  );
};

export default UserView;

const Wrapper = styled.div`
  padding-top: 50px;
  width: 90%;
  margin: 0 auto;
`;
const ContentConatiner = styled.div``;
const TopNameContainer = styled.div`
  width: 100%;
`;
const OrderIdContaner = styled.div``;
const OrderId = styled.h2`
  font-size: 34px;
  @media all and (max-width: 320px) {
    font-size: 25px;
  }
`;
const Date = styled.h6`
  font-size: 10px;
  color: grey;
`;
const OrderContainer = styled.div`
  margin-top: 40px;
  overflow: scroll;
`;
const OrderTopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #000;
`;
const OrderTitle = styled.h4``;
const OrderBottomContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding-bottom: 20px;
  border-bottom: 1px solid #000;
  cursor: pointer;
  @media all and (max-width: 980px) {
    min-width: 1180px;
  }
`;
const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
const ProductImageDiv = styled.div`
  width: 7%;
  img {
    width: 100%;
    display: block;
  }
`;
const ProductNameContainer = styled.div`
  margin-left: 10px;
`;
const ProductName = styled.h4``;
const ProductSize = styled.h4``;
const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 35%;
`;
const PriceQuandity = styled.h5``;
const Price = styled.h5``;
const ProductOrderId = styled.div`
  width: 30%;
`;
const OrderIdTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 30px;
`;
const TilesContainer = styled.div`
  margin-top: 20px;
`;
