import React from "react";
import styled from "styled-components";

import NotFound from "../../assets/images/no_data/no_data.jpg";

const NoData = () => {
  return (
    <ImageBox>
      <CardImage src={NotFound} alt="Image" />
      <p>No data found</p>
    </ImageBox>
  );
};

export default NoData;

const ImageBox = styled.div`
  width: 40%;
  margin: 50px auto;
  @media all and (max-width: 980px) {
    width: 200px;
  }
  p {
    color: #fff;
    font-size: 18px;
    font-family: "gordita_medium";
    text-align: center;
    margin-top: 15px;
  }
`;
const CardImage = styled.img`
  width: 100%;
  display: block;
`;
