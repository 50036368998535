import React, { useEffect, useState } from "react";
import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import { Outlet } from "react-router";
import styled from "styled-components";
import Circle from "../../assets/images/dashboard/circle.png";
import GraphicalRepresentation from "./GraphicalRepresentation";
import TopContainer from "./TopContainer";
import { paymentConfig, productConfig } from "../../../axiosConfig";
import { useBearStore } from "../../context/Store";
import moment from "moment";

const Dashboard = () => {
  const userData = useBearStore((state) => state.userData);
  const [countData, setCountData] = useState([]);
  const [product, setProduct] = useState([]);
  const [filtercountData, setFilterCountData] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState([]);
  const [filterEndDate, setFilterEndDate] = useState("");
  const [userCount, setUserCount] = useState("");

  const formatedendfilterstartdate =
    moment(filterStartDate).format("YYYY-MM-DD");
  const formatedendfilterenddate = moment(filterEndDate).format("YYYY-MM-DD");

  const handleDatefiltration = () => {
    const formData = new FormData();
    filterStartDate &&
      filterEndDate &&
      formData.append("from_date", formatedendfilterstartdate);
    formData.append("to_date", formatedendfilterenddate);
    paymentConfig
      .post("total-payments-date/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data, data } = response.data;
        if (app_data.StatusCode === 6000) {
          setFilterCountData(app_data.data);
        } else {
        }
      });
  };

  useEffect(() => {
    const fetchCountData = () => {
      handleDatefiltration();
      paymentConfig
        .post("total_payments/", {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          const { app_data, data } = response.data;
          if (app_data.StatusCode === 6000) {
            setCountData(app_data.data);
          } else {
          }
        })
        .catch((error) => {});
    };
    fetchCountData();
    fetchUserCount();
    fetchBestSellerData();
  }, [filterEndDate]);

  const fetchBestSellerData = () => {
    const formData = new FormData();
    formData.append("from_date", formatedendfilterstartdate);
    formData.append("to_date", formatedendfilterenddate);

    filterStartDate &&
      filterEndDate &&
      productConfig
        .post("best-sell-products-count/", formData, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            setProduct(app_data.data);
          }
        });
  };

  const fetchUserCount = () => {
    paymentConfig
      .get("user-count/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setUserCount(app_data.data.users_count);
        } else {
          setUserCount(app_data.data.users_count);
        }
      });
  };

  return (
    <Cover>
      <Wrapper>
        <MainContainer>
          <TopContainer
            countData={countData}
            title="Today's Payment Received"
            title2="Today's Order Received"
            type="student_count"
            userCount={userCount}
          />
          <DownContainer>
            <GrapContainer>
              <GraphicalRepresentation
                countData={countData}
                setCountData={setCountData}
                setFilterStartDate={setFilterStartDate}
                filterStartDate={filterStartDate}
                setFilterEndDate={setFilterEndDate}
                filterEndDate={filterEndDate}
                filtercountData={filtercountData}
                setFilterCountData={setFilterCountData}
                product={product}
              />
            </GrapContainer>
          </DownContainer>
        </MainContainer>
      </Wrapper>
    </Cover>
  );
};

export default Dashboard;
const Cover = styled.div`
  width: 100%;
  height: 90vh;
  z-index: 0;
  overflow-y: scroll;
  overflow-x: scroll;
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const MainContainer = styled.div`
  width: 100%;
`;

const DownContainer = styled.div``;

const Bottom = styled.div`
  width: 100%;
`;

const GrapContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
