import React, { useEffect } from "react";
import ProductCategoryNav from "./ProductCategoryNav";
import styled from "styled-components";
import { useCategoryIdStore } from "../../context/Store";

const ProductCategoryList = ({
  isCategory,
  setEditModal,
  categoryId,
  fetchSingleProductCategory,
}) => {
  const setCategoryId = useCategoryIdStore((state) => state.setCategoryId);

  useEffect(() => {
    categoryId && fetchSingleProductCategory();
  }, [categoryId]);
  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <MainTitle>Product Category</MainTitle>
        </TitleContainer>
        <ProductCategoryNav />
        {isCategory?.map((item) => (
          <ItemContainer key={item.id}>
            <ItemList type="name">{item.category_name}</ItemList>
            <ItemList type="name">
              <ImageDiv>
                <img src={item.image} alt="category-image" />
              </ImageDiv>
            </ItemList>
            <ItemList type="name">{item.order_id}</ItemList>
            <ItemList
              type="name"
              className="status"
              onClick={() => {
                setCategoryId(item.id);
              }}
            >
              <EditContainer
                onClick={() => {
                  setEditModal(true);
                }}
              >
                <i className="ri-edit-box-fill"></i>
              </EditContainer>
            </ItemList>
          </ItemContainer>
        ))}
      </Container>
    </Wrapper>
  );
};

export default ProductCategoryList;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Container = styled.div`
  max-width: 100%;
  overflow: scroll;
`;
const TitleContainer = styled.div``;
const MainTitle = styled.h2`
  margin: 30px 50px;
  @media all and (max-width: 980px) {
    font-size: 15px;
    margin: 30px 10px;
  }
`;
const ItemContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
  padding-top: 30px;
  height: 50px;
  @media all and (max-width: 980px) {
    min-width: 1180px;
  }
`;
const ItemList = styled.li`
  text-align: center;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
  font-size: 14px;
  margin-left: 30px;
  img {
    display: block;
    width: 100%;
  }
  &.status {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  &.slno {
    display: flex;
    justify-content: flex-end;
  }
`;
const EditContainer = styled.div`
  cursor: pointer;
`;
const DeleteContainer = styled.div`
  cursor: pointer;

  i {
    color: red;
  }
`;
const ImageDiv = styled.div`
  width: 50%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
`;
