import React, { useEffect, useState } from "react";
import Cover from "../../home/reuse/Cover";
import { OfferTopConatiner } from "../reuse/OfferTopConatiner";
import CartOfferList from "./CartOfferList";
import AddProductOfferModal from "../product-offer/modal/AddProductOfferModal";
import EditProductOfferModal from "../product-offer/modal/EditProductOfferModal";
import { useBearStore, useOfferIdStore } from "../../../context/Store";
import { productConfig } from "../../../../axiosConfig";
import styled from "styled-components";

const CartOfferScreen = () => {
  const userData = useBearStore((state) => state.userData);
  const setOfferId = useOfferIdStore((state) => state.setOfferId);
  const Id = useOfferIdStore((state) => state.offerId);

  const [cartOfferModal, setCartOfferModal] = useState(false);
  const [editCartOffer, setEditCartOffer] = useState(false);

  const [cartOfferData, setCartOfferData] = useState([]);
  const [indivitualOffer, setIndivitualOffer] = useState([]);

  // Modal form states
  const [offerName, setOfferName] = useState();
  const [minimumAmount, setMinimumAmount] = useState();
  const [deductionPercentage, setDeductionPercentage] = useState();
  const [startDate, SetStartDate] = useState();
  const [endDate, setEndDate] = useState();

  console.log(minimumAmount, "-=-=-=-=-=setMinimumAmount");

  const fetchCartOffer = () => {
    productConfig
      .get("cart-offers/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setCartOfferData(app_data.data);
        }
      });
  };

  const fetchIndivitualProductOffer = () => {
    Id &&
      productConfig
        .get(`indivitual-offer/${Id}`, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            setIndivitualOffer(app_data.data);
            setOfferName(app_data.data.offer_name);
            setMinimumAmount(app_data.data.min_purchase_amount);
            setDeductionPercentage(app_data.data.deduction_percentage);
            SetStartDate(app_data.data.start_date);
            setEndDate(app_data.data.end_date);
          }
        });
  };

  const handleEditCartOffer = () => {
    const formData = new FormData();

    if (indivitualOffer.offer_name !== offerName) {
      formData.append("offer_name", offerName);
    }

    if (indivitualOffer.min_purchase_amount !== minimumAmount) {
      formData.append("min_purchase_amount", minimumAmount);
    }

    if (indivitualOffer.deduction_percentage !== deductionPercentage) {
      formData.append("deduction_percentage", deductionPercentage);
    }

    if (indivitualOffer.start_date !== startDate) {
      formData.append("start_date", startDate);
    }

    if (indivitualOffer.end_date !== endDate) {
      formData.append("end_date", endDate);
    }

    productConfig
      .post(`edit-cart-offer/${Id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          fetchCartOffer();
          setEditCartOffer(false);
        }
      });
  };

  const addCartOffer = () => {
    const formData = new FormData();

    formData.append("offer_name", offerName);
    formData.append("min_purchase_amount", minimumAmount);
    formData.append("deduction_percentage", deductionPercentage);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    productConfig
      .post("add-cart-offer/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setCartOfferModal(false);
          fetchCartOffer();
        }
      });
  };

  const deleteProductOffer = () => {
    productConfig
      .post(
        `delete-product-offer/${Id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        }
      )
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          fetchCartOffer();
        }
      });
  };
  useEffect(() => {
    fetchCartOffer();
  }, []);

  useEffect(() => {
    Id === Id && fetchIndivitualProductOffer();
  }, [editCartOffer]);

  return (
    <Cover>
      <MainTitle>Cart Offer</MainTitle>
      <OfferTopConatiner buttonClickFunction={setCartOfferModal} />
      <CartOfferList
        editButtonClickFunction={setEditCartOffer}
        cartOfferData={cartOfferData}
        setId={setOfferId}
        deleteProductOffer={deleteProductOffer}
      />
      <AddProductOfferModal
        activeModal={cartOfferModal}
        onclickFunction={setCartOfferModal}
        labelValue="Minimum purchase amount"
        setOfferName={setOfferName}
        setObjectType={setMinimumAmount}
        setDeductionPercentage={setDeductionPercentage}
        SetStartDate={SetStartDate}
        setEndDate={setEndDate}
        handleSubmit={addCartOffer}
      />
      <EditProductOfferModal
        editActiveModal={editCartOffer}
        onclickFunction={setEditCartOffer}
        labelValue="Minimum purchase amount"
        offerName={offerName}
        objectType={minimumAmount}
        deductionPercentage={deductionPercentage}
        startDate={startDate}
        endDate={endDate}
        offername={offerName}
        setOfferName={setOfferName}
        setObjectType={setMinimumAmount}
        setDeductionPercentage={setDeductionPercentage}
        SetStartDate={SetStartDate}
        setEndDate={setEndDate}
        // handleSubmit={EditProductCategoryOffer}
        handleSubmit={handleEditCartOffer}
      />
    </Cover>
  );
};

export default CartOfferScreen;

const MainTitle = styled.h2`
  text-align: center;
  margin-top: 15px;
  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;
