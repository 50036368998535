import React, { useState } from "react";
import styled from "styled-components";
import DougnutChartGraph from "./DougnutChartGraph";

const GraphicalRepresentation = ({
  countData,
  setCountData,
  filterStartDate,
  setFilterStartDate,
  filterEndDate,
  setFilterEndDate,
  filtercountData,
  setFilterCountData,
  product,
}) => {
  return (
    <Cover>
      <Left>
        <FilterContainer>
          <FilterIcon>
            <Input
              type="date"
              onChange={(e) => setFilterStartDate(e.target.value)}
            />
            <img
              src={require("../../assets/images/dashboard/mainicon/Close.svg")}
              alt="Search"
            />
          </FilterIcon>
          <LimitFilterIcon>
            <Input
              type="date"
              onChange={(e) => setFilterEndDate(e.target.value)}
            />
          </LimitFilterIcon>
        </FilterContainer>

        <DougnutChartGraph
          filtercountData={filtercountData}
          setFilterCountData={setFilterCountData}
        />
      </Left>
      <Right>
        <FilterCardsContainer>
          <FilterCard>
            <MainTitle>Payment</MainTitle>
            <Count>
              {filtercountData.length == 0
                ? "0"
                : filtercountData.total_payments}{" "}
              K
            </Count>
          </FilterCard>
          <FilterCard>
            <MainTitle>Order</MainTitle>
            <Count>
              {filtercountData.length == 0 ? "0" : filtercountData.total_order}
            </Count>
          </FilterCard>
        </FilterCardsContainer>
        <BestSellerCard className="best-seller-card">
          <ProductTitle>Best Seller Products</ProductTitle>
          {product.length > 0 ? (
            product.map((item) => (
              <ProductContainer>
                <Products>{item.product}</Products>
              </ProductContainer>
            ))
          ) : (
            <ProductContainer>
              <SelectDate>Select Date</SelectDate>
            </ProductContainer>
          )}
        </BestSellerCard>
      </Right>
    </Cover>
  );
};

export default GraphicalRepresentation;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

const FilterIcon = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  height: 50px;

  img {
    display: none;
    width: 100%;
    margin-left: 10px;
    cursor: pointer;
  }
  @media all and (max-width: 480px) {
    width: 50%;
  }
`;

const Input = styled.input`
  border: 1px solid #26272a;
  border-radius: 5px !important;
  width: 100%;
  padding: 10px;
  color: #000;
  height: 20px;
  resize: none;

  @media all and (max-width: 480px) {
    padding: 15px 0px 15px 10px;
  }
`;

const LimitFilterIcon = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  height: 50px;
  margin-left: 10px;

  @media all and (max-width: 480px) {
    width: 50%;
  }
`;

const Cover = styled.div`
  padding-top: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media all and (max-width: 980px) {
    flex-wrap: wrap;
  }
`;

const FilterCardsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const FilterCard = styled.div`
  width: 40%;
  height: 200px;
  margin: 10px 20px;
  background: rgb(244, 172, 137);
  background: linear-gradient(
    356deg,
    rgba(244, 172, 137, 1) 28%,
    rgba(244, 153, 223, 1) 76%
  );
  border-radius: 10px;
  overflow-x: scroll;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;

const BestSellerCard = styled.div`
  width: 88%;
  height: 190px;
  margin: 05px 20px;
  background: rgb(244, 172, 137);
  background: linear-gradient(
    356deg,
    rgba(244, 172, 137, 1) 28%,
    rgba(244, 153, 223, 1) 76%
  );
  border-radius: 10px;
  overflow-y: scroll;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
  @media all and (max-width: 980px) {
    width: 94%;
    margin: 0 auto;
  }
  @media all and (max-width: 980px) {
    width: 92%;
  }
  @media all and (max-width: 425px) {
    width: 89%;
  }
`;

const Right = styled.div`
  width: 49%;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const Left = styled.div`
  width: 49%;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;

const MainTitle = styled.div`
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin-top: 60px;
`;
const ProductTitle = styled.h1`
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: 20px 10px;
  @media all and (max-width: 400px) {
    font-size: 15px;
  }
`;
const Count = styled.div`
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 55px;
`;
const ProductContainer = styled.ul`
  margin-bottom: 20px;
`;
const Products = styled.li`
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
`;
const SelectDate = styled.li`
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
  font-style: italic;
`;
