import React from "react";
import "../../../assets/css/style.css";
import styled from "styled-components";
import Button from "../../../general/Button";
import ReactQuill from "react-quill";
import EmptyImage from "../../../assets/images/product/image.png";

const ProcessingTables = ({
  status,
  setName,
  setDate,
  setDescription,
  setOutput,
  name,
  date,
  description,
  error,
  processingImage,
  output,
  setProcessingImage,
  setProcessingImageUrl,
  processingImageUrl,
  addProcess,
  editProcess,
  type,
}) => {
  const handleDescription = (value) => {
    setDescription(value);
  };

  const handleImage = (event) => {
    setProcessingImage(event.target.files[0]);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.target.files[0]);

    fileReader.onload = () => {
      const imageContent = fileReader.result;
      setProcessingImageUrl(imageContent);
    };
  };

  return (
    <MainContainer>
      <Wrapper>
        <Form>
          <Title>{status}</Title>
          <FormContainer>
            <TextDiv>
              <Label>Name : </Label>
            </TextDiv>
            <TextInputDiv>
              <input
                type="text"
                placeholder="Geo location"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </TextInputDiv>
          </FormContainer>
          <FormContainer>
            <TextDiv>
              <Label>Date : </Label>
            </TextDiv>
            <TextInputDiv>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </TextInputDiv>
          </FormContainer>
          <FormContainer>
            <TextDiv>
              <Label>Description : </Label>
            </TextDiv>
            <TextInputDiv>
              <ReactQuill onChange={handleDescription} value={description} />
            </TextInputDiv>
          </FormContainer>
          <FormContainer>
            <TextDiv>
              <Label>Processing Image : </Label>
            </TextDiv>
            <TextInputDiv className="image">
              {type == "edit_process" ? (
                <PhotoDiv>
                  <img
                    src={
                      processingImageUrl ? processingImageUrl : processingImage
                    }
                    alt="Image"
                  />
                </PhotoDiv>
              ) : (
                <PhotoDiv>
                  <img
                    src={processingImageUrl ? processingImageUrl : EmptyImage}
                    alt="Image"
                  />
                </PhotoDiv>
              )}

              <ImageInput>
                <input
                  type="file"
                  className="image-container"
                  onChange={handleImage}
                />
              </ImageInput>
            </TextInputDiv>
          </FormContainer>
          <FormContainer>
            <TextDiv>
              <Label>Output Product : </Label>
            </TextDiv>
            <TextInputDiv>
              <input
                type="text"
                placeholder="Output product"
                value={output}
                onChange={(e) => setOutput(e.target.value)}
              />
            </TextInputDiv>
          </FormContainer>
        </Form>
        {type == "edit_process" ? (
          <ButtonConatiner>
            <Button handleSubmit={editProcess} />
          </ButtonConatiner>
        ) : (
          <ButtonConatiner>
            <Button handleSubmit={addProcess} />
          </ButtonConatiner>
        )}
      </Wrapper>
    </MainContainer>
  );
};

export default ProcessingTables;

const MainContainer = styled.div`
  width: 85%;
  margin: 0 auto;
  margin-top: 50px;
  @media all and (max-width: 768px) {
    min-width: 980px;
  }
  @media all and (max-width: 375px) {
    min-width: 768px;
  }
`;
const Wrapper = styled.div``;
const Form = styled.form``;
const Title = styled.h1`
  margin: 10px;
`;
const FormContainer = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
`;
const TextDiv = styled.div`
  width: 20%;
`;
const Label = styled.label``;
const TextInputDiv = styled.div`
  width: 70%;
  textarea {
    margin-left: 10px;
  }
  input {
    height: 30px;
    padding: 3px;
    margin-left: 10px;
    width: 50%;
    border-radius: 3px;
    border: 1px solid grey;
  }
  input.image-container {
    border: None;
  }
  &.image {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;
const ButtonConatiner = styled.div`
  width: 30%;
  margin: 0 auto;
  margin-top: 30px;
`;
const PhotoDiv = styled.div`
  width: 200px;
  margin-right: 20px;
  img {
    width: 100%;
    display: block;
  }
`;
const ImageInput = styled.div``;
const ErrorMessage = styled.span`
  color: red;
  font-size: 10px;
  font-weight: 600;
`;
