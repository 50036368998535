import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { productConfig } from "../../../axiosConfig";
import { useBearStore } from "../../context/Store";
import TraceX from "./modal/TraceX";
import TraceXLinkList from "./TraceXLinkList";
import Cover from "../home/reuse/Cover";
import DeleteModal from "../blogs/modal/DeleteModal";
import DeleteTraceX from "./modal/DeleteTraceX";

const TraceXScreen = () => {
  const userData = useBearStore((state) => state.userData);

  const [tracexModal, setTracexModal] = useState(false);
  const [deleteTracexModal, setDeleteTracexModal] = useState(false);
  const [product, setProduct] = useState([]);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [TraceId, setTraceId] = useState("");

  console.log(TraceId, "-=-=-=TraceId-");

  const fetchProductData = () => {
    productConfig
      .get("/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setProduct(app_data.data);
        }
      });
  };

  const fetchTraceXData = () => {
    productConfig
      .get(`get-tracex-batch/`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
        params: {
          product_id: filterData,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setData(app_data.data);
        }
      });
  };

  const handleDeleteTraceXData = () => {
    TraceId &&
      productConfig
        .post(
          `delete-tracex-batch/${TraceId}/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userData.accessToken}`,
            },
          }
        )
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            setDeleteTracexModal(false);
            fetchTraceXData();
          }
        });
  };

  useEffect(() => {
    fetchProductData();
    fetchTraceXData();
  }, [tracexModal, filterData]);
  return (
    <Cover>
      <Wrapper>
        <ButtonDiv>
          <Button onClick={() => setTracexModal(true)}>Add TraceX Links</Button>
        </ButtonDiv>

        <TraceX
          modal={tracexModal}
          setModal={setTracexModal}
          products={product}
          get_products={fetchProductData}
        />
        <TraceXLinkList
          data={data}
          products={product}
          setFilterData={setFilterData}
          setTraceId={setTraceId}
          setDeleteTracexModal={setDeleteTracexModal}
        />
        <DeleteTraceX
          setDeleteModal={setDeleteTracexModal}
          deleteModal={deleteTracexModal}
          handleSubmit={handleDeleteTraceXData}
        />
      </Wrapper>
    </Cover>
  );
};

export default TraceXScreen;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
`;
const Button = styled.div`
  background: var(--button-color);
  width: 20%;
  text-align: center;
  padding: 8px;
  margin-top: 20px;
  color: #fff;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
`;
