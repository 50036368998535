import React from "react";
import Dosa from "../../assets/images/product/foxtail_milet_dosa-2.jpg";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import Delete from "../../assets/images/product/Bin.svg";
import Loader from "../../helpers/Loader";
import ReactPaginate from "react-paginate";
import ProductDetails from "./ProductDetails";

const ProductList = ({
  product,
  pagination,
  searchParams,
  setSearchParams,
  setCurrentPage,
  currentPage,
}) => {
  const currentParams = Object.fromEntries([...searchParams]);
  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage + 1);
    setSearchParams({
      ...currentParams,
      page: selectedPage + 1,
    });
  };
  return (
    <Wrapper>
      <Container>
        <ProductDetails />

        {product?.length > 0 ? (
          product.map((item, index) => (
            <ItemContainer key={item.id} to={`${item.id}`}>
              <Item type="slno">
                {pagination?.first_item <= pagination?.last_item && (
                  <Item type="slno">{pagination?.first_item + index}</Item>
                )}
              </Item>
              <Item type="name">{item.name}</Item>
              <Item type="name">
                <img src={item.photo_1} alt="Dosa" />
              </Item>
              <Item type="name">{item.product_type}</Item>
              <Item type="slno">{item.total_count}</Item>
              {/* <Item type="slno"></Item> */}
            </ItemContainer>
          ))
        ) : (
          <Loader />
        )}
        <PaginationContainer>
          <PaginationText>
            Showing Products {pagination?.first_item} - {pagination?.last_item}{" "}
            of {pagination?.total_items}
          </PaginationText>
          <ReactPaginate
            previousLabel={"< Prev"}
            nextLabel={"Next >"}
            pageCount={pagination?.total_pages}
            onPageChange={handlePageClick}
            forcePage={currentPage - 1}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </PaginationContainer>
      </Container>
    </Wrapper>
  );
};

export default ProductList;

const Container = styled.div`
  max-width: 100%;
  overflow: scroll;
  padding-top: 20px;
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const ItemContainer = styled(Link)`
  display: flex;
  border-bottom: 1px solid #26272a;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  min-width: 1180px;
`;
const Item = styled.li`
  color: #000;
  cursor: pointer;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
  img {
    display: block;
    width: 50%;
  }
`;
const DeleteIconDiv = styled.div`
  width: 25%;
  img {
    width: 100%;
    display: block;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0 15px;
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
