import React, { useEffect, useState } from "react";
import Cover from "../home/reuse/Cover";
import FeaturedCollectionTop from "./FeaturedCollectionTop";
import FeaturedCollectionList from "./FeaturedCollectionList";
import { webConfig } from "../../../axiosConfig";
import { useBearStore, useFeaturedIdStore } from "../../context/Store";

const FeaturedCollectionScreen = () => {
  const userData = useBearStore((state) => state.userData);
  const FeaturedId = useFeaturedIdStore((state) => state.FeaturedId);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [featuredCollectionData, setFeaturedCollectionData] = useState([]);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [video, setVideo] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const fetchFeaturedCollections = () => {
    webConfig
      .get("featured-collections/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setFeaturedCollectionData(app_data.data.data);
        }
      });
  };

  const fetchSingleFeaturedCollection = () => {
    FeaturedId &&
      webConfig
        .get(`single-featured-collection/${FeaturedId}`, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          let { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            setTitle(app_data.data.title);
            setUrl(app_data.data.url);
            setVideo(app_data.data.video);
          }
        });
  };

  const addFeatureCollectionVideo = () => {
    const formData = new FormData();

    formData.append("video", video);
    formData.append("url", url);
    formData.append("title", title);
    webConfig
      .post("add-featured-collection/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          fetchFeaturedCollections();
          setAddModal(false);
        }
      });
  };

  const editFeaturedCollectionVideo = () => {
    const formData = new FormData();

    if (featuredCollectionData.title !== title) {
      formData.append("title", title);
    }

    if (featuredCollectionData.url !== url) {
      formData.append("url", url);
    }

    if (featuredCollectionData.video !== video) {
      formData.append("video", video);
    }

    webConfig
      .post(`edit-featured-collection/${FeaturedId}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        let { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          fetchFeaturedCollections();
          setEditModal(false);
        }
      });
  };

  useEffect(() => {
    fetchFeaturedCollections();
    FeaturedId && fetchSingleFeaturedCollection();
  }, [FeaturedId]);

  return (
    <>
      <Cover>
        <FeaturedCollectionTop
          setTitle={setTitle}
          setUrl={setUrl}
          setVideo={setVideo}
          setVideoUrl={setVideoUrl}
          setAddModal={setAddModal}
          addModal={addModal}
          addFeatureCollectionVideo={addFeatureCollectionVideo}
        />
        <FeaturedCollectionList
          setEditModal={setEditModal}
          editModal={editModal}
          title={title}
          url={url}
          video={video}
          videoUrl={videoUrl}
          setTitle={setTitle}
          setUrl={setUrl}
          setVideo={setVideo}
          setVideoUrl={setVideoUrl}
          featuredCollectionData={featuredCollectionData}
          editFeaturedCollectionVideo={editFeaturedCollectionVideo}
          setDeleteModal={setDeleteModal}
          deleteModal={deleteModal}
          fetchFeaturedCollections={fetchFeaturedCollections}
        />
      </Cover>
    </>
  );
};

export default FeaturedCollectionScreen;
