import React, { useEffect, useState } from "react";
import { OfferTopConatiner } from "../reuse/OfferTopConatiner";
import styled from "styled-components";
import ProductTable from "./ProductTable";
import ProductOfferList from "./ProductOfferList";
import { productConfig } from "../../../../axiosConfig";
import { useBearStore, useOfferIdStore } from "../../../context/Store";
import Cover from "../../home/reuse/Cover";
import AddProductOfferModal from "./modal/AddProductOfferModal";
import EditProductOfferModal from "./modal/EditProductOfferModal";

const ProductOfferScreen = () => {
  const userData = useBearStore((state) => state.userData);
  const setOfferId = useOfferIdStore((state) => state.setOfferId);
  const Id = useOfferIdStore((state) => state.offerId);

  const [productOffer, setProductOffer] = useState([]);
  const [productData, setProductData] = useState([]);
  const [indivitualOffer, setIndivitualOffer] = useState([]);

  const [addProductOfferModal, setAddProductOfferModal] = useState(false);
  const [editProductOfferModal, setEditProductOfferModal] = useState(false);

  // add forms states
  const [offerName, setOfferName] = useState();
  const [product, setProduct] = useState();
  const [productId, setProductId] = useState();
  const [deductionPercentage, setDeductionPercentage] = useState();
  const [startDate, SetStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const fetchProducts = () => {
    productConfig
      .get("/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setProductData(app_data.data);
        }
      });
  };

  const fetchIndivitualProduct = () => {
    Id &&
      productConfig
        .get(`indivitual-offer/${Id}`, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            setIndivitualOffer(app_data.data);
            setOfferName(app_data.data.offer_name);
            setProduct(app_data.data.product);
            setDeductionPercentage(app_data.data.deduction_percentage);
            SetStartDate(app_data.data.start_date);
            setEndDate(app_data.data.end_date);
          }
        });
  };

  const fetchProductOffer = () => {
    productConfig
      .get("product-offers/", {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setProductOffer(app_data.data);
        }
      });
  };

  const AddProductOffer = () => {
    const formData = new FormData();

    formData.append("offer_name", offerName);
    formData.append("product", product);
    formData.append("deduction_percentage", deductionPercentage);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);

    productConfig
      .post("add-product-offer/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setAddProductOfferModal(false);
          fetchProductOffer();
        }
      });
  };

  const deleteProductOffer = () => {
    productConfig
      .post(
        `delete-product-offer/${Id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        }
      )
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          fetchProductOffer();
        }
      });
  };

  useEffect(() => {
    fetchProductOffer();
    fetchProducts();
  }, []);

  useEffect(() => {
    Id === Id && fetchIndivitualProduct();
  }, [editProductOfferModal]);

  const EditProductOffer = () => {
    const formData = new FormData();

    if (indivitualOffer.offer_name !== offerName) {
      formData.append("offer_name", offerName);
    }

    if (indivitualOffer.product !== product) {
      formData.append("product", product);
    }

    if (indivitualOffer.deduction_percentage !== deductionPercentage) {
      formData.append("deduction_percentage", deductionPercentage);
    }

    if (indivitualOffer.start_date !== startDate) {
      formData.append("start_date", startDate);
    }

    if (indivitualOffer.end_date !== endDate) {
      formData.append("end_date", endDate);
    }

    productConfig
      .post(`edit-product-offer/${Id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setEditProductOfferModal(false);
          fetchProductOffer();
        }
      });
  };

  return (
    <Cover>
      <MainTitle>Product Offer</MainTitle>
      <OfferTopConatiner
        addProductOfferModal={addProductOfferModal}
        buttonClickFunction={setAddProductOfferModal}
      />
      <Container>
        <ProductOfferList
          productOffer={productOffer}
          editProductOfferModal={editProductOfferModal}
          editButtonClickFunction={setEditProductOfferModal}
          setId={setOfferId}
          deleteProductOffer={deleteProductOffer}
        />
        <AddProductOfferModal
          activeModal={addProductOfferModal}
          onclickFunction={setAddProductOfferModal}
          data={productData}
          labelValue="Product"
          type="product_offer"
          setOfferName={setOfferName}
          setObjectType={setProduct}
          setDeductionPercentage={setDeductionPercentage}
          SetStartDate={SetStartDate}
          setEndDate={setEndDate}
          handleSubmit={AddProductOffer}
        />
        <EditProductOfferModal
          editActiveModal={editProductOfferModal}
          onclickFunction={setEditProductOfferModal}
          labelValue="Product"
          type="edit_product_offer"
          data={productData}
          offerName={offerName}
          objectType={product}
          offerObjectsId={productId}
          deductionPercentage={deductionPercentage}
          startDate={startDate}
          endDate={endDate}
          offername={offerName}
          setOfferName={setOfferName}
          setObjectType={setProduct}
          setDeductionPercentage={setDeductionPercentage}
          SetStartDate={SetStartDate}
          setEndDate={setEndDate}
          handleSubmit={EditProductOffer}
        />
      </Container>
    </Cover>
  );
};

export default ProductOfferScreen;

const Container = styled.div`
  width: 100%;
`;
const MainTitle = styled.h2`
  text-align: center;
  margin-top: 15px;
  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;
