import React, { useEffect, useState } from "react";
import Banner from "../../assets/images/banner/banner22.jpg";
import styled from "styled-components";
import { webConfig } from "../../../axiosConfig";
import EditBannerModal from "./modal/EditBannerModal";
import { useBannerIdStore, useBearStore } from "../../context/Store";
import BannerDeleteModal from "./modal/BannerDeleteModal";
import BannerDetails from "./BannerDetails";

const BannerList = ({ bannerData, fetchBanners, pagination }) => {
  const userData = useBearStore((state) => state.userData);
  const setBannerId = useBannerIdStore((state) => state.setBannerId);
  const bannerId = useBannerIdStore((state) => state.bannerId);

  const [editModal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [getId, setGetId] = useState("");
  const [singleBannerData, setSingleBannerData] = useState([]);
  const [bannerImage, setBannerImage] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [mobileBannerImage, setMobileBannerImage] = useState("");

  const fetchBannerData = () => {
    // setBannerId &&
    webConfig
      .get(`single-banner/${bannerId}/`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setSingleBannerData(app_data.data);
          setBannerImage(app_data.data.image);
          setMobileBannerImage(app_data.data.mob_image);
          setBannerUrl(app_data.data.url);
        } else {
        }
      });
  };

  const deleteBanner = () => {
    setBannerId &&
      webConfig
        .post(
          `delete-banner/${bannerId}/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userData.accessToken}`,
            },
          }
        )
        .then((response) => {
          const { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            window.location.reload();
          }
        });
  };

  useEffect(() => {
    bannerId && fetchBannerData();
  }, [bannerId]);

  return (
    <Wrapper>
      <Conatiner>
        <BannerDetails />
        {bannerData.map((item, index) => (
          <ItemContainer key={item.id}>
            <ItemList type="slno">
              {pagination?.first_item <= pagination?.last_item && (
                <ItemList type="slno" className="slno">
                  {pagination?.first_item + index}
                </ItemList>
              )}
            </ItemList>
            <ItemList type="name">
              <ImageDiv>
                <img src={item.image} alt="Banner" />
              </ImageDiv>
            </ItemList>
            <ItemList type="name">{item.url}</ItemList>
            <ItemList
              type="name"
              className="status"
              onClick={() => {
                setBannerId(item.id);
              }}
            >
              <EditContainer
                onClick={() => {
                  setModal(true);
                }}
              >
                <i className="ri-edit-box-fill"></i>
              </EditContainer>
              <DeleteContainer
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </DeleteContainer>
            </ItemList>
          </ItemContainer>
        ))}
      </Conatiner>
      <EditBannerModal
        editModal={editModal}
        setModal={setModal}
        getId={getId}
        fetchBanners={fetchBanners}
        bannerImage={bannerImage}
        setBannerImage={setBannerImage}
        bannerUrl={bannerUrl}
        setBannerUrl={setBannerUrl}
        singleBannerData={singleBannerData}
        bannerId={bannerId}
        setMobileBannerImage={setMobileBannerImage}
        mobileBannerImage={mobileBannerImage}
      />
      <BannerDeleteModal
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        deleteBanner={deleteBanner}
      />
    </Wrapper>
  );
};

export default BannerList;

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
const Conatiner = styled.div`
  max-width: 100%;
  overflow: scroll;
`;
const ItemContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
  padding-top: 30px;
  height: 50px;
  min-width: 1180px;
`;
const ItemList = styled.li`
  text-align: center;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
  font-size: 14px;
  margin-left: 30px;
  img {
    display: block;
    width: 100%;
  }
  &.status {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  &.slno {
    display: flex;
    justify-content: flex-end;
  }
`;
const EditContainer = styled.div`
  cursor: pointer;
`;
const DeleteContainer = styled.div`
  cursor: pointer;

  i {
    color: red;
  }
`;
const ImageDiv = styled.div`
  width: 50%;
  img {
    display: block;
    width: 100%;
  }
`;
