import React from "react";
import styled from "styled-components";

const UserNavList = ({ product }) => {
  return (
    <ItemContainer>
      <Item type="slno">Sl No</Item>
      {product == "Product" ? (
        <>
          <Item type="name">Product</Item>
          <Item type="name">Photo</Item>
        </>
      ) : (
        <>
          <Item type="name">Name</Item>
          <Item type="name">Phone</Item>
        </>
      )}
    </ItemContainer>
  );
};

export default UserNavList;

const ItemContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #dfdfdf;
  @media all and (max-width: 768px) {
    min-width: 1180px;
  }
`;
const Item = styled.li`
  font-size: 14px;
  font-weight: 700;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
`;
