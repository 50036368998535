import React from "react";
import styled from "styled-components";

const OrderDetails = () => {
  return (
    <Cover>
      <ItemContainer>
        <Item type="item">Sl no</Item>
        <Item type="item">Order Id</Item>
        <Item type="item">Date</Item>
        <Item type="item">Customer</Item>
        <Item type="item">Total Amount</Item>
        <Item type="item">Order Confirmation</Item>
        <Item type="item">Razorpay Confirmation</Item>
        <Item type="item">Order Status</Item>
      </ItemContainer>
    </Cover>
  );
};

export default OrderDetails;

const Cover = styled.div`
  @media all and (max-width: 1080px) {
    min-width: 1180px;
  }
`;
const ItemContainer = styled.ul`
  padding-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
`;
const Item = styled.li`
  width: ${({ type }) =>
    type == "check" ? "5%" : type == "item" ? "12%" : "15%"};
  font-size: 15px;
  font-weight: 700;
`;
