import React from "react";
import styled from "styled-components";
import Button from "../../../../general/Button";

const EditProductOfferModal = ({
  editActiveModal,
  onclickFunction,
  labelValue,
  offerName,
  objectType,
  deductionPercentage,
  startDate,
  endDate,
  data,
  type,
  setOfferName,
  setObjectType,
  setDeductionPercentage,
  SetStartDate,
  setEndDate,
  handleSubmit,
  offerObjectsId,
  handleEditCartOffer,
}) => {
  console.log(data, "-=-=-=sws-");
  return (
    <Cover>
      <Container className={editActiveModal ? "active" : ""}>
        <Overlay onClick={() => onclickFunction(false)}></Overlay>
        <Modal>
          <Form>
            <OfferNameDiv>
              <LabelDiv>
                <Label>Offer Name :</Label>
              </LabelDiv>
              <InputDiv>
                <input
                  type="text"
                  placeholder="Offer name"
                  value={offerName}
                  onChange={(e) => setOfferName(e.target.value)}
                />
              </InputDiv>
            </OfferNameDiv>
            <ProductNameDiv>
              <LabelDiv>
                <Label>{labelValue} :</Label>
              </LabelDiv>
              <InputDiv>
                {labelValue === "Minimum purchase amount" ? (
                  <input
                    type="text"
                    placeholder="Minimum purchse amount"
                    onChange={(e) => setObjectType(e.target.value)}
                    value={objectType}
                  />
                ) : (
                  <>
                    <select
                      onChange={(e) => setObjectType(e.target.value)}
                      value={offerObjectsId}
                    >
                      {data?.map((item) =>
                        type === "edit_product_offer" ? (
                          <option
                            value={item.id}
                            selected={item.name === objectType}
                          >
                            {item.name}
                          </option>
                        ) : (
                          <option
                            value={item.id}
                            selected={item.category_name === objectType}
                          >
                            {item.category_name}
                          </option>
                        )
                      )}
                    </select>
                  </>
                )}
              </InputDiv>
            </ProductNameDiv>
            <PercentageDiv>
              <LabelDiv>
                <Label>Percentage :</Label>
              </LabelDiv>
              <InputDiv>
                <input
                  type="text"
                  placeholder="Enter the deduction amount"
                  value={deductionPercentage}
                  onChange={(e) => setDeductionPercentage(e.target.value)}
                />
              </InputDiv>
            </PercentageDiv>
            <DateDiv>
              <LabelDiv>
                <Label>Start date:</Label>
              </LabelDiv>
              <InputDiv>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => SetStartDate(e.target.value)}
                />
              </InputDiv>
            </DateDiv>
            <DateDiv>
              <LabelDiv>
                <Label>End date:</Label>
              </LabelDiv>
              <InputDiv>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </InputDiv>
            </DateDiv>
            <ButtonContainer>
              <Button handleSubmit={handleSubmit} />
            </ButtonContainer>
          </Form>
        </Modal>
      </Container>
    </Cover>
  );
};

export default EditProductOfferModal;

const Cover = styled.div``;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 70%;
  max-width: 736px;
  max-height: 100vh;
  height: 50vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 36px #000;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Form = styled.form``;
const OfferNameDiv = styled.div`
  display: flex;
  align-items: center;
`;
const ProductNameDiv = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
const OfferType = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
const PercentageDiv = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
const DateDiv = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;
const Label = styled.label`
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
`;
const LabelDiv = styled.div`
  width: 42%;
`;
const InputDiv = styled.div`
  width: 58%;
  input {
    padding: 5px;
    width: 100%;
  }
  select {
    padding: 5px;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 20px;
`;
