import React, { useState } from "react";
import Plus from "../../assets/images/product/plus_icon.svg";
import styled from "styled-components";
import OfferBannerModal from "./modal/OfferBanerModal";
import { webConfig } from "../../../axiosConfig";
import { useBearStore } from "../../context/Store";

const OfferBannerTopContainer = ({
  addOfferBannerModal,
  setAddOfferBannerModal,
  fetchOfferBannerData,
  offerBannerData,
  isLoading,
  setLoading,
}) => {
  const userData = useBearStore((state) => state.userData);
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [mobImage, setMobImage] = useState("");
  const [mobImageUrl, setMobImageUrl] = useState("");

  const addOfferBanner = (e) => {
    setLoading(true);
    const formData = new FormData();
    e.preventDefault();
    formData.append("image", image);
    formData.append("mob_image", mobImage);

    webConfig
      .post("add-offer-banner/", formData, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data } = response.data;
        if (app_data.StatusCode === 6000) {
          setLoading(false);
          setAddOfferBannerModal(false);
          fetchOfferBannerData();
        } else {
          setLoading(false);
        }
      });
  };

  return (
    <MainContainer>
      <ButtonContainer
        style={{ display: offerBannerData.length > 0 ? "none" : "flex" }}
      >
        <Button onClick={() => setAddOfferBannerModal(true)}>
          <ImageDiv>
            <img src={Plus} alt="Add" />
          </ImageDiv>
          <ButtonTitle>Add Banner</ButtonTitle>
        </Button>
      </ButtonContainer>
      <OfferBannerModal
        setModal={setAddOfferBannerModal}
        modal={addOfferBannerModal}
        type="add_modal"
        image={image}
        setImage={setImage}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        mobImage={mobImage}
        setMobImage={setMobImage}
        mobImageUrl={mobImageUrl}
        setMobImageUrl={setMobImageUrl}
        actionFunction={addOfferBanner}
        setLoading={setLoading}
        isLoading={isLoading}
      />
    </MainContainer>
  );
};

export default OfferBannerTopContainer;

const MainContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  width: 20%;
  background-color: var(--button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  margin-right: 20px;
  cursor: pointer;
  @media all and (max-width: 1080px) {
    width: 20%;
  }
  @media all and (max-width: 768px) {
    width: 30%;
  }
  @media all and (max-width: 768px) {
    width: 35%;
  }
  @media all and (max-width: 480px) {
    width: 50%;
  }
  @media all and (max-width: 480px) {
    width: 70%;
  }
`;
const Button = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
`;
const ImageDiv = styled.div`
  width: 15%;
  img {
    width: 100%;
    display: block;
  }
`;
const ButtonTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #fff;
`;
