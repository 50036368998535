import React, { useEffect, useRef, useState } from "react";
import Users from "./Users";
import UsersList from "./UsersList";
import Cover from "../home/reuse/Cover";
import { accountsConfig } from "../../../axiosConfig";
import { useBearStore } from "../../context/Store";
import { Outlet, useSearchParams } from "react-router-dom";
import UserDetails from "./UserDetails";

const UsersScreen = ({ startIndex }) => {
  const userData = useBearStore((state) => state.userData);
  const [pagination, setPagination] = useState({});
  const [users, setUsers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  console.log(currentPage, "currentPage");

  useEffect(() => {
    accountsConfig
      .get(`users/?q=${searchQuery}&page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data, data } = response.data;
        if (app_data.StatusCode === 6000) {
          setUsers(app_data.data);
          setPagination(app_data.pagination_data);
        }
      });
  }, [searchQuery, currentPage]);

  const currentParams = Object.fromEntries([...searchParams]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage + 1);
    setSearchParams({
      ...currentParams,
      page: selectedPage + 1,
    });
  };

  return (
    <>
      <Cover>
        <UserDetails
          setUsers={setUsers}
          users={users}
          setSearchQuery={setSearchQuery}
        />

        <UsersList
          setUsers={setUsers}
          users={users}
          pagination={pagination}
          setPagination={setPagination}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Cover>
      <Outlet />
    </>
  );
};

export default UsersScreen;
