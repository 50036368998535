import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useBearStore } from "../../context/Store";
import { accountsConfig, productConfig } from "../../../axiosConfig";
import Loader from "../../helpers/Loader";
import { Link, useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";

const UserNavData = ({ type }) => {
  const userData = useBearStore((state) => state.userData);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);

  const currentParams = Object.fromEntries([...searchParams]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage + 1);
    setSearchParams({
      ...currentParams,
      page: selectedPage + 1,
    });
  };

  const fetchUserData = () => {
    accountsConfig
      .get(`users/?q=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      })
      .then((response) => {
        const { app_data, data } = response.data;
        if (app_data.StatusCode === 6000) {
          setUsers(app_data.data);
          setPagination(app_data.pagination_data);
        }
      });
  };

  useEffect(() => {
    fetchUserData();
  }, [searchQuery]);

  return (
    <Container>
      {users?.length > 0 ? (
        users.map((user, index) => (
          <ItemContainer key={user.id} to={`/users/${user.id}/`}>
            <Item type="slno">
              {pagination?.first_item <= pagination?.last_item && (
                <Item type="slno">{pagination?.first_item + index}</Item>
              )}
            </Item>
            <Item type="name">{user.name}</Item>
            <Item type="name">{user.phone}</Item>
          </ItemContainer>
        ))
      ) : (
        <Loader />
      )}
      <PaginationContainer>
        <PaginationText>
          Showing Users {pagination?.first_item} - {pagination?.last_item} of{" "}
          {pagination?.total_items}
        </PaginationText>
        <ReactPaginate
          previousLabel={"< Prev"}
          nextLabel={"Next >"}
          pageCount={pagination?.total_pages}
          onPageChange={handlePageClick}
          forcePage={currentPage - 1}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </PaginationContainer>
    </Container>
  );
};

export default UserNavData;

const Container = styled.div``;
const ItemContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid black;
  color: #000;
  cursor: pointer;
  @media all and (max-width: 768px) {
    min-width: 1180px;
  }
`;
const Item = styled.li`
  font-size: 14px;
  font-weight: 700;
  width: ${({ type }) =>
    type == "slno" ? "8%" : type == "name" ? "12%" : "15%"};
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0 15px;
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  margin-left: 20px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
