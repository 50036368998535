export const formSchema = [
  {
    label: "Product",
    type: "select",
    name: "product_id",
    placeholder: null,
    is_required: true,
  },
  {
    label: "Batch No",
    type: "text",
    name: "batch_no",
    placeholder: "Enter Batch No",
    is_required: true,
  },
  {
    label: "Link",
    type: "text",
    name: "link",
    placeholder: "Enter the traceX link",
    is_required: true,
  },
];
