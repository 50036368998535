import React, { useEffect, useState } from "react";
import Stages from "./reuse/Stages";
import OnBoardTables from "./tables/OnBoardTables";
import styled from "styled-components";
import ProcuringTable from "./tables/ProcuringTable";
import ProcessingTables from "./tables/ProcessingTables";
import QualityCheck from "./tables/QualityCheck";
import PackageTables from "./tables/PackageTables";
import { productConfig, tracebilityConfig } from "../../../axiosConfig";
import { useNavigate, useParams } from "react-router-dom";
import { useBearStore } from "../../context/Store";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2";
import TraceX from "../traceX/modal/TraceX";

const AddTracebility = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const userData = useBearStore((state) => state.userData);
  const [status, setStatus] = useState("OnBoarding");

  //common states
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [error, SetError] = useState("");

  //Onboard states
  const [geo, setGeo] = useState("");
  const [onboardImage, setOnboardImage] = useState("");
  const [onboardImageUrl, setOnboardImageUrl] = useState("");

  //Procuring States
  const [quantity, setQuantity] = useState("");
  const [procuringImage, setProcuringImage] = useState("");
  const [procuringImageUrl, setProcuringImageUrl] = useState("");

  //Processing States
  const [processingImage, setProcessingImage] = useState("");
  const [processingImageUrl, setProcessingImageUrl] = useState("");
  const [output, setOutput] = useState("");

  //QualityCheck
  const [qualityCheckImage, setQualityCheckImage] = useState("");
  const [qualityCheckImageUrl, setQualityCheckImageUrl] = useState("");
  const [report, setReport] = useState("");
  const [reportUrl, setReportUrl] = useState("");

  //Package
  const [expDate, setExpDate] = useState("");
  const [packingImage, setPackingImage] = useState("");
  const [packingImageUrl, setPackingImageUrl] = useState("");

  const showSuccessAlert = () => {
    Swal.fire({
      title: "Success!",
      text: "Changes Updated.",
      icon: "success",
      confirmButtonColor: "rgb(9, 175, 244)",
      confirmButtonText: "Done",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/products/");
      }
    });
  };

  const addOnboard = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("geography", geo);
    formData.append("start_date", date);
    formData.append("description", description);
    formData.append("image", onboardImage);

    if (name && geo && date && description && onboardImage) {
      tracebilityConfig
        .post(`add-onboard-tracebility/${id}/`, formData, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          let { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            alert("Success");
            setStatus("Procuring");
            setName("");
            setDate("");
            setGeo("");
            setDescription("");
            setOnboardImage("");
            setOnboardImageUrl("");
          } else {
            SetError(app_data.data.message);
          }
        });
    }
  };

  const addProcuring = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("quantity", quantity);
    formData.append("start_date", date);
    formData.append("description", description);
    formData.append("image", procuringImage);

    if (name && quantity && date && description && procuringImage) {
      tracebilityConfig
        .post(`add-procuring-tracebility/${id}/`, formData, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          let { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            alert("Success");
            setStatus("Process");
            setName("");
            setDate("");
            setQuantity("");
            setDescription("");
            setProcuringImage("");
            setProcuringImageUrl("");
          } else {
            SetError(app_data.data.message);
          }
        });
    }
  };

  const addProcess = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("start_date", date);
    formData.append("description", description);
    formData.append("image1", processingImage);
    formData.append("output_product", output);
    if (name && output && date && description && processingImage) {
      tracebilityConfig
        .post(`add-processing-tracebility/${id}/`, formData, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          let { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            alert("Success");
            setStatus("QualityCheck");
            setName("");
            setDate("");
            setOutput("");
            setDescription("");
            setProcessingImage("");
            setProcessingImageUrl("");
          } else {
            SetError(app_data.data.message);
          }
        });
    }
  };

  const addQualityCheck = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("start_date", date);
    formData.append("description", description);
    formData.append("image1", qualityCheckImage);
    formData.append("file1", report);

    if (name && date && description && qualityCheckImage && report) {
      tracebilityConfig
        .post(`add-quality-check-tracebility/${id}/`, formData, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          let { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            alert("Success");
            setStatus("Package");
            setName("");
            setDate("");
            setOutput("");
            setDescription("");
            setQualityCheckImage("");
            setQualityCheckImageUrl("");
            setReport("");
            setReportUrl("");
          } else {
            SetError(app_data.data.message);
          }
        });
    }
  };

  const addPackage = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("start_date", date);
    formData.append("exp_date", expDate);
    formData.append("description", description);
    formData.append("image1", packingImage);

    if (name && date && description && packingImage && expDate) {
      tracebilityConfig
        .post(`add-packing-tracebility/${id}/`, formData, {
          headers: {
            Authorization: `Bearer ${userData.accessToken}`,
          },
        })
        .then((response) => {
          let { app_data } = response.data;
          if (app_data.StatusCode === 6000) {
            showSuccessAlert();
            setName("");
            setDate("");
            setExpDate("");
            setDescription("");
            setPackingImage("");
            setPackingImageUrl("");
          } else {
            SetError(app_data.data.message);
          }
        });
    }
  };

  return (
    <MainContainer>
      <Stages status={status} setStatus={setStatus} />
      {status == "OnBoarding" ? (
        <OnBoardTables
          status={status}
          error={error}
          name={name}
          date={date}
          geo={geo}
          description={description}
          onboardImage={onboardImage}
          onboardImageUrl={onboardImageUrl}
          setStatus={setStatus}
          setGeo={setGeo}
          setOnboardImage={setOnboardImage}
          setOnboardImageUrl={setOnboardImageUrl}
          setDescription={setDescription}
          setDate={setDate}
          setName={setName}
          addOnboard={addOnboard}
        />
      ) : status == "Procuring" ? (
        <ProcuringTable
          status={status}
          setStatus={setStatus}
          error={error}
          name={name}
          date={date}
          description={description}
          quantity={quantity}
          procuringImage={procuringImage}
          procuringImageUrl={procuringImageUrl}
          setName={setName}
          setDate={setDate}
          setDescription={setDescription}
          setQuantity={setQuantity}
          setProcuringImage={setProcuringImage}
          setProcuringImageUrl={setProcuringImageUrl}
          addProcuring={addProcuring}
        />
      ) : status == "Process" ? (
        <ProcessingTables
          status={status}
          setStatus={setStatus}
          error={error}
          name={name}
          date={date}
          description={description}
          processingImage={processingImage}
          processingImageUrl={processingImageUrl}
          output={output}
          setName={setName}
          setProcessingImage={setProcessingImage}
          setDate={setDate}
          setProcessingImageUrl={setProcessingImageUrl}
          setDescription={setDescription}
          setOutput={setOutput}
          addProcess={addProcess}
        />
      ) : status == "QualityCheck" ? (
        <QualityCheck
          status={status}
          error={error}
          name={name}
          date={date}
          description={description}
          report={report}
          qualityCheckImage={qualityCheckImage}
          qualityCheckImageUrl={qualityCheckImageUrl}
          setStatus={setStatus}
          setName={setName}
          setDate={setDate}
          setDescription={setDescription}
          setQualityCheckImage={setQualityCheckImage}
          setQualityCheckImageUrl={setQualityCheckImageUrl}
          setReport={setReport}
          setReportUrl={setReportUrl}
          addQualityCheck={addQualityCheck}
        />
      ) : status == "Package" ? (
        <PackageTables
          status={status}
          error={error}
          name={name}
          date={date}
          description={description}
          expDate={expDate}
          packingImage={packingImage}
          packingImageUrl={packingImageUrl}
          setStatus={setStatus}
          setName={setName}
          setDate={setDate}
          setDescription={setDescription}
          setPackingImage={setPackingImage}
          setPackingImageUrl={setPackingImageUrl}
          setExpDate={setExpDate}
          addPackage={addPackage}
        />
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default AddTracebility;

const MainContainer = styled.div`
  max-width: 100%;
  height: 90vh;
  overflow: scroll;
  overflow-y: scroll;
`;
